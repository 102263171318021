import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef, Row } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Table } from "~/components";
import Loader from "~/components/UI/Loader";
import { useAuth } from "~/providers";
// import { TableAvatar } from "~/components/UI/TableAvatar";
import {
  getProjectQualityResults,
  generateProjectQualityResults,
  deleteProjectQualityResult,
} from "~/queries/projectQueries";

export const QualityResults = () => {
  const { id } = useParams();
  const { role } = useAuth();

  const queryClient = useQueryClient();

  const { isLoading: isResultsLoading, data: results } = useQuery({
    queryKey: ["project", id, "results"],
    queryFn: async () => {
      try {
        return await getProjectQualityResults(id);
      } catch (err: any) {
        toast.error(err.message);
        throw err;
      }
    },
  });

  const generateMutation = useMutation({
    mutationFn: (resultId: string) =>
      generateProjectQualityResults(id, resultId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["project", id, "results"] });
      toast.success("Results regenerated successfully");
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (resultId: string) => deleteProjectQualityResult(id, resultId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["project", id, "results"] });
      toast.success("Results deleted successfully");
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const toPascalCaseWithSpace = (word: string) =>
    word
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" ");
  const columns: ColumnDef<Quality>[] = [
    {
      id: "name",
      cell: ({ row }) => (
        <a
          href={`https://docs.google.com/spreadsheets/d/${row.original.itemId}`}
          // className="text-blue-500 "
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <TableAvatar
            first_name={row.original?.sheet}
            last_name={row.original?.sheet}
          /> */}
          <span className="text-left">
            {toPascalCaseWithSpace(row.original.sheet)}
          </span>
        </a>
      ),
      header: () => <span>Name</span>,
      size: 14,
    },
    {
      id: "qualityScore",
      cell: ({ row }) => (
        <span className="text-left">{row.original.qualityScore}%</span>
      ),
      header: () => <span>Quality Score</span>,
      size: 10,
    },
    {
      id: "passCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.passCount}</span>
      ),
      header: () => <span>Passed</span>,
      size: 10,
    },
    {
      id: "failCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.failCount}</span>
      ),
      header: () => <span>Failed</span>,
      size: 10,
    },
    {
      id: "inQaCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.inQaCount}</span>
      ),
      header: () => <span>In QA</span>,
      size: 10,
    },
    {
      id: "notTestedCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.notTestedCount}</span>
      ),
      header: () => <span>Not Tested</span>,
      size: 10,
    },
    {
      id: "incompleteCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.incompleteCount}</span>
      ),
      header: () => <span>Incomplete</span>,
      size: 10,
    },

    {
      id: "reworkCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.reworkCount}</span>
      ),
      header: () => <span>Rework</span>,
      size: 10,
    },
    {
      id: "ongoingCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.ongoingCount}</span>
      ),
      header: () => <span>Ongoing</span>,
      size: 10,
    },
    {
      id: "blockedCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.blockedCount}</span>
      ),
      header: () => <span>Blocked</span>,
      size: 10,
    },

    {
      id: "totalCount",
      cell: ({ row }) => (
        <span className="text-left">{row.original.totalCount}</span>
      ),
      header: () => <span>Total</span>,
      size: 10,
    },
    {
      id: "refresh",
      cell: ({ row }) => (
        <img
          src="/refresh.svg"
          alt="Refresh"
          className="w-5 h-5 cursor-pointer"
          onClick={() => generateMutation.mutate(row.original.id)}
        />
      ),
      header: () => <span style={{ visibility: "hidden" }}>Refresh</span>,
      size: 10,
    },

    ...(role?.slug !== "project-team-member"
      ? [
          {
            id: "delete",
            cell: ({ row }: { row: Row<Quality> }) => (
              <img
                src="/delete.svg"
                alt="Delete"
                className="w-5 h-5 cursor-pointer"
                onClick={() => deleteMutation.mutate(row.original.id)}
              />
            ),
            header: () => <span style={{ visibility: "hidden" }}>Delete</span>,
            size: 10,
          },
        ]
      : []),
  ];

  return (
    <div className="bg-white w-full rounded-xl min-h-[400px]">
      {isResultsLoading ? (
        <Loader big />
      ) : results && results.length > 0 ? (
        <div className="bg-white w-full h-full rounded-xl flex flex-col p-4 min-h-[400px]">
          <Table key="roles" cols={columns} rawData={results} />
        </div>
      ) : (
        <div className="bg-white w-full h-full rounded-xl flex flex-col items-center justify-center text-center">
          <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5">
            <img src="/empty.svg" className="w-[100px]" alt="Empty state" />
            <div className="flex flex-col items-center gap-y-2">
              <span className="text-sm text-[#0A0D14]">No quality results</span>
              <span className="text-sm text-[#525866]">
                No quality results available for this project
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
