export const atLeastOneNumber = new RegExp(".*[0-9].*");
export const atLeastOneUpperCase = new RegExp(".*[A-Z].*");
export const atLeastOneLowerCase = new RegExp(".*[a-z].*");
export const atLeastOneSpecial = new RegExp(".*[-!@£$%^&*()-+”?:><].*");
export const getPasswordStrength = (password: string) => {
  type Strength =
    | {
        regex: RegExp;
        description: string;
        test?: undefined;
      }
    | {
        test: (pwd: string) => boolean;
        description: string;
        regex?: undefined;
      };

  const strengthTests: Strength[] = [
    { regex: /.*[0-9].*/, description: "contain at least one number" },
    { regex: /.*[A-Z].*/, description: "contain at least one uppercase letter" },
    { regex: /.*[a-z].*/, description: "contain at least one lowercase letter" },
    {
      regex: /.*[-!@£$%^&*()-+"#'|{}_=.[\]/?:><].*/,
      description: "at least one special character",
    },
    {
      test: (pwd: string) => pwd.length > 8,
      description: "be greater than 8 characters",
    },
  ];

  const passedTests: Strength[] = [];
  const failedTests: Strength[] = [];

  strengthTests.forEach((test) => {
    let passed = false;
    if (test.regex) {
      passed = test.regex.test(password);
    } else if (test.test) {
      passed = test.test(password);
    }
    if (passed) {
      passedTests.push(test);
    } else {
      failedTests.push(test);
    }
  });

  const strength = passedTests.length;
  let strengthDescription = "";

  if (strength === strengthTests.length) {
    strengthDescription = "The password is very strong.";
  } else if (strength >= 3) {
    strengthDescription = "The password is strong.";
  } else if (strength >= 2) {
    strengthDescription = "The password is moderately strong.";
  } else if (strength >= 1) {
    strengthDescription = "The password is weak.";
  } else {
    strengthDescription = "";
  }

  return { strengthDescription, strength, passedTests, failedTests };
};
export const checkIfValid = (password: string, confirmPassword: string) => {
  return (
    [
      atLeastOneNumber.test(password!),
      atLeastOneUpperCase.test(password!),
      atLeastOneLowerCase.test(password!),
      atLeastOneSpecial.test(password!),
    ].filter(Boolean).length >= 3 &&
    password == confirmPassword &&
    password!.length >= 8
  );
};
