/* eslint-disable prefer-const */
import { ColumnDef } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Header, Pill, Table, Pagination } from "~/components";
import Loader from "~/components/UI/Loader";
import { usePagination } from "~/hooks";
import { getPlans } from "~/queries/billingsQueries";

export const PlansPage = () => {
  const navigate = useNavigate();
  const { handlePageChange } = usePagination();
  const searchParams = useSearchParams()[0];
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;

  const { data: plans, isLoading } = useQuery({
    queryKey: ["plans"],
    queryFn: async () => {
      try {
        const data = await getPlans();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const colums: ColumnDef<(typeof plans.data)[0]>[] = [
    {
      accessorFn: (row) => row.name,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Plan Name</span>,
      size: 8,
    },
    {
      id: "amount",
      cell: ({ row }) => {
        return row.original.processors.map((processor: any) => (
          <span
            key={processor.id}
            className="flex w-full text-right justify-end "
          >
            {processor.amount + " " + processor.currency}{" "}
          </span>
        ));
      },
      header: () => (
        <span className="flex w-full text-right justify-end">Amount</span>
      ),
      size: 8,
    },
    {
      id: "interval",
      cell: ({ row }) => (
        <div className="flex items-center gap-x-2">
          {row.original?.interval === "monthly" ? (
            <span className="py-1 px-2 rounded-full text-xs font-medium bg-[#FFDAC2] text-[#6E330C]">
              {row.original?.interval?.replace(/\b\w/g, (char: string) =>
                char.toUpperCase()
              )}
            </span>
          ) : (
            <span className="py-1 px-2 rounded-full text-xs font-medium bg-[#C2D6FF] text-[#162664]">
              {row.original?.interval?.replace(/\b\w/g, (char: string) =>
                char.toUpperCase()
              )}
            </span>
          )}
        </div>
      ),
      header: () => <span>Billing</span>,
      size: 15,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original?.status} />,
      header: () => <span>Status</span>,
      // size: 9.9,
    },
  ];

  return (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex items-center justify-between">
        <Header
          title="Subscription Plans"
          subtext="Manage your subscription plan"
        />
        <Button
          label="Create new plan"
          icon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                fill="white"
              />
            </svg>
          }
          leftIcon
          effect={() => navigate("/app/subscriptions/create")}
        />
      </div>
      <div className="flex flex-col mt-6 w-full h-full">
        {isLoading ? (
          <Loader big />
        ) : plans?.data.length > 0 ? (
          <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4">
            <Table
              clickFunction={navigate}
              key="subscriptions"
              cols={colums}
              rawData={plans?.data}
            />
          </div>
        ) : (
          <div className="mt-6 bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
            <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
              <img src="/empty.svg" className="w-[100px]" />
              <div className="flex flex-col items-center gap-y-2">
                <span className=" text-sm text-[#0A0D14]">
                  No plans to show
                </span>
                <span className=" text-sm text-[#525866]">
                  You have not created any subscription plans. Create one to get
                  started.
                </span>
              </div>
            </div>
            <Button
              mono
              label="Create new plan"
              icon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                    fill="black"
                  />
                </svg>
              }
              leftIcon
              effect={() => navigate("/app/subscriptions/create")}
            />
          </div>
        )}
      </div>
      {plans?.meta?.totalNumberOfRecords > 10 && (
        <Pagination
          page={page}
          limit={limit}
          pages={plans?.meta?.totalNumberOfPages}
          total={plans?.meta?.totalNumberOfRecords}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};
