/* eslint-disable no-irregular-whitespace */
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { Button, Header, Table } from "~/components";
import Loader from "~/components/UI/Loader";
import { useLocation } from "~/hooks";
import { getSubcriptions } from "~/queries/billingsQueries";
import { getFormattedDate } from "~/utils/dateUtils";
import { getFullMoney } from "~/utils/formatAmount";
import { useSearchParams } from "react-router-dom";

export const Billings = ({ move }: { move: (v: string) => void }) => {
  const { location, finding } = useLocation();

  const searchParams = useSearchParams()[0];
  // const status = searchParams.get("show") || "";

  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  // const { show, handlePaneSwitch } = usePanes(status);
  const { data: subscriptions, isLoading } = useQuery<
    ListResponse<Subscription>
  >({
    queryKey: ["subscriptions"],
    enabled: !finding,
    queryFn: async () => {
      try {
        const data = await getSubcriptions({
          page,
          limit,
          // "filter[status]": status,
        });
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const subscriptionColums: ColumnDef<Subscription>[] = [
    {
      id: "cost",
      cell: ({ row }) => (
        <span className="text-[#525866]">
          {getFullMoney(
            parseFloat(row.original.amount),
            row.original.currency,
            location?.languages.split(",")[0]
          )}{" "}
          {row.original.plan?.interval} + tax
        </span>
      ),
      header: () => <span>Cost</span>,
      size: 50,
    },
    {
      id: "plan",
      cell: ({ row }) => (
        <span className="text-[#525866]">
          {row.original.plan?.name} plan - {row.original.plan?.interval}
        </span>
      ),
      header: () => <span>Plan Name</span>,
      size: 50,
    },
  ];
  const billingHistoryColumns: ColumnDef<Subscription>[] = [
    {
      id: "cost",
      cell: ({ row }) => (
        <span className="text-[#525866]">{row.original.plan?.name} plan</span>
      ),
      header: () => <span>Plan</span>,
      size: 33.3,
    },
    {
      id: "cost",
      cell: ({ row }) => (
        <span className="text-[#525866]">
          {getFormattedDate(row.original.createdAt)}
        </span>
      ),
      header: () => <span>Date</span>,
      size: 33.3,
    },

    {
      id: "cost",
      cell: ({ row }) => (
        <span className="text-[#525866]">
          {getFullMoney(
            parseFloat(row.original.amount),
            row.original.currency,
            location?.languages.split(",")[0]
          )}
        </span>
      ),
      header: () => <span>Amount Paid</span>,
      size: 33.3,
    },
  ];

  return isLoading || finding ? (
    <Loader big />
  ) : (
    <div className="w-full flex flex-col">
      <Header
        title="Billing"
        subtext="Manage your billing information and invoices."
      />
      <div className="flex flex-col gap-y-3 w-full mt-6">
        <h3 className="font-medium text-sm">Subscription</h3>
        {subscriptions && subscriptions.data.length > 0 ? (
          <div className=" rounded-lg bg-white border flex flex-col w-full text-sm">
            <div className="p-5 border-b flex flex-col w-full gap-y-5">
              <Table
                key="subs"
                cols={subscriptionColums}
                rawData={[subscriptions.data?.[0]]}
              />
              <span className="text-sm text-[#525866]">
                Current billing period:
                <span className="text-[#0A0D14]">
                  {" "}
                  {getFormattedDate(subscriptions.data?.[0]?.startDate)} -{" "}
                  {getFormattedDate(subscriptions.data?.[0]?.endDate)}
                </span>
                 
              </span>
            </div>
            <div className="w-full items-center flex justify-end gap-x-3 p-5">
              <Button effect={() => move("plans")} label="Change Plan" />
            </div>
          </div>
        ) : (
          <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5 text-sm">
            <p className="text-[#0A0D14] font-medium">
              You're currently on the Free plan.
            </p>
            <p className="text-[#525866]">
              Upgrade your plan to collaborate with teammates, manage multiple
              clients, projects and connect your favorite apps
            </p>
            <button
              onClick={() => move("plans")}
              className="font-medium text-[#35B9E9] text-left w-fit"
            >
              View plans
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-3 w-full mt-6">
        <h3 className="font-medium text-sm">Billing History</h3>
        {subscriptions && subscriptions.data.length > 0 ? (
          <div className="p-5 rounded-lg bg-white border flex flex-col w-full ">
            <Table
              key="history"
              cols={billingHistoryColumns}
              rawData={subscriptions.data}
            />
          </div>
        ) : (
          <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5 text-sm">
            <p className="text-[#0A0D14] py-[14px] text-center">
              You have not made any subscriptions
            </p>
          </div>
        )}
      </div>
      {/* <div className='flex flex-col gap-y-3 w-full mt-6'>
        <h3 className='font-medium text-sm'>Payment Method</h3>
        <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5 text-sm items-center'>
          <div className='max-w-[260px] w-full flex flex-col items-center gap-y-5 py-10'>
            <img src='/empty.svg' className='w-[100px]' />
            <div className='flex flex-col items-center gap-y-2 text-center'>
              <span className=' text-sm text-[#525866]'>
                You have not added any payment method
              </span>
              <Button
                label='Add payment method'
                mono
                // effect={() => popup({ component: <Activate /> })}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
