/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Loader from '~/components/UI/Loader';
import { getWorkspace, getWorkspaces } from '~/queries/workspaceQueries';
import { WorkspaceRadioButton } from './components/RadioButton';
import { Button } from '~/components';
import { useForm } from '~/hooks';
import { useNavigate } from 'react-router-dom';

export const WorkspacePage = () => {
  const navigate = useNavigate();
  const { formData, update } = useForm({ initial: { selected: '' } });
  const { data, isLoading } = useQuery<Workspace[]>({
    queryKey: ['workspaces'],
    queryFn: async () => {
      try {
        const data = await getWorkspaces();
        if (data.length === 0) navigate('/signup/onboarding');
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => getWorkspace(formData.selected),
    ...{
      onSuccess(data: any) {
        window.location.href = `https://${data.domain}:${window.location.port}/app`;
      },
      onError(error) {
        toast.error(error.message);
        return false;
      },
    },
  });
  return isLoading ? (
    <Loader big />
  ) : (
    <div className='w-full h-full flex flex-col items-center justify-center overflow-scroll'>
      <h3 className='text-5xl font-semibold'>Choose workspace</h3>

      <div className='rounded-lg border bg-[#FCFCFC] p-4 flex flex-col items-center max-w-[400px] w-full mt-10'>
        <span className='max-w-[500px] text-center text-lg mt-3'>
          Hi, please choose a workspace to proceed with
        </span>
        <div className='flex flex-col mt-6 w-full gap-y-2 items-center'>
          {data?.map((workspace) => (
            <WorkspaceRadioButton
              selected={formData.selected == workspace.id}
              name='selected'
              updateData={update}
              value={workspace.id}
              workspace={workspace}
            />
          ))}
        </div>
        <Button
          label={'Continue'}
          loading={isPending}
          effect={mutate}
          disabled={!formData.selected}
          {...{ style: { marginTop: 20, width: '100%' } }}
        />
      </div>
    </div>
  );
};
