/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "~/providers";

import { OwnerDashboard } from "./OwnerDashboard";
import { TeamDashboard } from "./TeamDashboard";
import { OrganizationDashboard } from "./OrganizationDashboard";

export const DashboardPage = () => {
  const { role, workspace } = useAuth();

  if (!workspace.slug || !role.slug) return null;

  // TODO this should be moved to a function that determines which of the dashboards to display.
  if (workspace.slug?.toLowerCase() === "admin") {
    return <OwnerDashboard />;
  }
  if (role.slug?.toLowerCase() === "project-team-member") {
    return <TeamDashboard />;
  }

  return <OrganizationDashboard />;
};
