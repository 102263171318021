/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from "axios";
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;

export const getIntegrations = async (filter?: { integrated?: boolean }) => {
  const config = {
    method: "GET",
    params: {
      limit: 100,
      "filter[integrated]":
        typeof filter?.integrated === "undefined"
          ? undefined
          : filter.integrated
          ? "true"
          : "false",
    },
    url: `${baseUrl}/integrations`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getIntegrationStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/integrations/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getIntegrationItems = async (slug?: string, params?: any) => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${baseUrl}/integrations/${slug}/items`,
    params,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
