import { useState } from "react";
import {
  getEmailTemplates,
  getReportTemplates,
} from "~/queries/templateQueries";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Header,
  Pagination,
  SearchBar,
  // SearchDropdown,
  Table,
  TextPanes,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { useForm, usePanes, usePagination } from "~/hooks";
import { getFormattedDate } from "~/utils/dateUtils";

// import { openModal } from "~/providers";

export const Templates = () => {
  const { handlePageChange } = usePagination();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("show") || "";
  // const popup = openModal();
  // const search = searchParams.get("search") || "";
  const filterBy = searchParams.get("filterBy") || "name";
  const [_search, setSearch] = useState("");
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { show, handlePaneSwitch } = usePanes(status);
  const { formData } = useForm({
    initial: { filterBy },
  });

  const handleSearch = (value: string) => {
    setSearch(value);
    // Reset page to 1 when searching
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("search", value);
      return prev;
    });
  };

  const handleClearSearch = () => {
    setSearch("");

    setSearchParams((prev) => {
      prev.delete("search");
      prev.set("page", "1");
      return prev;
    });
  };

  const { data: templates, isLoading } = useQuery({
    queryKey: [
      "templates",
      {
        page,
        limit,
        status: show,
        search: searchParams.get("search") || "",
        ...formData,
      },
    ],
    queryFn: async () => {
      try {
        if (show === "reports") {
          return await getReportTemplates({
            page,
            limit,
            "filter[title]": searchParams.get("search") || "",
          });
        } else {
          return await getEmailTemplates({
            page,
            limit,
            "filter[title]": searchParams.get("search") || "",
          });
        }
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const panes = [
    {
      id: "",
      label: "Email",
      show: true,
    },
    {
      id: "reports",
      label: "Report",
      show: true,
    },
  ];

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => row.title,
      id: "title",
      cell: (info) => info.getValue(),
      header: () => <span>Title</span>,
      size: 26,
    },
    {
      accessorFn: (row) => getFormattedDate(row.createdAt),
      id: "description",
      cell: (info) => info.getValue(),
      header: () => <span>Date Created</span>,
      size: 26,
    },
  ];

  return (
    <div className="w-full flex flex-col h-full p-8">
      <div className="w-full flex items-center justify-between">
        <Header title="Templates" subtext="Manage your templates" />
        <Button
          label="Create Report Template"
          icon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                fill="white"
              />
            </svg>
          }
          leftIcon
          effect={() => navigate("/app/templates/create")}
        />
      </div>
      <div className="flex flex-col mt-6 w-full h-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />
        <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4 gap-y-5">
          <div className="flex items-center gap-2 sm:flex-row flex-col">
            <SearchBar
              placeholder="Search by title "
              initialValue={searchParams.get("search") || ""}
              onClear={handleClearSearch}
              onSearch={handleSearch}
            />
            {/* <SearchDropdown
              label="Filter By"
              name="filterBy"
              update={updateFilter}
              options={[
                { value: "name", label: "name" },
                { value: "email", label: "email" },
              ]}
              placeholder="All"
              value={filterBy}
            /> */}
          </div>
          {isLoading ? (
            <Loader big />
          ) : templates?.data?.length > 0 ? (
            <Table
              clickFunction={navigate}
              key="templates"
              cols={columns}
              rawData={templates.data}
            />
          ) : (
            <div className="mt-6 bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
              <div className="max-w-[370px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/emptytemplates.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No templates to show
                  </span>
                  <span className="max-w-[256px] text-sm text-[#525866]">
                    You have not created any templates. Create one to get
                    started
                  </span>
                  <div className="flex items-center gap-x-2 mt-3">
                    <Button
                      mono
                      label={`Create ${
                        show === "reports" ? "report" : "email"
                      } template`}
                      effect={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {templates?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={templates?.meta?.totalNumberOfPages}
            total={templates?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
