/* eslint-disable prefer-const */
import { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { PDFDocument } from "pdf-lib";
import { useNavigate, useSearchParams } from "react-router-dom";
// import * as papaparse from 'papaparse';
// import * as XLSX from 'xlsx';
import {
  ExpandableButton,
  Header,
  Pill,
  Table,
  TextPanes,
  Pagination,
  SearchBar,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { usePanes, usePagination } from "~/hooks";
import {
  getTransactions,
  getTransactionStats,
} from "~/queries/transactionQueries";
import { totoPascalCase } from "~/utils/helpers";
import { getFormattedDate } from "~/utils/dateUtils";
import { exportTransactions } from "~/mutations/billingsMutations";

export const TransactionsPage = () => {
  const { handlePageChange } = usePagination();
  const navigate = useNavigate();
  const [_search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("show") || "";
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { show, handlePaneSwitch } = usePanes(status);

  // Search handler
  const handleSearch = (value: string) => {
    setSearch(value);
    // Reset page to 1 when searching
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("search", value);
      return prev;
    });
  };

  const handleClearSearch = () => {
    setSearch("");

    setSearchParams((prev) => {
      prev.delete("search");
      prev.set("page", "1");
      return prev;
    });
  };

  const { data: transactions, isLoading } = useQuery({
    queryKey: [
      "transactions",
      { page, limit, status, search: searchParams.get("search") || "" },
    ],
    queryFn: async () => {
      try {
        const data = await getTransactions({
          page,
          limit,
          "filter[status]": status,
          "filter[reference]": searchParams.get("search") || "",
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    // keepPreviousData: true, // If you intended to use this, make sure it's correctly typed.
  });

  const transactionData = transactions?.data || [];

  const { data: transactionStats, isLoading: statsLoading } = useQuery({
    queryKey: ["transactions", "stats"],
    queryFn: async () => {
      try {
        const data = await getTransactionStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const exportMutation = useMutation({
    mutationFn: (format: "csv" | "pdf" | "xlsx") =>
      exportTransactions({ format }),
    onSuccess: async (data: any, format: "csv" | "pdf" | "xlsx") => {
      try {
        if (!data) {
          throw new Error("No data received from the server");
        }

        let blob: Blob | null = null;
        const filename = `transactions.${format}`;

        if (format === "pdf") {
          if (!(data instanceof ArrayBuffer)) {
            throw new Error("Received data is not an ArrayBuffer");
          }
          const pdfDoc = await PDFDocument.load(data);
          const pdfBytes = await pdfDoc.save();
          blob = new Blob([pdfBytes], { type: "application/pdf" });
        } else if (format === "csv") {
          blob = new Blob([data], { type: "text/csv" });
        } else if (format === "xlsx") {
          if (!(data instanceof ArrayBuffer)) {
            throw new Error("Received data is not an ArrayBuffer for XLSX");
          }
          blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        }

        if (!blob) {
          throw new Error("Failed to create a blob for the file export.");
        }

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);

        toast.success("Export completed successfully");
      } catch (error) {
        toast.error(`Export processing failed: ${(error as Error).message}`);
      }
    },
    onError: (error: Error) => {
      toast.error(`Export failed: ${error.message}`);
    },
  });

  const handleExport = async (format: "csv" | "pdf" | "xlsx") => {
    try {
      await exportMutation.mutateAsync(format);
    } catch (error) {
      toast.error(`Export error: ${(error as Error).message}`);
    }
  };

  const getTotalCount = (array: any[]) => {
    return array?.reduce((total, obj) => {
      return total + parseInt(obj?.count, 10);
    }, 0);
  };

  const panes = [
    {
      id: "",
      label: "All",
      show: true,
      value: getTotalCount(transactionStats),
    },
    ...(transactionStats?.map((stat: any) => ({
      id: stat.value,
      label: stat.value?.replace(/\b\w/g, (char: string) => char.toUpperCase()),
      show: true,
      value: stat.count,
    })) || []),
  ];

  const colums: ColumnDef<(typeof transactions.data)[0]>[] = [
    {
      id: "workspace",
      cell: ({ row }) => (
        <span>{totoPascalCase(row.original.workspace?.name)}</span>
      ),
      header: () => <span>Organisation Name</span>,
      size: 13,
    },
    {
      accessorFn: (row) => row.reference,
      id: "reference",
      cell: (info) => {
        const value = info.getValue() as string;
        return value.length > 15 ? value.slice(0, 15) + "..." : value;
      },
      header: () => <span>Reference</span>,
      size: 26,
    },
    {
      id: "amount",
      cell: ({ row }) => (
        <span className="flex w-full text-right ml-auto">
          {row.original.amount + " " + row.original.currency}{" "}
        </span>
      ),
      header: () => <span className="flex w-full text-right">Amount</span>,
      size: 26,
    },
    {
      id: "date",
      cell: ({ row }) => (
        <span>{getFormattedDate(row.original.createdAt)}</span>
      ),
      header: () => <span>Timestamp</span>,
      size: 17,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
    },
  ];

  return (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex items-center justify-between">
        <Header
          title="Transactions"
          subtext="Manage the transactions on your platform"
        />
        <ExpandableButton label="Export data">
          <div className="min-w-[160px] w-full flex flex-col items-start">
            <button
              className="kebab-button"
              type="button"
              onClick={() => handleExport("csv")}
              disabled={exportMutation.isPending}
            >
              {exportMutation.isPending && exportMutation.variables === "csv"
                ? "Exporting..."
                : "Export as .csv"}
            </button>
            <button
              className="kebab-button"
              type="button"
              onClick={() => handleExport("pdf")}
              disabled={exportMutation.isPending}
            >
              {exportMutation.isPending && exportMutation.variables === "pdf"
                ? "Exporting..."
                : "Export as .pdf"}
            </button>
            <button
              className="kebab-button"
              type="button"
              onClick={() => handleExport("xlsx")}
              disabled={exportMutation.isPending}
            >
              {exportMutation.isPending && exportMutation.variables === "xlsx"
                ? "Exporting..."
                : "Export as .xlsx"}
            </button>
          </div>
        </ExpandableButton>
      </div>
      <div className="flex flex-col mt-6 w-full h-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />
        <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4 gap-y-5">
          <div className="flex items-center gap-x-2">
            <SearchBar
              placeholder="Search by reference "
              initialValue={searchParams.get("search") || ""}
              onClear={handleClearSearch}
              onSearch={handleSearch}
            />
          </div>
          {isLoading || statsLoading ? (
            <Loader big />
          ) : transactionData.length > 0 ? (
            <Table
              clickFunction={navigate}
              key="transactions"
              cols={colums}
              rawData={transactionData}
            />
          ) : (
            <div className="mt-6 min-h-[400px] w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No transactions to show
                  </span>
                  <span className=" text-sm text-[#525866]">
                    Transactions that happen will show up here.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {transactions?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={transactions?.meta?.totalNumberOfPages}
            total={transactions?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
