/* eslint-disable @typescript-eslint/no-explicit-any */
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.css'; // For further customization
import { motion } from 'framer-motion';
export const PhoneTextInput = ({
  value,
  onChange,
  name,
  error,
}: {
  name: string;
  error?: any;
  value: any;
  onChange: (e: any) => void;
}) => {
  return (
    <div className='flex flex-col gap-y-1'>
      <label className='text-sm font-medium text-gray-950'>Phone Number</label>
      <PhoneInput
        country={'us'} // Default country
        value={value}
        onChange={(v) => onChange({ target: { value: v, name } })}
        inputStyle={{ borderColor: error ? '#EC5757' : undefined }}
        buttonStyle={{
          borderRadius: '12px',
          border: '1px solid  #E2E4E9',
          borderColor: error ? '#EC5757' : undefined,
        }}
      />
      {error && (
        <motion.span
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: error ? 1 : 0, height: error ? 1 : 0 }}
          className='text-xs text-[#EC5757] capitalize block'
        >
          {error ? error ?? ' 7s' : '7s'}
        </motion.span>
      )}
    </div>
  );
};
