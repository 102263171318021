/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl =
  import.meta.env.VITE_BASE_URL || "https://platform.api.wholistic.work";

export const getWorkspaces = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/workspaces`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getWorkspace = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/workspaces/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const adminGetWorkspaces = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/platform/workspaces`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const adminGetWorkspace = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/platform/workspaces/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const adminGetWorkspaceStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/workspaces/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
