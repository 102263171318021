/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Group, Header, TextInput } from "~/components";
import { SettingContainer } from "../../../components";
import { useForm } from "~/hooks";
import { openModal, useAuth } from "~/providers";
import { ChangePassword } from "./ChangePassword";
import { Enable2Fa } from "./Enable2Fa";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateProfile } from "~/mutations/profileMutations";
import { Disable2fa } from "./Disable2fa";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { fadeIn } from "~/constants/animations";
import { MAX_FILE_SIZE } from "~/constants/values";
import { uploadProfile } from "~/api";
import Loader from "~/components/UI/Loader";
import { handleError } from "~/utils/helpers";
import { DeleteAccount } from "./DeleteAccount";

export const Profile = () => {
  const theForm = new FormData();
  const { user, setUser } = useAuth();

  const [showAdd, setShowAdd] = useState(false);

  const { formData, update, setErrors, errors } = useForm({
    initial: {
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });
  const popup = openModal();
  const { mutate, isPending } = useMutation({
    mutationFn: (photoUrl?: string) =>
      updateProfile({
        ...formData,
        photoUrl: photoUrl,
      }),
    ...{
      onSuccess(data: any) {
        toast.success(`Profile updated succesfully`);
        setUser((prev: User) => ({ ...prev, ...data }));
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === "validation") {
          setErrors(handledError.messages);
        }
      },
    },
  });
  const { mutate: upload, isPending: uploading } = useMutation({
    mutationFn: (theForm: any) => uploadProfile(theForm),
    ...{
      onSuccess(data: { url: string }) {
        toast.success(`Adding photo to profile`);
        mutate(data.url);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const handleAvatarChange = (event: any) => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      toast.error("File size exceeds the maximum limit of 5MB.");
      return;
    }
    theForm.append("file", file);
    upload(theForm);
  };
  return (
    <div className="w-full flex flex-col">
      <Header title="Profile" subtext="Manage your Wholistic profile." />
      <div className="rounded-xl w-full border border-[#E2E4E9] mt-6">
        <div className="rounded-t-xl w-full flex flex-col items-center pb-5 pt-8 gap-y-3 bg-[#F5F7FA]">
          <div
            onMouseEnter={() => setShowAdd(true)}
            onMouseLeave={() => setShowAdd(false)}
            className="w-20 h-20 rounded-lg bg-[#6E3FF3] flex items-center justify-center text-2xl overflow-clip  text-white font-medium relative"
          >
            {uploading ? (
              <Loader bgColor="#ffffff" />
            ) : user.photoUrl ? (
              <img src={user.photoUrl} className="w-full h-full object-cover" />
            ) : (
              <>
                {user?.firstName?.[0]}
                {user?.lastName?.[0]}
              </>
            )}
            <Group key="upload fab">
              <input
                className="hidden"
                accept=".jpg, .jpeg, .png"
                onChange={handleAvatarChange}
                type="file"
                disabled={uploading}
                id="avatar"
              />
              <AnimatePresence>
                {showAdd ? (
                  <motion.div
                    variants={fadeIn}
                    animate="animate"
                    exit="exit"
                    initial="initial"
                    className="absolute inset-0 w-full h-full flex items-center justify-center bg-[#1D1D1D]/[70%] rounded-lg"
                  >
                    {uploading ? (
                      <Loader />
                    ) : (
                      <label
                        htmlFor="avatar"
                        className="bg-white rounded-full p-[5px] flex justify-center cursor-pointer items-center gap-x-[9px] text-xs text-[#263238] font-semibold"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5452 2.69844L5.7452 4.49844H2.3V15.2984H16.7V4.49844H13.2548L11.4548 2.69844H7.5452ZM6.8 0.898438H12.2L14 2.69844H17.6C17.8387 2.69844 18.0676 2.79326 18.2364 2.96204C18.4052 3.13082 18.5 3.35974 18.5 3.59844V16.1984C18.5 16.4371 18.4052 16.6661 18.2364 16.8348C18.0676 17.0036 17.8387 17.0984 17.6 17.0984H1.4C1.16131 17.0984 0.932387 17.0036 0.763604 16.8348C0.594821 16.6661 0.5 16.4371 0.5 16.1984V3.59844C0.5 3.35974 0.594821 3.13082 0.763604 2.96204C0.932387 2.79326 1.16131 2.69844 1.4 2.69844H5L6.8 0.898438ZM9.5 14.3984C8.18718 14.3984 6.92813 13.8769 5.99982 12.9486C5.07152 12.0203 4.55 10.7613 4.55 9.44844C4.55 8.13562 5.07152 6.87656 5.99982 5.94826C6.92813 5.01995 8.18718 4.49844 9.5 4.49844C10.8128 4.49844 12.0719 5.01995 13.0002 5.94826C13.9285 6.87656 14.45 8.13562 14.45 9.44844C14.45 10.7613 13.9285 12.0203 13.0002 12.9486C12.0719 13.8769 10.8128 14.3984 9.5 14.3984ZM9.5 12.5984C10.3354 12.5984 11.1366 12.2666 11.7274 11.6758C12.3181 11.0851 12.65 10.2839 12.65 9.44844C12.65 8.61301 12.3181 7.81179 11.7274 7.22105C11.1366 6.63031 10.3354 6.29844 9.5 6.29844C8.66457 6.29844 7.86335 6.63031 7.27261 7.22105C6.68187 7.81179 6.35 8.61301 6.35 9.44844C6.35 10.2839 6.68187 11.0851 7.27261 11.6758C7.86335 12.2666 8.66457 12.5984 9.5 12.5984Z"
                            fill="#0A0D14"
                          />
                        </svg>
                      </label>
                    )}
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </Group>
          </div>
          <span className="font-medium text-[#0A0D14]">Profile picture</span>
        </div>
        <div className="bg-white rounded-b-xl px-5 flex-col">
          <SettingContainer title="First Name">
            <div className="w-[30%]">
              <TextInput
                value={formData.firstName}
                error={errors.firstName}
                name="firstName"
                handleInputChange={update}
              />
            </div>
          </SettingContainer>
          <SettingContainer title="Last Name">
            <div className="w-[30%]">
              <TextInput
                value={formData.lastName}
                error={errors.lastName}
                name="lastName"
                handleInputChange={update}
              />
            </div>
          </SettingContainer>
          <SettingContainer title="Email Address">
            <div className="w-[30%]">
              <TextInput name="email" value={user?.user?.email} readOnly />
            </div>
          </SettingContainer>
          <SettingContainer
            title="Password"
            subtext="Change your current password."
          >
            <Button
              mono
              label="Change password"
              effect={() => popup({ component: <ChangePassword /> })}
            />
          </SettingContainer>
          <SettingContainer
            title="Two-Factor Authentication (2FA)"
            subtext="Add an additional layer of security to your account during login."
          >
            {user?.user?.twofaEnabled ? (
              <Button
                danger
                label="Disable 2FA"
                effect={() => popup({ component: <Disable2fa /> })}
              />
            ) : (
              <Button
                mono
                label="Set up 2FA"
                effect={() => popup({ component: <Enable2Fa /> })}
              />
            )}
          </SettingContainer>
          <SettingContainer
            title="Delete Account"
            subtext="Delete your account and all related data, including workspaces you own"
          >
            <Button
              danger
              label="Delete Account"
              effect={() => popup({ component: <DeleteAccount /> })}
            />
          </SettingContainer>
          {/* <SettingContainer
            title='Theme'
            subtext='Choose your preferred theme.'
          >
            <div className='flex items-center gap-x-2'>
              <button className='w-6 h-6 block rounded bg-[#35B9E9]'></button>
              <button className='w-6 h-6 block rounded bg-[#DF1C41]'></button>
              <button className='w-6 h-6 block rounded bg-[#38C793]'></button>
              <button className='w-6 h-6 block rounded bg-[#F2AE40]'></button>
              <button className='w-6 h-6 block rounded bg-[#6E3FF3]'></button>
              <button className='w-6 h-6 block rounded bg-[#E255F2]'></button>
              <button className='w-6 h-6 block rounded bg-[#375DFB]'></button>
              <button className='w-6 h-6 block rounded bg-[#F17B2C]'></button>
            </div>
          </SettingContainer> */}
          {/* <SettingContainer title='Language' subtext='Customize your language'>
            <div className='w-[30%]'>
              <SelectElement
                name='email'
                options={[{ label: 'English', value: 1 }]}
                onChange={update}
                value={'unyimetommy@gmail.com'}
              />
            </div>
          </SettingContainer> */}
          {/* <SettingContainer
            title='Delete profile'
            subtext='Delete your profile and other associated data. This action is irrevesible'
          >
            <Button danger label='Delete Profile' />
          </SettingContainer> */}
          <div className="w-full flex justify-end py-5">
            <Button
              label="Save changes"
              disabled={
                !formData.firstName ||
                !formData.lastName ||
                (formData.firstName == user.firstName &&
                  formData.lastName == user.lastName)
              }
              effect={() => mutate(user.photoUrl)}
              loading={isPending}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
