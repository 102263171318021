import { motion, AnimatePresence } from 'framer-motion';

interface PanesProps {
  panes: {
    id: string;
    label: string;
    show?: boolean;
  }[];
  active: string;
  handleChange: (id: string) => void;
}

export const Panes = ({ panes, active, handleChange }: PanesProps) => {
  //todo make sure that the setactive prop is passed down as a prop

  return (
    <motion.div key={panes.toString()}>
      <ul className='grid grid-cols-2 h-[56px] border-b border-[#E2E4E9]'>
        {panes
          .filter((pane) => pane.show)
          .map((pane) => (
            <li className='h-full relative' key={pane.id}>
              <motion.button
                onClick={() => handleChange(pane.id)}
                key={pane.label}
                className='flex w-full h-full items-center justify-center'
              >
                <span
                  style={{ color: active == pane.id ? '#35B9E9' : '#868C98' }}
                  className={`text-sm font-medium `}
                >
                  {pane.label}
                </span>
              </motion.button>
              <AnimatePresence key={panes.toString()} initial={false}>
                {active == pane.id && (
                  <motion.div
                    className='absolute bottom-0 left-0 right-0 h-[2px] bg-[#35B9E9] w-full'
                    layoutId={location.pathname}
                  ></motion.div>
                )}
              </AnimatePresence>
            </li>
          ))}
      </ul>
    </motion.div>
  );
};
