import { useMutation } from '@tanstack/react-query';
import { FormEvent } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TextInput } from '~/components';
import { useForm } from '~/hooks';
import { initForgotPassword } from '~/mutations/authMutations';
import { AuthScreen } from '~/screens';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { formData, update } = useForm({
    initial: { email: '' },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => initForgotPassword({ ...formData }),
    ...{
      onSuccess() {
        navigate('/forgot/verify', { state: { email: formData.email } });
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  const handleMutate = (e: FormEvent) => {
    e.preventDefault();
    mutate();
  };
  return (
    <AuthScreen icon='/auth/rocket.svg'>
      <div className='flex flex-col w-full'>
        <section className='flex flex-col'>
          <h1 className='text-[#0A0D14] text-[32px] font-medium'>
            Forgot password?
          </h1>
          <span className='text-sm text-[#525866]'>
            No worries! Just provide your registered email address and we'll send
            you instructions to reset your password.
          </span>
        </section>

        <form onSubmit={handleMutate} className='mt-4 flex flex-col gap-y-4'>
          <TextInput
            name='email'
            value={formData.email}
            label='Email'
            autoFocus
            placeholder='Enter your email address'
            handleInputChange={update}
          />
          <Link
            to='/'
            className='text-sm underline underline-offset-2 font-medium text-[#35B9E9]'
          >
            I remember my password
          </Link>
          <Button
            type='submit'
            label='Continue with email'
            disabled={!formData.email}
            loading={isPending}
          />
        </form>
      </div>
    </AuthScreen>
  );
};
