import { ChangeEvent, ReactNode, useState } from "react";
import { Button, TextInput } from "~/components";
import { AuthScreen } from "~/screens";
import { AnimatePresence, motion } from "framer-motion";
import { useForm } from "~/hooks";
import { RadioButton } from "./components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getProfile } from "~/queries/profileQueries";
import toast from "react-hot-toast";
import Loader from "~/components/UI/Loader";
import { Checkbox } from "./components/Checkbox";
import { createWorkspace } from "~/mutations/workspaceMutations";
import { useAuth } from "~/providers";
export const OnboardingPage = () => {
  const { setWorkspace } = useAuth();
  const [integrations, setIntegrations] = useState<string[]>([]);
  const [otherIntegrationsInput, setOtherIntegrationsInput] = useState("");
  const [purpose, setPurpose] = useState<string[]>([]);
  const { formData, update } = useForm({
    initial: {
      name: "",
      slug: "",
      size: "",
      utmMedium: "",
      otherMedium: "",
      otherIntegrations: "",
    },
  });
  const { data: profile, isLoading } = useQuery({
    queryKey: ["me"],
    queryFn: () => getProfile(),
    ...{
      throwOnError(error) {
        toast.error(error.message);
        // navigate('/');
        return false;
      },
    },
  });
  // OtherIntegrations
  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const predefinedIntegrations = integrations.filter(
        (item) => item !== "OtherIntegrations"
      );

      const customIntegrations = integrations.includes("OtherIntegrations")
        ? otherIntegrationsInput
            .split(",")
            .map((item) => item.trim())
            .filter(Boolean)
        : [];

      const allIntegrations = [
        ...predefinedIntegrations,
        ...customIntegrations,
      ];

      return createWorkspace({
        name: formData.name,
        slug: formData.slug,
        size: formData.size,
        utmMedium:
          formData.utmMedium == "Other"
            ? formData.otherMedium
            : formData.utmMedium,
        integrations: allIntegrations,
        serviceTypes: purpose,
        purpose,
      });
    },
    ...{
      onSuccess(data: any) {
        setWorkspace(data.data);
        window.location.href = `https://${data.data.domain}:${window.location.port}/app`;
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const [currentStep, setCurrentStep] = useState(0);
  const steps = 5;
  const next = () => {
    if (currentStep == steps) return;
    // if(currentStep == 0 && !formData.name) return
    setCurrentStep((prev) => ++prev);
  };
  const prev = () => {
    if (currentStep == 0) return;
    setCurrentStep((prev) => --prev);
  };

  const generateSlug = (e: ChangeEvent<HTMLInputElement>) => {
    update(e);
    update({
      target: {
        name: "slug",
        value: e.target.value.split(" ").join("-").toLowerCase(),
      },
    });
  };

  const updateIntegrations = (value: string) => {
    if (integrations.includes(value)) {
      setIntegrations((prev) => prev.filter((int) => int != value));
    } else {
      setIntegrations((prev) => [...prev, value]);
    }
  };
  const updatePurpose = (value: string) => {
    if (purpose.includes(value)) {
      setPurpose((prev) => prev.filter((int) => int != value));
    } else {
      setPurpose((prev) => [...prev, value]);
    }
  };
  const checked = (v: string) => {
    return integrations.includes(v);
  };
  const purposed = (v: string) => {
    return purpose.includes(v);
  };
  const getY = () => {
    switch (currentStep) {
      case 0:
        return 0;
      case 1:
        return -289;
      case 2:
        return -650;
      case 3:
        return -1160;
      case 4:
        return -1570;
      case 5:
        return -1950;

      default:
        return 0;
    }
  };

  return (
    <AuthScreen icon="/auth/chill.svg">
      {isLoading ? (
        <Loader big />
      ) : (
        <div className="flex flex-col w-full">
          <span className="w-full block h-[6px] bg-white/50 relative rounded-full">
            <motion.span
              initial={{ width: 0 }}
              animate={{ width: `${(currentStep / 5) * 100}%` }}
              className="absolute block inset-0 h-[6px] bg-[#53C2DA] rounded-full"
            ></motion.span>
          </span>
          <div className=" max-h-[615px] h-full overflow-clip mt-6">
            <AnimatePresence>
              <motion.div
                animate={{ y: getY() }}
                transition={{
                  type: "tween",
                  ease: [0.4, 0.0, 0.2, 1],
                  duration: 0.3,
                }}
                className="w-full"
              >
                <div className="flex flex-col w-full gap-y-20">
                  <Scroller isActive={currentStep == 0} key="heading">
                    <section className="flex flex-col gap-y-8">
                      <h1 className="text-[#0A0D14] text-[32px] font-medium">
                        Welcome {profile?.firstName} {profile?.lastName}😎
                      </h1>
                      <span className="text-sm text-[#525866]">
                        We're glad to have you choose Wholistic to manage your
                        clients and projects. To optimise your experience, we've
                        got a couple of things lined up. We promise it'll only
                        take a minute.
                      </span>
                      <Button label="Let's go!" effect={next} />
                    </section>
                  </Scroller>
                  <Scroller isActive={currentStep == 1} key="name and slug">
                    <section className="flex flex-col gap-y-8">
                      <h1 className="text-[#0A0D14] text-2xl font-medium">
                        Create a workspace
                      </h1>
                      <div className="flex flex-col gap-y-4">
                        <TextInput
                          name="name"
                          label="Workspace name"
                          placeholder="Enter your workspace name"
                          hint="You can use the name of your business or organization."
                          value={formData.name}
                          handleInputChange={generateSlug}
                          readOnly={currentStep != 1}
                        />
                        <div className="flex flex-col">
                          <label
                            htmlFor="slug"
                            className="text-sm text-[#0A0D14] font-medium"
                          >
                            Workspace Url
                          </label>
                          <div className="border border-[#E2E4E9] rounded-xl flex items-center flex-row-reverse mt-1">
                            <span className="border-l px-3 py-[10px] text-[#868C98] text-sm shrink-0">
                              .wholistic.work
                            </span>
                            <input
                              name="slug"
                              placeholder="URL name"
                              value={formData.slug}
                              onChange={update}
                              className="px-3 py-[10px] bg-white text-sm outline-none w-full rounded-l-xl"
                              readOnly={currentStep != 1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-x-3 items-start">
                        <Button mono label="Previous" effect={prev} />
                        <Button
                          disabled={!formData.name && !formData.slug}
                          label="Next"
                          effect={next}
                        />
                      </div>
                    </section>
                  </Scroller>
                  <Scroller isActive={currentStep == 2} key="number of peope">
                    <section className="flex flex-col gap-y-8">
                      <h1 className="text-[#0A0D14] text-2xl font-medium">
                        What's the number of people you would be working with?
                      </h1>
                      <div className="grid grid-cols-2 gap-3">
                        <RadioButton
                          selected={formData.size == "1"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"1"}
                        >
                          Just me
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "2 - 10"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"2 - 10"}
                        >
                          2 - 10
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "11-25"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"11-25"}
                        >
                          11 - 25
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "26 - 100"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"26 - 100"}
                        >
                          26 - 100
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "101 - 200"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"101 - 200"}
                        >
                          101 - 200
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "201 - 500"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"201 - 500"}
                        >
                          201 - 500
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "501"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"501"}
                        >
                          501+
                        </RadioButton>
                        <RadioButton
                          selected={formData.size == "unknown"}
                          name="size"
                          updateData={currentStep != 2 ? undefined : update}
                          value={"unknown"}
                        >
                          Unknown
                        </RadioButton>
                      </div>
                      <div className="flex justify-end gap-x-3 items-start">
                        <Button mono label="Previous" effect={prev} />
                        <Button
                          disabled={!formData.size}
                          label="Next"
                          effect={next}
                        />
                      </div>
                    </section>
                  </Scroller>
                  <Scroller isActive={currentStep == 3} key="for who">
                    <section className="flex flex-col gap-y-8">
                      <h1 className="text-[#0A0D14] text-2xl font-medium">
                        What would you be using Wholistic for?
                      </h1>
                      <div className="grid grid-cols-2 gap-3">
                        <Checkbox
                          updateData={
                            currentStep != 3
                              ? undefined
                              : () => updatePurpose("Project Management")
                          }
                          value={"Project Management"}
                          selected={purposed("Project Management")}
                        >
                          Project Management
                        </Checkbox>
                        <Checkbox
                          updateData={
                            currentStep != 3
                              ? undefined
                              : () => updatePurpose("Operations")
                          }
                          value={"Operations"}
                          selected={purposed("Operations")}
                        >
                          Operations
                        </Checkbox>
                        <Checkbox
                          updateData={
                            currentStep != 3
                              ? undefined
                              : () => updatePurpose("Testing")
                          }
                          value={"Testing"}
                          selected={purposed("Testing")}
                        >
                          Testing
                        </Checkbox>
                        <Checkbox
                          updateData={
                            currentStep != 3
                              ? undefined
                              : () => updatePurpose("Personal Work")
                          }
                          value={"Personal Work"}
                          selected={purposed("Personal Work")}
                        >
                          Personal Work
                        </Checkbox>
                        <Checkbox
                          updateData={
                            currentStep != 3
                              ? undefined
                              : () => updatePurpose("Professional Services")
                          }
                          value={"Professional Services"}
                          selected={purposed("Professional Services")}
                        >
                          Professional Services
                        </Checkbox>
                        <Checkbox
                          updateData={
                            currentStep != 3
                              ? undefined
                              : () => updatePurpose("Freelancing")
                          }
                          value={"Freelancing"}
                          selected={purposed("Freelancing")}
                        >
                          Freelancing
                        </Checkbox>
                      </div>
                      <div className="flex justify-end gap-x-3 items-start">
                        <Button mono label="Previous" effect={prev} />
                        <Button
                          disabled={purpose.length < 1}
                          label="Next"
                          effect={next}
                        />
                      </div>
                    </section>
                  </Scroller>
                  <Scroller isActive={currentStep == 4} key="medium">
                    <section className="flex flex-col gap-y-8">
                      <h1 className="text-[#0A0D14] text-2xl font-medium">
                        How did you hear about us?
                      </h1>
                      <div className="grid grid-cols-2 gap-3">
                        <RadioButton
                          selected={formData.utmMedium == "Referral"}
                          name="utmMedium"
                          updateData={currentStep != 4 ? undefined : update}
                          value={"Referral"}
                        >
                          Referral
                        </RadioButton>
                        <RadioButton
                          selected={formData.utmMedium == "Social Media"}
                          name="utmMedium"
                          updateData={currentStep != 4 ? undefined : update}
                          value={"Social Media"}
                        >
                          Social Media
                        </RadioButton>
                        <RadioButton
                          selected={formData.utmMedium == "News"}
                          name="utmMedium"
                          updateData={currentStep != 4 ? undefined : update}
                          value={"News"}
                        >
                          News
                        </RadioButton>
                        <RadioButton
                          selected={formData.utmMedium == "Search"}
                          name="utmMedium"
                          updateData={currentStep != 4 ? undefined : update}
                          value={"Search"}
                        >
                          Search e.g Google
                        </RadioButton>
                        <RadioButton
                          selected={formData.utmMedium == "Other"}
                          name="utmMedium"
                          updateData={currentStep != 4 ? undefined : update}
                          value={"Other"}
                        >
                          Other
                        </RadioButton>
                        {formData.utmMedium == "Other" && (
                          <input
                            name="otherMedium"
                            value={formData.otherMedium}
                            onChange={currentStep != 4 ? undefined : update}
                            className="p-4 border rounded-xl text-[#0A0D14] text-sm outline-none"
                            autoFocus={formData.utmMedium == "Other"}
                          />
                        )}
                      </div>
                      <div className="flex justify-end gap-x-3 items-start">
                        <Button mono label="Previous" effect={prev} />
                        <Button
                          disabled={!formData.utmMedium}
                          label="Next"
                          effect={next}
                        />
                      </div>
                    </section>
                  </Scroller>
                  <Scroller isActive={currentStep == 5} key="integrations">
                    <section className="flex flex-col gap-y-8">
                      <h1 className="text-[#0A0D14] text-2xl font-medium">
                        Which of these tools do you currently use?
                      </h1>
                      <div className="grid grid-cols-2 gap-3">
                        <Checkbox
                          updateData={
                            currentStep != 5
                              ? undefined
                              : () => updateIntegrations("Clickup")
                          }
                          value={"Clickup"}
                          selected={checked("Clickup")}
                        >
                          <img src="/auth/clickup.svg" />
                        </Checkbox>
                        <Checkbox
                          selected={checked("Slack")}
                          updateData={
                            currentStep != 5
                              ? undefined
                              : () => updateIntegrations("Slack")
                          }
                          value={"Slack"}
                        >
                          <img src="/auth/slack.svg" />
                        </Checkbox>
                        <Checkbox
                          selected={checked("Drive")}
                          updateData={
                            currentStep != 5
                              ? undefined
                              : () => updateIntegrations("Drive")
                          }
                          value={"Drive"}
                        >
                          <img src="/auth/drive.svg" />
                        </Checkbox>
                        <Checkbox
                          selected={checked("Chat")}
                          updateData={
                            currentStep != 5
                              ? undefined
                              : () => updateIntegrations("Chat")
                          }
                          value={"Chat"}
                        >
                          <img src="/auth/chat.svg" />
                        </Checkbox>
                        <Checkbox
                          selected={checked("Teams")}
                          updateData={
                            currentStep != 5
                              ? undefined
                              : () => updateIntegrations("Teams")
                          }
                          value={"Teams"}
                        >
                          <img src="/auth/teams.svg" />
                        </Checkbox>
                        <Checkbox
                          selected={integrations.includes("OtherIntegrations")}
                          updateData={
                            currentStep != 5
                              ? undefined
                              : () => {
                                  if (
                                    integrations.includes("OtherIntegrations")
                                  ) {
                                    setIntegrations((prev) =>
                                      prev.filter(
                                        (item) => item !== "OtherIntegrations"
                                      )
                                    );
                                    setOtherIntegrationsInput("");
                                  } else {
                                    setIntegrations((prev) => [
                                      ...prev,
                                      "OtherIntegrations",
                                    ]);
                                  }
                                }
                          }
                          value={"OtherIntegrations"}
                        >
                          Other Integrations
                        </Checkbox>

                        {integrations.includes("OtherIntegrations") && (
                          <input
                            name="otherIntegrations"
                            value={otherIntegrationsInput}
                            onChange={
                              currentStep != 5
                                ? undefined
                                : (e) => {
                                    setOtherIntegrationsInput(e.target.value);
                                  }
                            }
                            placeholder="Enter integrations separated by commas"
                            className="p-4 border rounded-xl text-[#0A0D14] text-sm outline-none"
                            autoFocus
                          />
                        )}
                      </div>
                      <div className="flex justify-end gap-x-3 items-start">
                        <Button mono label="Previous" effect={prev} />
                        <Button
                          label="Finish"
                          effect={mutate}
                          loading={isPending}
                        />
                      </div>
                    </section>
                  </Scroller>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}
    </AuthScreen>
  );
};

const Scroller = ({
  children,

  isActive,
}: {
  children: ReactNode;
  isActive: boolean;
}) => {
  return (
    <motion.div
      animate={{
        opacity: isActive ? 1 : 0.4,
      }}
      className="w-full"
    >
      {children}
    </motion.div>
  );
};
