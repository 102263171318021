export const Header = ({
  title,
  subtext,
}: {
  title: string;
  subtext: string;
}) => {
  return (
    <div className='flex flex-col gap-y-1'>
      <h2 className='text-[#0A0D14] text-lg font-medium'>{title}</h2>
      <span className='text-[#525866] text-sm'>{subtext}</span>
    </div>
  );
};
