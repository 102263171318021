import { MouseEventHandler } from "react";

export const SignupWithGoogle = ({
  url,
  onClick,
}: {
  url: string;
  onClick?: MouseEventHandler;
}) => {
  return (
    <a
      href={url}
      onClick={onClick}
      className="flex items-center justify-center gap-x-2 py-[10px] px-4 rounded-xl text-sm text-white font-medium bg-[#F14336]"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.153 8.63672V11.5413H14.2718C14.0909 12.4754 13.5482 13.2663 12.7342 13.7981L15.218 15.6868C16.6651 14.3777 17.5 12.455 17.5 10.1709C17.5 9.63908 17.4513 9.12767 17.3608 8.6368L10.153 8.63672Z"
          fill="white"
        />
        <path
          d="M3.32106 6.63867C2.79929 7.64773 2.50015 8.78639 2.50015 10C2.50015 11.2136 2.79929 12.3523 3.32106 13.3613C3.32106 13.3681 5.8675 11.425 5.8675 11.425C5.71444 10.975 5.62397 10.4977 5.62397 9.99993C5.62397 9.50214 5.71444 9.02489 5.8675 8.57489L3.32106 6.63867Z"
          fill="white"
        />
        <path
          d="M10.153 5.48638C11.2801 5.48638 12.2819 5.86819 13.082 6.60457L15.2736 4.45685C13.9447 3.24323 12.2194 2.5 10.153 2.5C7.16135 2.5 4.5802 4.1841 3.32092 6.63866L5.86728 8.57504C6.47254 6.80228 8.1632 5.48638 10.153 5.48638Z"
          fill="white"
        />
        <path
          d="M5.86399 11.4277L5.30381 11.848L3.32092 13.3616C4.5802 15.8093 7.1612 17.5003 10.1528 17.5003C12.2191 17.5003 13.9515 16.8321 15.2178 15.6866L12.734 13.798C12.0522 14.248 11.1825 14.5207 10.1528 14.5207C8.16304 14.5207 6.47245 13.2048 5.86712 11.4321L5.86399 11.4277Z"
          fill="white"
        />
      </svg>
      <span>Sign up with Google</span>
    </a>
  );
};
