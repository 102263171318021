/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './styles.module.css';
import { motion } from 'framer-motion';

interface TextAreaProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: any;
  name?: string;
  value: string | undefined;
  autoFocus?: boolean;
  handleInputChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  props?: object;
  readOnly?: boolean;
  hint?: string;
  rows?: number;
  className?: string;
}

export const TextArea = ({
  label,
  placeholder,
  rows = 5,
  required = false,
  name,
  autoFocus = false,
  error,
  value,
  readOnly,
  hint,
  handleInputChange,
  className,
  ...props
}: TextAreaProps) => {
  return (
    <div className={styles.input_container}>
      {label && (
        <label className={styles.input_label} htmlFor={name}>
          {label}
          {required && <span className='input--required'></span>}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        autoFocus={autoFocus}
        required={required}
        id={name}
        rows={rows}
        name={name}
        style={{ borderColor: error ? '#EC5757' : undefined }}
        className={`${styles.input_field} ${className}`}
        readOnly={readOnly}
        {...props}
      />
      {error && (
        <motion.span
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: error ? 1 : 0, height: error ? 1 : 0 }}
          className='text-xs text-[#EC5757] capitalize block'
        >
          {error ? error ?? ' 7s' : '7s'}
        </motion.span>
      )}
      <span>{hint}</span>
    </div>
  );
};
