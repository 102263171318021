/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl =
  import.meta.env.VITE_BASE_URL || "https://platform.api.wholistic.work";

export const getPlans = async (params?: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/plans`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
    params,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getPlan = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/plans/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getSubcriptions = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/subscriptions`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getSubscriptionStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/subscriptions/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getCards = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/cards`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getFeatures = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/features`,
    headers,
    // params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
