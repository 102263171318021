import { Group } from '../../components';
import { ReactNode } from 'react';

export const AuthScreen = ({
  icon,
  children,
}: {
  icon: string;
  children: ReactNode;
}) => {
  return (
    <div className=' w-full h-full flex items-center justify-center gap-x-[230px]'>
      <Group key='left'>
        <div className='max-w-[456px] w-full'>{children}</div>
      </Group>
      <Group key='right'>
        <div className='min-w-[410px]'>
          <img src={icon} alt='onboarding graphic' />
        </div>
      </Group>
    </div>
  );
};
