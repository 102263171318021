/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { addProjectReport, getReportTemplates } from '~/api';
import { Button } from '~/components';
import Loader from '~/components/UI/Loader';

export const AddProjectReport = ({
  id,
  close,
}: {
  id: any;
  close?: () => void;
}) => {
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  const { data: reports, isLoading } = useQuery<ListResponse<ReportTemplate>>({
    queryKey: ['project', 'templates'],
    queryFn: async () => {
      try {
        const data = await getReportTemplates();

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => addProjectReport(id, { reportTemplateId: selected }),
    ...{
      onSuccess(data: any) {
        navigate(`app/projects/${id}/reports/${data.data.id}`);
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  return (
    <div className='w-full flex flex-col  min-w-[76vw] min-h-[81vh]'>
      <header className='flex items-start w-full justify-between border-b py-4 px-5'>
        <div className='flex flex-col gap-y-1  max-w-[440px]'>
          <h4 className='text-[#0A0D14] font-medium'>
            Create new report template
          </h4>
          <span className='text-sm text-[#525866]'>
            Use our pre-built report templates to visualise your project data
          </span>
        </div>
        <button onClick={close}>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z'
              fill='#525866'
            />
          </svg>
        </button>
      </header>
      <section className='p-5 flex flex-col flex-1 w-full gap-y-5 h-full'>
        <span className='text-sm'>
          Select the template you want to start with:
        </span>
        {isLoading ? (
          <div className='w-full h-full py-20'>
            <Loader big />
          </div>
        ) : reports?.data && reports.data.length > 0 ? (
          <div className='grid grid-cols-4 w-full h-full overflow-scroll remove-scroll'>
            {reports.data.map((r) => (
              <button
                onClick={() => setSelected(r.id)}
                style={{
                  background: selected == r.id ? '#F1FBFE' : undefined,
                }}
                key={r.id}
                className='flex flex-col gap-y-[10px] transition duration-200 ease-in-out hover:bg-[#F1FBFE] rounded-lg p-2 text-[#0A0D14]'
              >
                <div className='rounded-lg border border-[#E2E4E9] h-[180px] w-full overflow-clip'>
                  <img
                    src={r.bannerUrl}
                    alt={r.title}
                    className='w-full h-full object-cover'
                  />
                </div>
                <span className='font-medium text-sm '>{r.title}</span>
              </button>
            ))}
          </div>
        ) : (
          <div className='w-full h-full flex items-center justify-center'>
            <div className='max-w-[260px] w-full flex flex-col items-center gap-y-5 '>
              <img src='/empty.svg' className='w-[100px]' />
              <div className='flex flex-col items-center gap-y-2'>
                <span className=' text-sm text-[#0A0D14]'>
                  No templates to show
                </span>
                <span className=' text-sm text-[#525866]'>
                  You have not created any templates
                </span>
              </div>
            </div>
          </div>
        )}
      </section>
      <footer className='px-5 py-4 border-t flex justify-end items-center gap-x-3'>
        <Button mono label='Cancel' effect={close} />
        <Button
          effect={mutate}
          label='Proceed'
          disabled={!selected}
          loading={isPending}
        />
      </footer>
    </div>
  );
};
