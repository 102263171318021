export const Pill = ({
  status,
  label,
}: {
  status: string | undefined | null;
  label?: string;
}) => {
  let bg;
  switch (status?.toLowerCase()) {
    case 'inactive':
      bg = '#868C98';

      break;
    case 'rejected':
    case 'blocked':
      bg = '#DF1C41';

      break;
    case 'scheduled':
      bg = '#375DFB';

      break;
    case 'active':
    case 'successful':
    case 'accepted':
    case 'connected':
    case 'approved':
      bg = '#38C793';

      break;
    case 'pending':
    case 'submitted and pending approval':
    case 'ongoing':
      bg = '#F17B2C';

      break;

    case 'transfer in progress':
      bg = '#EBF0FF';

      break;
    default:
      bg = '#000';
  }
  return (
    <span
      className={` text-[#525866] px-2 py-1 text-xs capitalize rounded-[8px] bg-white flex items-center gap-x-2 w-fit font-medium border border-[#E2E4E9]`}
    >
      <span
        style={{ background: bg }}
        className='w-[5px] h-[5px] rounded-full block'
      ></span>
      {label || status}
    </span>
  );
};
