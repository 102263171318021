/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  exportProjectReport,
  getProjectReport,
  updateProjectReport,
} from "~/api";
import { Button, ExpandableButton, Group } from "~/components";
import Loader from "~/components/UI/Loader";
import Handlebars from "handlebars";
import { PDFDocument } from "pdf-lib";

import { AnimatePresence, motion } from "framer-motion";
import { ReportComments } from "./sidebar/Comments";
import { openModal, useAuth } from "~/providers";
import { useForm } from "~/hooks";
import { SendEmail } from "~/actions";
import { isActionAllowed } from "~/utils/authHelper";
import { getProjectUsers } from "~/queries/projectQueries";

export const PreviewReportPage = () => {
  const { user } = useAuth();
  const [sections, setSections] = useState<any>([]);
  const popup = openModal();
  const { id, reportId } = useParams();
  const [sideBar, setSidebar] = useState({ open: false, active: "" });

  const navigate = useNavigate();
  const { formData, setData } = useForm({
    initial: { title: "", startDate: "", endDate: "" },
  });

  const { data: report, isLoading: templateLoading } = useQuery<ProjectReport>({
    queryKey: ["report", reportId],
    queryFn: async () => {
      try {
        const data = await getProjectReport(id, reportId);
        setData({
          title: data.data.title,
          startDate: dayjs(data.data.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(data.data.endDate).format("YYYY-MM-DD"),
        });

        setSections(
          data.data.data?.sections
            ? Object.values(data.data.data.sections)
            : [
                {
                  title: "Highlights",
                  titleBackgroundColor: "#2D9F75",
                  titleTextColor: "#ffffff",
                  bodyBackgroundColor: "#EFFAF6",
                  bodyTextColor: "#0E121B",
                  content: `<ul> 
                                <li>
                                  <strong>Design:</strong> The designs for the
                                  Platform Owner modules have been completed and
                                  are scheduled for review this week. Most of
                                  the Project Team modules designs have been
                                  completed in exception of the Template module.
                                </li>
                                
                              </ul>`,
                },
              ]
        );
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { data: users } = useQuery<User[]>({
    queryKey: ["project-users", "list", id],
    queryFn: async () => {
      try {
        const data = await getProjectUsers(id);
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
  });

  const template = React.useMemo(() => {
    return Handlebars.compile(report?.reportTemplate?.body || "", {
      noEscape: true,
    })
  }, [report?.reportTemplate?.body])

  const html = React.useMemo(() => {
    if (!report) return "";
    const html = template({
      ...report?.data,
      sections: sections.map(({ content, ...section }: any) => ({
        content,
        ...section,
      })),
    });
    return html;
  }, [report, sections, template]);
  const { mutate: update, isPending: mutating } = useMutation({
    mutationFn: (status: string) =>
      updateProjectReport(id, reportId, {
        title: report?.title,
        body: "",
        startDate: report?.startDate,
        endDate: report?.endDate,
        reviewerUserId: user?.id,
        reportTemplateId: report?.reportTemplateId,
        status: status, // approved | pending | declined
      }),
    ...{
      onSuccess() {
        toast.success(`Report updated succesfully`);
        navigate(`/app/projects/${id}`);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const { mutate: exportReport, isPending: exporting } = useMutation({
    mutationFn: () => exportProjectReport(id, reportId),
    ...{
      async onSuccess(data: any) {
        const pdfDoc = await PDFDocument.load(data);

        // Save the modified PDF (even if unmodified, this can sometimes help)
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${report?.title}.pdf`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  return templateLoading ? (
    <Loader big />
  ) : (
    <div className="flex w-full justify-between oveflow-clip h-full px-8 pt-8">
      <div className="flex flex-col text-[#0A0D14] oveflow-clip w-screen">
        <div className="flex items-center w-full justify-between pr-8">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="flex items-center gap-x-2 text-[#0A0D14] text-lg font-medium"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
                fill="#525866"
              />
            </svg>
            <span> {formData?.title}</span>
          </button>
          <div className="flex items-center gap-x-2">
            <Button mono label="Cancel" effect={() => navigate(-1)} />
            <ExpandableButton label="Actions" loading={mutating}>
              <div className="min-w-[168px] w-full flex flex-col items-start">
                {report?.status !== "approved" &&
                  isActionAllowed("update-project-report") && (
                    <Link
                      className="kebab-button"
                      to={`/app/projects/${id}/reports/${reportId}`}
                    >
                      Edit
                    </Link>
                  )}
                {isActionAllowed("export-project-report") && (
                  <button
                    className="kebab-button"
                    onClick={() => exportReport()}
                  >
                    {exporting ? (
                      <span className="flex items-center justify-center w-full">
                        <Loader />
                      </span>
                    ) : (
                      "Export"
                    )}
                  </button>
                )}
                {report?.status !== "approved" &&
                  isActionAllowed("update-project-report") && (
                    <button
                      className="kebab-button"
                      onClick={() => update("pending")}
                    >
                      Submit
                    </button>
                  )}
                {report?.status === "pending" &&
                  isActionAllowed("approve-project-report") && (
                    <button
                      className="kebab-button"
                      onClick={() => update("approved")}
                    >
                      Approve
                    </button>
                  )}
                {report?.status === "pending" &&
                  isActionAllowed("decline-project-report") && (
                    <button
                      onClick={() => update("declined")}
                      className="kebab-button"
                    >
                      Reject
                    </button>
                  )}
                {report?.status === "approved" &&
                  isActionAllowed("export-project-report") && (
                    <button
                      onClick={() =>
                        popup({
                          component: (
                            <SendEmail
                              reportId={reportId!}
                              projectId={id!}
                              users={users}
                            />
                          ),
                        })
                      }
                      className="kebab-button"
                    >
                      Send
                    </button>
                  )}
              </div>
            </ExpandableButton>
          </div>
        </div>
        <div className="flex items-start gap-x-4 h-full mt-6">
          <Group key="template">
            <div className="px-[60px] overflow-scroll h-full w-full report-content">
              <iframe
                title={report?.title}
                srcDoc={html}
                width="100%"
                height="100%"
                style={{
                  border: "none",
                  overflow: "hidden",
                }}
              ></iframe>
            </div>
          </Group>
        </div>
      </div>
      <Group key="open sidebar">
        <AnimatePresence>
          {sideBar.open && (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: "37%" }}
              exit={{ opacity: 0, width: 0 }}
              className="bg-white flex rounded-lg flex-col h-[84%] w-full border-l absolute right-[106px] z-[30]"
            >
              {sideBar.active === "comments" && (
                <ReportComments
                  close={() => setSidebar({ open: false, active: "" })}
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Group>
      <Group key="side bar menu">
        <div className="h-full shrink-0 flex relative border-l z-[30] rounded-lg">
          <div className="bg-white h-full w-[76px] rounded-lg p-3 flex flex-col gap-y-3">
            <button
              style={{
                background:
                  sideBar.active === "comments" ? "#EBFAFF" : undefined,
                fill: sideBar.active === "comments" ? "#35B9E9" : undefined,
              }}
              onMouseDown={() => setSidebar({ open: true, active: "comments" })}
              className="h-11 w-full rounded-lg bg-[#F6F8FA] hover:bg-[#EBFAFF] fill-[#525866] hover:fill-[#35B9E9] transition duration-200 ease-in-out flex items-center justify-center"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.84125 15.25L2.5 17.875V4C2.5 3.80109 2.57902 3.61032 2.71967 3.46967C2.86032 3.32902 3.05109 3.25 3.25 3.25H16.75C16.9489 3.25 17.1397 3.32902 17.2803 3.46967C17.421 3.61032 17.5 3.80109 17.5 4V14.5C17.5 14.6989 17.421 14.8897 17.2803 15.0303C17.1397 15.171 16.9489 15.25 16.75 15.25H5.84125ZM5.32225 13.75H16V4.75H4V14.7887L5.32225 13.75ZM9.25 8.5H10.75V10H9.25V8.5ZM6.25 8.5H7.75V10H6.25V8.5ZM12.25 8.5H13.75V10H12.25V8.5Z"
                  fill="#525866"
                />
              </svg>
            </button>
            <svg
              width="52"
              height="1"
              viewBox="0 0 52 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="52" y2="0.5" stroke="#E2E4E9" />
            </svg>
          </div>
        </div>
      </Group>
    </div>
  );
};
