/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Unsaved } from '~/actions';
import { Button, PhoneTextInput, TextInput } from '~/components';
import Loader from '~/components/UI/Loader';
import { useForm } from '~/hooks';
import { updateClient } from '~/mutations/clientMutations';
import { openModal } from '~/providers';
import { getClient } from '~/queries/clientQueries';
import { handleError } from '~/utils/helpers';

export const EditClients = () => {
  const navigate = useNavigate();
  const popup = openModal();
  const { id } = useParams();
  const qc = useQueryClient();
  const { formData, update, setData, setErrors, errors } = useForm({
    initial: {
      name: '',
      email: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactName2: '',
      contactEmail2: '',
      contactPhone2: '',
    },
  });

  const { data: client, isLoading } = useQuery({
    queryKey: ['client', id],
    queryFn: async () => {
      try {
        const data = await getClient(id);
        setData({
          name: data.name,
          email: data.email,
          contactName: data.contactName,
          contactEmail: data.contactEmail,
          contactPhone: data.contactPhone,
          contactName2: data.contactName2,
          contactEmail2: data.contactEmail2,
          contactPhone2: data.contactPhone2,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () => updateClient({ ...formData }, id),
    ...{
      onSuccess() {
        toast.success(`${formData.name} updated succesfully`);
        qc.invalidateQueries({ queryKey: ['clients'] });
        navigate('/app/clients');
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === 'validation') {
          setErrors(handledError.messages);
        }
      },
    },
  });

  const areObjectsEqual = (obj1: any, obj2: any) => {
    // Check if both arguments are objects
    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      return obj1 === obj2; // If not objects, compare the values directly
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);

    // Iterate over the keys and compare the values
    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      // Recursively compare nested objects
      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false; // If values are not equal, return false
      }
    }

    return true; // If all values are equal, return true
  };

  const handleExit = () => {
    if (!areObjectsEqual) {
      navigate(-1);
    } else {
      popup({ component: <Unsaved /> });
    }
  };

  return isLoading ? (
    <Loader big />
  ) : (
    <div className='flex flex-col w-full text-[#0A0D14] oveflow-clip  h-full px-8 pt-10 pb-11'>
      <div className='flex items-center w-full justify-between'>
        <button
          onClick={handleExit}
          className='flex items-center gap-x-2 text-[#0A0D14] font-medium'
        >
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z'
              fill='#525866'
            />
          </svg>
          <span>Edit client</span>
        </button>
        <div className='flex items-center gap-x-2'>
          <Button mono label='Discard' effect={handleExit} />
          <Button
            label='Save Changes'
            effect={mutate}
            loading={isPending}
            disabled={areObjectsEqual(formData, client)}
          />
        </div>
      </div>
      <div className='flex flex-col items-center w-full overflow-scroll'>
        <div className='flex flex-col gap-y-8 w-full items-center mt-6 max-w-[512px] mx-auto'>
          <div className='flex flex-col gap-y-5 w-full'>
            <h3 className='font-medium'>Company Details</h3>
            <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5'>
              <TextInput
                name='name'
                label='Company Name'
                value={formData.name}
                error={errors.name}
                placeholder='Acme Inc'
                autoFocus
                handleInputChange={update}
              />
              <TextInput
                value={formData.email}
                name='email'
                error={errors.email}
                type='email'
                label='Official Email'
                placeholder='support@acme.com'
                handleInputChange={update}
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-5 w-full'>
            <h3 className='font-medium'>Primary contact details</h3>
            <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5'>
              <TextInput
                value={formData.contactName}
                name='contactName'
                label='Full Name'
                placeholder='John Doe'
                error={errors.contactName}
                handleInputChange={update}
              />
              <TextInput
                value={formData.contactEmail}
                name='contactEmail'
                error={errors.contactEmail2}
                type='email'
                label='Email Address'
                placeholder='john@acmee.com'
                handleInputChange={update}
              />
              <PhoneTextInput
                value={formData.contactPhone}
                error={errors.contactPhone}
                name='contactPhone'
                onChange={update}
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-5 w-full'>
            <h3 className='font-medium'>
              Secondary contact details (optional)
            </h3>
            <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5'>
              <TextInput
                value={formData.contactName2}
                name='contactName2'
                label='Full Name'
                placeholder='Jane Doe'
                handleInputChange={update}
              />
              <TextInput
                value={formData.contactEmail2}
                name='contactEmail2'
                label='Email Address'
                placeholder='jane@acme.com'
                handleInputChange={update}
              />
              <PhoneTextInput
                value={formData.contactPhone2}
                name='contactPhone2'
                onChange={update}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
