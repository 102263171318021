/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueries } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { addProjectIntegrations } from "~/api";
import { Button, MultiSelect, SelectElement } from "~/components";
import { useForm } from "~/hooks";
import { useApps } from "~/hooks/useLists";
import { getIntegrationItems } from "~/queries/integrationQueries";

export const AddProjectIntegration = ({
  id,
  app,
  close,
  refetch,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any;
  app?: Integration;
  refetch?: () => void;
  close?: () => void;
}) => {
  const [selected, setSelected] = useState<Integration | undefined>(app);
  const [selectedHierarchies, setSelectedHierarchies] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);

  const { list: integrationsList = [], data = [] } = useApps();
  const { formData, update } = useForm({
    initial: {
      id: app?.id || "",
    },
  });

  const queries = useQueries({
    queries: (selected?.hierarchies || []).map((h: string, index: number) => {
      const options = {
        queryKey: ["apps", selected?.slug, "items", h, { search }],
        queryFn: async () => {
          try {
            const data = await getIntegrationItems(selected?.slug, {
              search: search || undefined,
              hierarchies: selectedHierarchies,
              limit: 1000,
            });
            return data.data;
          } catch (error: any) {
            toast.error(error?.message);
          }
        },
        enabled: index === selectedHierarchies.length,
      };
      return options;
    }),
  });

  const handleSetApp = (e: any) => {
    update(e);
    const [s] = data.filter((d: { id: any }) => d.id == e.target.value);
    setSelected(s);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      addProjectIntegrations(id, {
        items,
        userIntegrationId:
          queries[queries.length - 1]?.data?.[0]?.userIntegrationId,
      }),
    ...{
      onSuccess() {
        toast.success("Resource Linked successfully");
        refetch?.();
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  return (
    <div className="w-full flex flex-col  ">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Link project resource</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section
        // style={{ height: itemsList?.length > 3 ? 400 : "auto" }}
        style={{ height: 400 }}
        className="p-5 flex flex-col w-full gap-y-5 "
      >
        <SelectElement
          options={integrationsList}
          onChange={handleSetApp}
          value={formData.id}
          name="id"
          label="App"
          placeholder="Select"
        />
        {selected?.hierarchies?.map((h, index) => {
          const options =
            queries[index].data?.map((i: any) => ({
              value: i.itemId,
              label: i.name,
            })) || [];
          if (index === (selected?.hierarchies?.length || 0) - 1) {
            return (
              <MultiSelect
                options={options}
                setSelected={setItems}
                selected={items}
                handleSearch={setSearch}
                name="itemId"
                hint="Data will be synced to the current project"
                label={`From ${selected?.name}`}
                placeholder={`Select ${h}`}
              />
            );
          }
          return (
            <SelectElement
              key={index}
              searchable
              options={options}
              onChange={(e) =>
                setSelectedHierarchies(
                  Array.from(new Set([...selectedHierarchies, e.target.value]))
                )
              }
              value={selectedHierarchies[index]}
              name="id"
              label={`Select ${h}`}
              placeholder="Select"
            />
          );
        })}
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        <Button
          label="Connect project"
          effect={mutate}
          loading={isPending}
          disabled={!formData.id || items.length == 0}
        />
      </footer>
    </div>
  );
};
