/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

export const Checkbox = ({
  value,
  selected,
  updateData,
  children,
}: {
  updateData: any;
  selected: boolean;
  value: string;
  children: ReactNode;
}) => {
  return (
    <div className='relative'>
      <label
        className='p-4 border rounded-xl text-center flex justify-center cursor-pointer text-[#0A0D14] duration-200 transition-all ease-in-out hover:bg-[#F8F9FF] text-sm font-medium'
        style={{
          backgroundColor: selected ? '#F1FBFE' : '#FFFFFF',
          borderColor: selected ? '#5FD7F4' : '#E2E4E9',
          opacity: 0.8,
        }}
        htmlFor={value}
      >
        {children}
      </label>
      <input
        type='checkbox'
        id={value}
        className='hidden'
        name={'integrations'}
        // checked={selected}
        onChange={updateData}
      />
    </div>
  );
};
