/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { AnimatePresence, motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";

interface SelectElementProps {
  label?: string;
  value?: any;
  options: {
    value: string | number | any;
    label: string;
  }[];
  hint?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  error?: any;
  searchable?: boolean;

  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSearch?: (term: string) => void;
}

export const SelectElement = ({
  label = "",
  options,
  name,
  value,
  error,
  hint,
  onChange,
  placeholder,
  required = false,
  searchable = false,
  handleSearch,
}: // hint,
SelectElementProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const setSearchTerm = (term: string) => {
    if (handleSearch) handleSearch(term);
    else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  const setSearchTermDebounced = useDebouncedCallback(
    setSearchTerm,
    handleSearch ? 500 : 100
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
    setSearchTerm("");
    if (searchRef.current) searchRef.current.value = "";
  };

  useEffect(() => {
    // Event listener for clicks outside the component
    const handleClickOutside = (e: any) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(e.target)
      ) {
        handleClose();
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  useEffect(() => {}, [value]);

  return (
    <div className="select-container">
      {label ? (
        <label className="select-label" htmlFor={name}>
          {label}
          {required && <span className="input--required"></span>}
        </label>
      ) : null}

      {searchable && (
        <div className="bg-white relative " ref={dropdownContainerRef}>
          <div
            id={name}
            ref={searchRef}
            className="select-input"
            onClick={handleOpen}
          >
            {options?.find((o) => o.value === value)?.label || placeholder}
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.ul
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.18 }}
                className="dropdown-options-container"
              >
                <input
                  id={name}
                  ref={searchRef}
                  autoComplete="off"
                  autoFocus={isOpen}
                  placeholder="Search"
                  onChange={(e) => setSearchTermDebounced(e.target.value)}
                  className="bg-[#F9F9FC] rounded-t-[4px] outline-none border-b block border-[#E6E8F0] py-2 px-3 w-full text-[#6C798F]"
                />
                {filteredOptions.map((option: any) => (
                  <motion.li
                    animate={{
                      color: value?.toString() === option.value ? "#000" : "",
                    }}
                    key={option.value}
                    onClick={() => {
                      // setSelected(option);
                      handleClose();
                      onChange({
                        target: { name, value: option.value },
                      } as any);
                    }}
                  >
                    {option.label}
                  </motion.li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      )}
      {!searchable && (
        <div
          style={{ borderColor: error ? "#EC5757" : undefined }}
          className="select-menu-container group"
        >
          <select
            onChange={onChange}
            className="select"
            name={name}
            id={name}
            required={required}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options?.map((option) => (
              <option
                value={option.value}
                key={option.value}
                selected={option.value.toString() == value?.toString()}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}

      {error && (
        <motion.span
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: error ? 1 : 0, height: error ? 1 : 0 }}
          className="text-xs text-[#EC5757] capitalize"
        >
          {error ? error ?? " 7s" : "7s"}
        </motion.span>
      )}
      {hint && (
        <span className="text-xs flex items-center gap-x-2 mt-1 text-[#525866]">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 14C4.6862 14 2 11.3138 2 8C2 4.6862 4.6862 2 8 2C11.3138 2 14 4.6862 14 8C14 11.3138 11.3138 14 8 14ZM7.4 9.8V11H8.6V9.8H7.4ZM7.4 5V8.6H8.6V5H7.4Z"
              fill="#868C98"
            />
          </svg>
          {hint}
        </span>
      )}
    </div>
  );
};
