import { Button } from '~/components';

export const Tasks = () => {
  return (
    <div className='  bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center'>
      <div className='max-w-[260px] w-full flex flex-col items-center gap-y-5 '>
        <img src='/empty.svg' className='w-[100px]' />
        <div className='flex flex-col items-center gap-y-2'>
          <span className=' text-sm text-[#0A0D14]'>No tasks to show</span>
          <span className=' text-sm text-[#525866]'></span>
        </div>
        <Button
          mono
          label='Create Task'
          icon={
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z'
                fill='black'
              />
            </svg>
          }
          leftIcon
        />
      </div>
    </div>
  );
};
