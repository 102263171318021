/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-refresh/only-export-components */
import { AnimatePresence } from 'framer-motion';
import { createContext, ReactNode, useContext, useReducer } from 'react';
import { v4 } from 'uuid';
import { SideModal } from '../../components';

const SideModalContext = createContext<any>({});

interface ModalProvider {
  children: ReactNode;
}

interface ModalProps {
  component?: ReactNode;
}

interface ModalActions {
  type: 'ADD_MODAL' | 'REMOVE_MODAL' | 'CLOSE_MODAL';
  payload?: any;
  id: any;
}

const SideModalProvider = ({ children }: ModalProvider) => {
  const [state, dispatch] = useReducer((state: any, action: ModalActions) => {
    switch (action.type) {
      case 'ADD_MODAL':
        return [...state, { ...action.payload }];
      case 'CLOSE_MODAL': {
        return state[0].open == false;
      }
      case 'REMOVE_MODAL':
        return state.filter((el: any) => el.id !== action.id);

      default:
        return state;
    }
  }, []);

  return (
    <SideModalContext.Provider value={dispatch}>
      <>
        <AnimatePresence>
          {state.map((note: any) => {
            return (
              <SideModal
                dispatch={dispatch}
                key={note.id}
                id={note.id}
                {...note}
              />
            );
          })}
        </AnimatePresence>
      </>
      {children}
    </SideModalContext.Provider>
  );
};

export const openSideModal = () => {
  const dispatch: ({}) => void = useContext(SideModalContext);
  return (props: ModalProps) => {
    dispatch({
      type: 'ADD_MODAL',
      payload: {
        id: v4(),
        ...props,
      },
    });
  };
};

export const closeSideModal = () => {
  const dispatch = useContext(SideModalContext);
  return (id: string) => {
    dispatch({
      type: 'REMOVE_MODAL',
      id,
    });
  };
};

export const removeSideModal = () => {
  const dispatch = useContext(SideModalContext);
  return (id: string) => {
    dispatch({
      type: 'REMOVE_MODAL',
      id,
    });
  };
};

export default SideModalProvider;
