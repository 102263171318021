/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, TextInput } from '~/components';
import Loader from '~/components/UI/Loader';
import { useForm } from '~/hooks';
import { activate2fa, generate2fa } from '~/mutations/profileMutations';

export const Enable2Fa = ({ close }: { close?: () => void }) => {
  const [step, setStep] = useState(0);
  const [authData, setAuthData] = useState<any>(null);
  const { formData, update } = useForm({
    initial: { code: '' },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => generate2fa(),
    ...{
      onSuccess(data) {
        setAuthData(data);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const { mutate: enable, isPending: enabling } = useMutation({
    mutationFn: () => activate2fa({ ...formData }),
    ...{
      onSuccess(data) {
        setAuthData((prev: any) => ({ ...prev, codes: data }));
        setStep(2);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const handleClose = () => {
    close?.();
  };
  const downloadFile = (texts: string[]) => {
    // Join the array into a single string with newline characters
    const fileContent = texts.join('\n');

    // Create a blob from the string
    const blob = new Blob([fileContent], { type: 'text/plain' });

    // Create a link element
    const link = document.createElement('a');

    // Create a URL for the blob and set it as the href attribute of the link
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with a filename
    link.download = '2FA_Codes.txt';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const handleNext = () => {
    if (step == 0) {
      setStep(1);
      mutate();
    } else if (step == 1) {
      enable();
    } else if (step == 2) {
      handleClose();
    }
  };
  const handlePrev = () => {
    if (step == 0) {
      handleClose();
    } else if (step == 1) {
      setStep(0);
    } else if (step == 2) {
      downloadFile(authData.codes);
    }
  };
  return (
    <div className='w-full flex flex-col'>
      <header className='flex items-start w-full justify-between border-b py-4 px-5'>
        <div className='flex flex-col gap-y-1  max-w-[440px]'>
          {step == 0 ? (
            <>
              <h4 className='text-[#0A0D14] font-medium'>
                Install an authentication app
              </h4>
              <span className='text-sm text-[#525866]'>
                To set up Two-Factor Authentication, you'll need to download and
                install an authentication application on your phone.
              </span>
            </>
          ) : step == 1 ? (
            <>
              <h4 className='text-[#0A0D14] font-medium'>Scan the QR code</h4>
              <span className='text-sm text-[#525866]'>
                Scan the QR Code below with your authenticator app.
              </span>
            </>
          ) : (
            step == 2 && (
              <>
                <h4 className='text-[#0A0D14] font-medium'>
                  Save your backup codes
                </h4>
              </>
            )
          )}
        </div>
        <button onClick={close}>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z'
              fill='#525866'
            />
          </svg>
        </button>
      </header>
      {isPending ? (
        <div className='py-20'>
          <Loader big />
        </div>
      ) : (
        <>
          {step == 0 ? (
            <section className='p-5 flex flex-col w-full gap-y-5 max-w-[440px]'>
              <p className='text-sm text-[#525866]'>
                We suggest choosing one of the following recommended apps.
              </p>
              <div className='flex flex-col gap-y-4'>
                <div className='w-full flex items-center gap-x-3'>
                  <div>
                    <img src='/settings/google.svg' alt='google authenticato' />
                  </div>
                  <span className='text-sm text-[#0E121B]'>
                    Google Authenticator
                  </span>
                </div>
                <div className='w-full flex items-center gap-x-3'>
                  <div>
                    <img
                      src='/settings/microsoft.svg'
                      alt='google authenticato'
                    />
                  </div>
                  <span className='text-sm text-[#0E121B]'>
                    Microsoft Authenticator
                  </span>
                </div>
                <div className='w-full flex items-center gap-x-3'>
                  <div>
                    <img src='/settings/authy.svg' alt='google authenticato' />
                  </div>
                  <span className='text-sm text-[#0E121B]'>Authy</span>
                </div>
              </div>
              <p className='text-sm text-[#525866] w-fit'>
                If you have downloaded one, you can proceed with the next step
                to scan and set up your authentication app.
              </p>
            </section>
          ) : step == 1 ? (
            <section className='p-5 flex flex-col w-full gap-y-5'>
              <div className='mx-auto'>
                <img src={authData?.qrCodeImage} />
              </div>
              <TextInput
                label='Verification Code'
                placeholder='Enter your verification code'
                name='code'
                value={formData.code}
                handleInputChange={update}
              />
              <span className='text-sm text-[#525866]'>
                If you're having trouble scanning, you can use the QR Code
                <br />
                Key:{' '}
                <span className='text-[#1F87AD]'>
                  {authData?.otpAuthURL.split('=')[1]}
                </span>
              </span>
            </section>
          ) : (
            step == 2 && (
              <section className='p-5 flex flex-col w-full gap-y-5 max-w-[440px]'>
                <p className='text-sm text-[#525866]'>
                  If you lose your authentication device you can use any of
                  these codes to securely login to your account. <br />
                  <br />
                  You can only see this once, so be sure to download or copy
                  them somewhere safe them to avoid getting locked out of your
                  account.
                </p>

                <div className='text-sm text-[#000000] bg-[#F6F8FA] border border-[#F1F2F4] rounded-[6px] w-full items-center py-5 px-8 grid grid-cols-3 gap-5 '>
                  {authData.codes?.map((code: string) => (
                    <span key={code}>{code}</span>
                  ))}
                </div>
              </section>
            )
          )}
        </>
      )}

      <footer className='px-5 py-4 border-t flex justify-end items-center gap-x-3'>
        <Button
          mono
          label={
            step == 0
              ? 'Cancel'
              : step == 1
              ? 'Back'
              : step == 2
              ? 'Download as TXT file'
              : ''
          }
          effect={handlePrev}
        />
        <Button
          label={
            step == 0
              ? 'Next'
              : step == 1
              ? 'Authenticate'
              : step == 2
              ? 'Done'
              : ''
          }
          effect={handleNext}
          loading={enabling}
          disabled={step == 1 && !formData.code}
        />
      </footer>
    </div>
  );
};
