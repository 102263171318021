import { AppContainer } from "~/layouts/container";
import { AuthRoutes } from "./authRoutes";
import { OrganizationRoutes } from "./orgRoutes";
import { ErrorPage } from "../pages/error/index";

export const AppRoutes = [
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <AppContainer />,
    children: [...OrganizationRoutes, ...AuthRoutes],
  },
];
