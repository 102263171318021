/* eslint-disable @typescript-eslint/no-explicit-any */
import "./styles/table.scss";
import "./styles/bubble-menu.scss";

import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Strike from "@tiptap/extension-strike";

import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import Underline from "@tiptap/extension-underline";
import OrderedList from "@tiptap/extension-ordered-list";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Gapcursor from "@tiptap/extension-gapcursor";
import ListItem from "@tiptap/extension-list-item";

import { ColorInput, Group, TextInput } from "~/components";
import { useCallback, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { accordionVariants } from "~/constants/animations";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: "2px solid #ced4da",
        parseHTML: (element) =>
          element.style.borderStyle || "2px solid #ced4da",
        renderHTML: (attributes) => {
          return {
            style: `border: ${attributes.borderStyle}`,
          };
        },
      },
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
      style: {
        default: "height: 28px; padding: 3px 5px",
      },
    };
  },
});

const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: "2px solid #ced4da",
        parseHTML: (element) =>
          element.style.borderStyle || "2px solid #ced4da",
        renderHTML: (attributes) => {
          return {
            style: `border: ${attributes.borderStyle}`,
          };
        },
      },
      backgroundColor: {
        default: "#f1f3f5",
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
      style: {
        default: "height: 28px; padding: 3px 5px",
      },
    };
  },
});

export const ContentEditor = ({
  section,
  updateSection,
  removeSection,
  index,
}: {
  section: any;
  updateSection: any;
  removeSection: any;
  index: any;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  // @ts-ignore
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Bold,
        Strike,
        TextStyle,
        Color,
        Underline,
        Italic,
        Link.configure({
          openOnClick: false,
          autolink: true,
        }),
        BulletList,
        OrderedList,
        Table.configure({
          resizable: true,
          allowTableNodeSelection: true,
          HTMLAttributes: {
            style: "width: 100%; border-collapse: collapse",
          },
        }),
        CustomTableCell,
        CustomTableHeader,
        TableRow,
        Gapcursor,
        ListItem,
      ],
      editorProps: {
        attributes: {
          class: "prose max-w-full mx-auto p-4 focus:outline-none text-sm",
        },
      },
      autofocus: true,
      content: section.content,
      onUpdate: ({ editor }) => {
        updateSection(index, { ...section, content: editor.getHTML() });
      },
    },
    [index]
  );

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }, [editor]);

  if (!editor) {
    return null;
  }
  if (!editor) {
    return null;
  }
  return (
    <div className="shadow-sm border rounded-xl border-[#E2E4E9] overflow-clip ">
      <Group key="header">
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className="flex items-center py-4 px-[14px] justify-between cursor-pointer"
        >
          <h3 className="flex items-center gap-x-3">{section.title}</h3>
          <div className="flex items-center gap-x-3">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 9H20V11H4V9ZM20 15H4V13H20V15Z"
                fill="#595959"
              />
            </svg>

            <button onClick={() => removeSection(index)}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6.6H21.5V8.4H19.7V20.1C19.7 20.3387 19.6052 20.5676 19.4364 20.7364C19.2676 20.9052 19.0387 21 18.8 21H6.2C5.96131 21 5.73239 20.9052 5.5636 20.7364C5.39482 20.5676 5.3 20.3387 5.3 20.1V8.4H3.5V6.6H8V3.9C8 3.66131 8.09482 3.43239 8.2636 3.2636C8.43239 3.09482 8.6613 3 8.9 3H16.1C16.3387 3 16.5676 3.09482 16.7364 3.2636C16.9052 3.43239 17 3.66131 17 3.9V6.6ZM17.9 8.4H7.1V19.2H17.9V8.4ZM9.8 11.1H11.6V16.5H9.8V11.1ZM13.4 11.1H15.2V16.5H13.4V11.1ZM9.8 4.8V6.6H15.2V4.8H9.8Z"
                  fill="#DF1C41"
                />
              </svg>
            </button>
          </div>
        </div>
      </Group>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={accordionVariants}
            className="overflow-scroll h-full"
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <Group key="body">
              <div className="flex flex-col p-[14px] gap-y-5  border-t border-[#E2E4E9] overflow-scroll">
                <TextInput
                  label="Title"
                  placeholder="Enter section title"
                  name="title"
                  value={section.title}
                  handleInputChange={(e) =>
                    updateSection(index, { ...section, title: e.target.value })
                  }
                />
                <div className="grid grid-cols-2 gap-4">
                  {" "}
                  <ColorInput
                    label="Title Background Color"
                    name="titleBackgroundColor"
                    value={section.titleBackgroundColor}
                    handleInputChange={(e) =>
                      updateSection(index, {
                        ...section,
                        titleBackgroundColor: e.target.value,
                      })
                    }
                  />
                  <ColorInput
                    label="Title Text Color"
                    name="titleTextColor"
                    value={section.titleTextColor}
                    handleInputChange={(e) =>
                      updateSection(index, {
                        ...section,
                        titleTextColor: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="grid grid-cols-2 gap-5">
                  {" "}
                  <ColorInput
                    label="Body Background Color"
                    name="bodyBackgroundColor"
                    value={section.bodyBackgroundColor}
                    handleInputChange={(e) =>
                      updateSection(index, {
                        ...section,
                        bodyBackgroundColor: e.target.value,
                      })
                    }
                  />
                  <ColorInput
                    label="Body Text Color"
                    name="bodyTextColor"
                    value={section.bodyTextColor}
                    handleInputChange={(e) =>
                      updateSection(index, {
                        ...section,
                        bodyTextColor: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <span className="text-sm font-medium text-[#0A0D14]">
                    Content
                  </span>
                  <div className="bg-white w-full rounded-xl border overflow-scroll ">
                    <Group key="menu">
                      <div className="flex items-center gap-x-1 border-b p-2">
                        <button
                          className={
                            editor.isActive("bold")
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor?.chain().focus().toggleBold().run()
                          }
                          disabled={
                            !editor?.can().chain().focus().toggleBold().run()
                          }
                        >
                          <img src="/editor/chat/bold.svg" />
                        </button>
                        <button
                          className={
                            editor.isActive("italic")
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor?.chain().focus().toggleItalic().run()
                          }
                          disabled={
                            !editor?.can().chain().focus().toggleItalic().run()
                          }
                        >
                          <img src="/editor/chat/italic.svg" />
                        </button>
                        <button
                          className={
                            editor.isActive("underline")
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor?.chain().focus().toggleUnderline().run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              .chain()
                              .focus()
                              .toggleUnderline()
                              .run()
                          }
                        >
                          <img src="/editor/chat/underline.svg" />
                        </button>
                        <button
                          className={
                            editor.isActive("strike")
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor?.chain().focus().toggleStrike().run()
                          }
                          disabled={
                            !editor?.can().chain().focus().toggleStrike().run()
                          }
                        >
                          <img src="/editor/chat/strike.svg" />
                        </button>
                        <input
                          type="color"
                          onInput={(event: any) =>
                            editor
                              .chain()
                              .focus()
                              .setColor(event.target.value)
                              .run()
                          }
                          value={editor.getAttributes("textStyle").color}
                          data-testid="setColor"
                        />
                        <svg
                          width="1"
                          height="20"
                          viewBox="0 0 1 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="1"
                            height="20"
                            fill="#1D1C1D"
                            fill-opacity="0.13"
                          />
                        </svg>

                        <button
                          onClick={() =>
                            editor.isActive("link")
                              ? editor.chain().focus().unsetLink().run()
                              : setLink()
                          }
                          className={
                            editor.isActive("link")
                              ? "button-active"
                              : "button-inactive"
                          }
                        >
                          <img src="/editor/chat/link.svg" />
                        </button>
                        <svg
                          width="1"
                          height="20"
                          viewBox="0 0 1 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="1"
                            height="20"
                            fill="#1D1C1D"
                            fill-opacity="0.13"
                          />
                        </svg>
                        <button
                          className={
                            editor.isActive("orderedList")
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor?.chain().focus().toggleOrderedList().run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              .chain()
                              .focus()
                              .toggleOrderedList()
                              .run()
                          }
                        >
                          <img src="/editor/chat/order.svg" />
                        </button>
                        <button
                          className={
                            editor.isActive("bulletList")
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor
                              ?.chain()
                              .focus()
                              .toggleList("bulletList", "listItem")
                              .run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              .chain()
                              .focus()
                              .toggleList("bulletList", "listItem")
                              .run()
                          }
                        >
                          <img src="/editor/chat/bullet.svg" />
                        </button>
                        <svg
                          width="1"
                          height="20"
                          viewBox="0 0 1 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="1"
                            height="20"
                            fill="#1D1C1D"
                            fill-opacity="0.13"
                          />
                        </svg>
                        <button
                          className={
                            editor.isActive(Table.name)
                              ? "button-active"
                              : "button-inactive"
                          }
                          onClick={() =>
                            editor.isActive(Table.name)
                              ? editor.chain().focus().deleteTable().run()
                              : editor
                                  .chain()
                                  .focus()
                                  .insertTable({
                                    rows: 3,
                                    cols: 3,
                                    withHeaderRow: true,
                                  })
                                  .run()
                          }
                        >
                          <img src="/editor/chat/table.svg" />
                        </button>
                      </div>
                    </Group>
                    <Group key="editor">
                      <div className="w-full h-full py-2 overflow-scroll remove-scroll max-h-[250px] report-content">
                        {editor && (
                          <BubbleMenu
                            editor={editor}
                            tippyOptions={{
                              duration: 100,
                              placement: "bottom",
                            }}
                            className="bubble-menu"
                            shouldShow={({ editor }) => {
                              return (
                                editor.isActive(Table.name) && editor.isFocused
                              );
                            }}
                          >
                            <button
                              className={
                                editor.isActive("bold") ? "is-active" : ""
                              }
                              onClick={() =>
                                editor.chain().focus().addColumnBefore().run()
                              }
                            >
                              Add column before
                            </button>
                            <button
                              onClick={() =>
                                editor.chain().focus().addColumnAfter().run()
                              }
                            >
                              Add column after
                            </button>
                            <button
                              onClick={() =>
                                editor.chain().focus().addRowBefore().run()
                              }
                            >
                              Add row before
                            </button>
                            <button
                              onClick={() =>
                                editor.chain().focus().addRowAfter().run()
                              }
                            >
                              Add row after
                            </button>
                            <button
                              onClick={() =>
                                editor.chain().focus().deleteColumn().run()
                              }
                            >
                              Delete column
                            </button>

                            <button
                              onClick={() =>
                                editor.chain().focus().deleteRow().run()
                              }
                            >
                              Delete row
                            </button>
                            <button
                              onClick={() =>
                                editor
                                  .chain()
                                  .focus()
                                  .toggleHeaderColumn()
                                  .run()
                              }
                            >
                              Toggle header column
                            </button>
                            <button
                              onClick={() =>
                                editor.chain().focus().toggleHeaderRow().run()
                              }
                            >
                              Toggle header row
                            </button>
                            <button
                              onClick={() =>
                                editor.chain().focus().toggleHeaderCell().run()
                              }
                            >
                              Toggle header cell
                            </button>
                          </BubbleMenu>
                        )}
                        <EditorContent
                          // className='outline-none mt-5 text-sm text-[#0A0D14] active:outline-none border-none px-3'
                          editor={editor}
                        />
                      </div>
                    </Group>
                  </div>
                </div>
              </div>
            </Group>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};
