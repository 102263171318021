export const StrengthCheck = ({
  pass,
  strength,
}: {
  pass: boolean;
  strength: number;
}) => {
  return (
    <div
      style={{
        backgroundColor: !pass
          ? '#D1D3D4'
          : strength >= 4
          ? '#38C793'
          : strength >= 3
          ? '#F17B2C'
          : '#DF1C41',
      }}
      className='w-full transition-colors duration-200 ease-in-out h-1 rounded-[1.2px]'
    >
      {' '}
    </div>
  );
};
