/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { dropdown } from '~/constants/animations';
import { useOutsideClick } from '~/hooks';

export const Kebab = ({
  children,
}: {
  children: React.ReactNode;
  rightAlign?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  function handleClickOutside(e: any) {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }
  return (
    <div className='relative ' onClick={(e) => e.stopPropagation()}>
      <button
        className='peer shrink-0 grid place-items-center w-full'
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10 3.25C9.38125 3.25 8.875 3.75625 8.875 4.375C8.875 4.99375 9.38125 5.5 10 5.5C10.6187 5.5 11.125 4.99375 11.125 4.375C11.125 3.75625 10.6187 3.25 10 3.25ZM10 14.5C9.38125 14.5 8.875 15.0063 8.875 15.625C8.875 16.2437 9.38125 16.75 10 16.75C10.6187 16.75 11.125 16.2437 11.125 15.625C11.125 15.0063 10.6187 14.5 10 14.5ZM10 8.875C9.38125 8.875 8.875 9.38125 8.875 10C8.875 10.6188 9.38125 11.125 10 11.125C10.6187 11.125 11.125 10.6188 11.125 10C11.125 9.38125 10.6187 8.875 10 8.875Z'
            fill='#525866'
          />
        </svg>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            onClick={(e) => e.stopPropagation()}
            variants={dropdown}
            initial='initial'
            animate='animate'
            exit='exit'
            className='absolute bg-white shadow-md mt-[6px] px-3 py-2 w-fit rounded-xl right-0 z-10 border border-[#E2E4E9]'
            ref={containerRef}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
