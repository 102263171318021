import React, { FormEvent, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { Button, CheckboxElement, Table, TextInput } from "~/components";
import { useForm } from "~/hooks";
import { hasEmptyValues } from "~/utils/helpers";
import { getFeatures } from "~/queries/billingsQueries";
import { createSubscriptionPlan } from "~/mutations/billingsMutations";

interface Feature {
  id: string;
  name: string;
}

export const CreateSubscriptionPage: React.FC = () => {
  const navigate = useNavigate();
  const qc = useQueryClient();
  const [searchParams] = useSearchParams();
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);

  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "100";

  const {
    data: featuresData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["features", { page, limit }],
    queryFn: async () => {
      try {
        const data = await getFeatures();
        //   {
        //   page: Number(page),
        //   limit: Number(limit),
        // }
        return data;
      } catch (err: any) {
        toast.error(err.message);
        throw err;
      }
    },
  });

  const {
    formData: primary,
    update,
    errors,
  } = useForm({
    initial: {
      name: "",
      status: "active", // active | inactive
      description: "",
      interval: "", // monthly | yearly
      features: [],
      processors: [
        {
          processor: "paystack",
          amount: 0,
          currency: "NGN",
        },
        {
          processor: "stripe",
          amount: 0,
          currency: "USD",
        },
      ],
    },
  });

  const { mutate } = useMutation({
    mutationFn: () => createSubscriptionPlan({ ...primary, ...error }),
    ...{
      onSuccess() {
        toast.success(`${primary.name} created succesfully`);
        qc.invalidateQueries({ queryKey: ["subscriptions"] });
        navigate("/app/subscriptions");
      },
      onError: (error: any) => {
        console.error(error);
        toast.error(
          `Unable to create ${primary.name} plan, ${error?.data?.message}`
        );
      },
    },
  });

  useEffect(() => {
    update({
      target: {
        name: "interval",
        value: selected[0] || "",
      },
    });
  }, [selected]);

  useEffect(() => {
    update({
      target: {
        name: "features",
        value: selectedFeatures,
      },
    });
  }, [selectedFeatures]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // Add your form submission logic here
  };

  const handleCheck = (obj: { id: string }) => {
    setSelected([obj.id]);
  };

  const handleFeature = (obj: { id: string }) => {
    setSelectedFeatures((prev) => {
      if (prev.includes(obj.id)) {
        return prev.filter((id) => id !== obj.id);
      } else {
        return [...prev, obj.id];
      }
    });
  };

  const isFeaturesValid = selectedFeatures.length > 0;
  const isFormValid = hasEmptyValues(primary) === false && isFeaturesValid;

  const columns: ColumnDef<Feature>[] = [
    {
      id: "plan",
      cell: ({ row }) => row.original.name,
      header: () => <span>Access</span>,
      size: 80,
    },
    {
      id: "feature",
      cell: ({ row }) => (
        <CheckboxElement
          checked={selectedFeatures.includes(row.original.id)}
          name={row.original.id}
          changeFunction={() => handleFeature({ id: row.original.id })}
        />
      ),
      header: () => <span>Feature</span>,
      size: 20,
    },
  ];

  if (isLoading) return <div>Loading features...</div>;
  if (error)
    return <div>Error loading features: {(error as Error).message}</div>;

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full text-[#0A0D14] overflow-clip px-8 pt-10 pb-11 h-full"
    >
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#0A0D14] font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>New subscription plan</span>
        </button>
        <div className="flex items-center gap-x-2">
          <Button mono label="Cancel" effect={() => navigate(-1)} />
          <Button
            label="Create plan"
            disabled={!isFormValid}
            onClick={mutate}
            type="submit"
          />
        </div>
      </div>
      <div className="flex flex-col items-center w-full overflow-scroll">
        <div className="flex flex-col gap-y-8 w-full items-center mt-6 max-w-[768px] mx-auto">
          <div className="flex flex-col gap-y-5 w-full">
            <h3 className="font-medium">Plan details</h3>
            <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
              <div className="grid grid-cols-2 gap-x-5">
                <TextInput
                  name="name"
                  label="Plan Name"
                  required
                  value={primary.name}
                  placeholder="Acme Inc"
                  autoFocus
                  error={errors.name}
                  handleInputChange={update}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-5">
                <TextInput
                  name="description"
                  label="Description"
                  required
                  value={primary.description}
                  placeholder="Enter plan description"
                  autoFocus
                  error={errors.description}
                  handleInputChange={update}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <label className="text-sm font-medium text-[#0A0D14]">
                  Billing Interval
                </label>
                <div className="flex items-center gap-x-2">
                  <CheckboxElement
                    label="Monthly"
                    checked={selected.includes("monthly")}
                    name="monthly"
                    changeFunction={() => handleCheck({ id: "monthly" })}
                  />
                  <CheckboxElement
                    label="Yearly"
                    checked={selected.includes("yearly")}
                    name="yearly"
                    changeFunction={() => handleCheck({ id: "yearly" })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-5 w-full">
            <h3 className="font-medium">Payment Processors</h3>
            <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
              <label className="text-sm font-medium text-[#0A0D14]">
                Paystack Payment Gateway
              </label>
              <div className="bg-transparent border p-2 rounded-xl flex items-center justify-between w-1/2">
                <input
                  className="text-sm outline-none w-full"
                  value={primary.processors?.[0]?.amount} // Use optional chaining
                  type="number"
                  name="paystackAmount"
                  required
                  onChange={(e) =>
                    update({
                      target: {
                        name: "processors",
                        value: [
                          {
                            ...primary.processors?.[0],
                            amount: Number(e.target.value), // Convert to number
                          },
                          ...(primary.processors?.slice(1) || []),
                        ],
                      },
                    })
                  }
                />
                <select
                  className="outline-none text-sm shrink-0 border-l border-[#E2E4E9]"
                  name="paystackCurrency"
                  required
                  onChange={update}
                  value={primary.processors?.[0]?.currency} // Use optional chaining
                >
                  <option value="NGN">NGN</option>
                </select>
              </div>

              <label className="text-sm font-medium text-[#0A0D14]">
                Stripe Payment Gateway
              </label>
              <div className="bg-transparent border p-2 rounded-xl flex items-center justify-between w-1/2">
                <input
                  className="text-sm outline-none w-full"
                  value={primary.processors?.[1]?.amount} // Use optional chaining
                  type="number"
                  name="stripeAmount"
                  required
                  onChange={(e) =>
                    update({
                      target: {
                        name: "processors",
                        value: [
                          ...(primary.processors?.slice(0, 1) || []),
                          {
                            ...primary.processors?.[1],
                            amount: Number(e.target.value),
                          },
                        ],
                      },
                    })
                  }
                />
                <select
                  className="outline-none text-sm shrink-0 border-l border-[#E2E4E9]"
                  name="stripeCurrency"
                  required
                  onChange={update}
                  value={primary.processors?.[1]?.currency} // Use optional chaining
                >
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-5 w-full">
            <h3 className="font-medium">Features</h3>
            <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
              {featuresData && featuresData.data ? (
                <Table
                  key="features"
                  cols={columns}
                  rawData={featuresData.data}
                />
              ) : (
                <div>No features available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
