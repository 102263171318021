/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  addProjectComments,
  deleteProjectComment,
  getProjectComments,
} from "~/api";
import Loader from "~/components/UI/Loader";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Strike from "@tiptap/extension-strike";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";

import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";

import "./style.css";
import { Button, Group, Kebab } from "~/components";
import { getCommentTime } from "~/utils/dateUtils";
import { useAuth } from "~/providers";
import ListItem from "@tiptap/extension-list-item";
import { useCallback } from "react";
export const ProjectChat = ({
  close,
  id,
}: {
  close?: () => void;
  id: string;
}) => {
  const { user } = useAuth();
  const {
    data: comments,
    isLoading,
    refetch,
  } = useQuery<ProjectComments[]>({
    queryKey: ["project", id, "comments"],
    queryFn: async () => {
      try {
        const data = await getProjectComments(id);

        return data.data.reverse();
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Strike,
      Underline,
      Italic,
      Link,
      BulletList,
      OrderedList,
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: "Write something …",
      }),
      ListItem,
    ],
    editorProps: {
      attributes: {
        class: "editor-content",
      },
    },
    autofocus: true,
    content: "",
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      addProjectComments(id, {
        comment: editor?.getHTML().trim(),
        replyId: null,
      }),
    ...{
      onSuccess() {
        editor?.commands.setContent("");
        refetch();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const { mutate: deleteComment } = useMutation({
    mutationFn: (commentId: string) => deleteProjectComment(id, commentId),
    ...{
      onSuccess() {
        editor?.commands.setContent("");
        refetch();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full flex flex-col h-full relative">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Comment</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5 h-full overflow-scroll remove-scroll">
        {comments && comments.length > 0 ? (
          <div className="flex flex-col">
            {comments.map((c) => (
              <div
                key={c.id}
                className="border-b py-4 flex items-start justify-between w-full gap-x-3"
              >
                <Group key="comment">
                  <div className="flex items-start gap-x-3">
                    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-[#E7E4F9] shrink-0">
                      <span className="text-[#524988] text-sm">
                        {c?.user?.profile?.firstName[0]}
                        {c?.user?.profile?.lastName?.[0]}
                      </span>
                    </div>
                    <div className="flex flex-col report-content">
                      <h4 className="text-sm text-[#0A0D14] font-semibold">
                        {c?.user?.profile?.firstName}{" "}
                        {c?.user?.profile?.lastName}
                      </h4>
                      <span
                        className="text-[13px]"
                        dangerouslySetInnerHTML={{
                          __html: c?.comment,
                        }}
                      />
                    </div>
                  </div>
                </Group>
                <Group key="time">
                  <div className="flex items-center gap-x-2 shrink-0">
                    <span className="text-xs text-[#868C98] shrink-0">
                      {getCommentTime(c.createdAt)}
                    </span>
                    {user.id == c.user.profile.id && (
                      <Kebab>
                        <div className="flex flex-col w-[120px]">
                          <button
                            onClick={() => deleteComment(c.id)}
                            style={{ fontSize: 12, color: "#DF1C41" }}
                            className="kebab-button"
                          >
                            Delete Comment
                          </button>
                        </div>
                      </Kebab>
                    )}
                  </div>
                </Group>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center gap-y-5">
            <img src="/emptyintegrations.svg" />
            <p className="text-center text-sm text-[#525866]"></p>
          </div>
        )}
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3 mt-auto">
        <div className="border rounded-lg h-[124px] w-full flex flex-col p-3">
          <Group key="menu">
            <div className="flex items-center gap-x-1">
              <button
                className={
                  editor.isActive("bold") ? "button-active" : "button-inactive"
                }
                onClick={() => editor?.chain().focus().toggleBold().run()}
                disabled={!editor?.can().chain().focus().toggleBold().run()}
              >
                <img src="/editor/chat/bold.svg" />
              </button>
              <button
                className={
                  editor.isActive("italic")
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                disabled={!editor?.can().chain().focus().toggleItalic().run()}
              >
                <img src="/editor/chat/italic.svg" />
              </button>
              <button
                className={
                  editor.isActive("underline")
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                disabled={
                  !editor?.can().chain().focus().toggleUnderline().run()
                }
              >
                <img src="/editor/chat/underline.svg" />
              </button>
              <button
                className={
                  editor.isActive("strike")
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={() => editor?.chain().focus().toggleStrike().run()}
                disabled={!editor?.can().chain().focus().toggleStrike().run()}
              >
                <img src="/editor/chat/strike.svg" />
              </button>
              <svg
                width="1"
                height="20"
                viewBox="0 0 1 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="1"
                  height="20"
                  fill="#1D1C1D"
                  fill-opacity="0.13"
                />
              </svg>

              <button
                className={
                  editor.isActive("link") ? "button-active" : "button-inactive"
                }
                onClick={() =>
                  editor.isActive("link")
                    ? editor.chain().focus().unsetLink().run()
                    : setLink()
                }
              >
                <img src="/editor/chat/link.svg" />
              </button>
              <svg
                width="1"
                height="20"
                viewBox="0 0 1 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="1"
                  height="20"
                  fill="#1D1C1D"
                  fill-opacity="0.13"
                />
              </svg>
              <button
                className={
                  editor.isActive("orderedList")
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={() =>
                  editor?.chain().focus().toggleOrderedList().run()
                }
                disabled={
                  !editor?.can().chain().focus().toggleOrderedList().run()
                }
              >
                <img src="/editor/chat/order.svg" />
              </button>
              <button
                className={
                  editor.isActive("bulletList")
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={() =>
                  editor
                    ?.chain()
                    .focus()
                    .toggleList("bulletList", "listItem")
                    .run()
                }
                disabled={
                  !editor
                    ?.can()
                    .chain()
                    .focus()
                    .toggleList("bulletList", "listItem")
                    .run()
                }
              >
                <img src="/editor/chat/bullet.svg" />
              </button>
            </div>
          </Group>
          <div className="flex items-center gap-x-3 w-full justify-between remove scroll py-2">
            <div className="w-full h-[62px] overflow-y-auto">
              <EditorContent editor={editor} />
            </div>
            <Button
              label="Send"
              effect={mutate}
              loading={isPending}
              disabled={!editor.getText().trim()}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};
