/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '../config/axios';

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

const baseUrl =
  import.meta.env.VITE_BASE_URL;

export const getEmailSettings = async () => {
  const config = {
    method: 'GET',
    url: `${baseUrl}/profile/email-settings`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const setEmailSettings = async (data: any) => {
  const config = {
    method: 'PUT',
    url: `${baseUrl}/profile/notification-settings`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getNotificationSettings = async () => {
  const config = {
    method: 'GET',
    url: `${baseUrl}/profile/notification-settings?limit=100`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const setNotificationSettings = async () => {
  const config = {
    method: 'PUT',
    url: `${baseUrl}/profile/notification-settings`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const updateWorkspace = async (id: any, data: any) => {
  const config = {
    method: 'PATCH',
    url: `${baseUrl}/workspaces/${id}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getPublicWorkspace = async () => {
  const config = {
    method: 'GET',
    url: `${baseUrl}/workspaces/public`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
