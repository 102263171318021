/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useSearchParams } from "react-router-dom";

export const usePanes = (defaultShow: string, preserve = false) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const show = searchParams.get("show") || defaultShow;
  const handlePaneSwitch = (pane: string) => {
    const to = preserve
      ? `?${new URLSearchParams([
          ...new Map([...searchParams, ...new URLSearchParams({ show: pane })]),
        ]).toString()}`
      : `?show=${pane}`;
    navigate(to, {
      replace: true,
    });
  };
  return { show, handlePaneSwitch };
};

import { useLayoutEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { getUserLocation } from "~/queries/userQueries";
import toast from "react-hot-toast";
import { v4 } from "uuid";
export const useOutsideClick = <T extends HTMLElement>(
  handler: (event: any) => void
) => {
  const ref = useRef<T>(null);
  useLayoutEffect(() => {
    const listener = (event: any) => {
      if (!ref?.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
  return ref;
};
export const useUpdateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const update = (e: { target: { value: any; name: string } }) => {
    setSearchParams(() => {
      searchParams.set(e.target.name, e.target.value);
      return searchParams;
    });
  };

  return { update };
};

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const limit: any = searchParams.get("limit") || 10;
  const page: any = searchParams.get("page") || 1;

  const handlePageChange = (e: { selected: number }) => {
    setSearchParams(() => {
      searchParams.set("page", (e.selected + 1).toString());
      return searchParams;
    });
  };
  const handleLimitChange = (limit: any) => {
    setSearchParams({ limit: limit });
  };

  return {
    handlePageChange,
    handleLimitChange,
    limit,
    page,
  };
};

export const useLocation = () => {
  const { data: location, isLoading: finding } = useQuery<UserLocation>({
    queryKey: ["user location"],
    queryFn: async () => {
      try {
        const data = await getUserLocation();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  return { location, finding };
};

export const useDeviceId = () => {
  let id = localStorage.getItem("deviceId");
  if (!id) {
    id = v4();
    localStorage.setItem("deviceId", id);
  }

  return id as string;
};
