/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Pill, ProgressBar, Tracker } from '~/components';
import Loader from '~/components/UI/Loader';
import { getProjectUsers } from '~/queries/projectQueries';

export const ProjectSummaryCard = ({ project }: { project: Project }) => {
  const { isLoading, data: members } = useQuery<User[]>({
    queryKey: ['project', project?.id, 'members'],
    queryFn: async () => {
      try {
        const data = await getProjectUsers(project.id);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  return (
    <div className='bg-white rounded-xl border border-[#E2E4E9] shadow-sm h-full p-4 flex flex-col'>
      <div className='flex justify-between w-full items-start pb-4 border-b '>
        <div className='flex flex-col gap-y-2'>
          <h3 className='font-medium'>{project.name}</h3>
          <div className='flex items-center gap-x-2'>
            <Pill status={project.status} />
            {members && members?.length > 0 && (
              <Tracker status={project?.health ?? 'on-track'} />
            )}
          </div>
        </div>
        <Link to={`/app/projects/${project.id}`}>
          <svg
            width='28'
            height='24'
            viewBox='0 0 28 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.5 5.25V6.75H8.75V17.25H19.25V13.5H20.75V18C20.75 18.1989 20.671 18.3897 20.5303 18.5303C20.3897 18.671 20.1989 18.75 20 18.75H8C7.80109 18.75 7.61032 18.671 7.46967 18.5303C7.32902 18.3897 7.25 18.1989 7.25 18V6C7.25 5.80109 7.32902 5.61032 7.46967 5.46967C7.61032 5.32902 7.80109 5.25 8 5.25H12.5ZM18.2803 8.78025L14 13.0605L12.9395 12L17.2197 7.71975L14.75 5.25H20.75V11.25L18.2803 8.78025Z'
              fill='#525866'
            />
          </svg>
        </Link>
      </div>
      <div className='pt-4 flex flex-col gap-y-3 w-full h-full '>
        <p className='line-clamp-2'>{project?.description}</p>
        <div className='mt-auto'>
          <ProgressBar progress={parseFloat(project.workDone)} />
        </div>
        <div className='flex w-full items-center justify-between mt-auto'>
          <Tracker
            status={project?.health ?? 'on-track'}
            label={`${dayjs(project?.endDate).diff(
              dayjs(),
              'day'
            )} day(s) left`}
          />
          {isLoading ? (
            <Loader />
          ) : members && members.length > 0 ? (
            <div className='flex items-center'>
              {[...members].slice(0, 5).map((member) => (
                <div
                  key={member.id}
                  className='w-9 h-9 rounded-full flex items-center justify-center bg-[#E7E4F9] shrink-0 border-2 border-white -ml-2'
                >
                  <span className='text-[#524988] text-sm'>
                    {member?.profile?.firstName?.[0]}
                    {member?.profile?.lastName?.[0]}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <Tracker status={project?.health ?? 'on-track'} />
          )}
        </div>
      </div>
    </div>
  );
};
