/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;
export const addUser = async (data: { email?: string; roleId?: string }) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/users`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const deleteUser = async (id: any) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/users/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const updateUser = async (
  id: any,
  data: {
    roleId?: string;
    status?: string;
    reason?: string;
  }
) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/users/${id}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const resendInvite = async (id: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/users/${id}/resend`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
