import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { addProjectQualityResource } from "~/api";
import { useForm } from "~/hooks";
import { Button, TextInput } from "~/components";

export const AddQualityResource = ({
  id,
  close,
  data,
  userRole,
}: {
  id: string;
  close?: () => void;
  data: ProjectIntegration;
  userRole: Role;
}) => {
  const qc = useQueryClient();

  const { formData, update } = useForm({
    initial: {
      sheets: "",
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const sheetsArray = formData.sheets
        ?.split(",")
        .map((sheet) => sheet.trim());
      const payload = {
        projectIntegrationId: data.id,
        sheets: sheetsArray,
      };
      return addProjectQualityResource(id, payload);
    },
    onSuccess() {
      toast.success("Quality resource successfully created");
      qc.invalidateQueries({
        queryKey: ["project", id, "integrations", data?.integration.slug],
      });
      qc.invalidateQueries({
        queryKey: ["project", id, "integrations", undefined],
      });
      close?.();
    },
    onError(err) {
      toast.error(err.message);
    },
  });

  return (
    <div className="w-full flex flex-col">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        {userRole?.slug !== "project-team-member" && (
          <h4 className="text-[#0A0D14] font-medium">Add Quality Resource</h4>
        )}
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-2 max-w-[440px]">
        <p className="text-sm text-[#525866]">
          Kindly specify the sheet(s) to be added. Please ensure that each sheet
          contains a <strong>'Test Status' </strong> column with the following
          dropdown values: <strong>Incomplete</strong>, <strong>Pass</strong>,{" "}
          <strong>Fail</strong>, <strong>In QA</strong>,{" "}
          <strong>Blocked</strong>, <strong>Not Tested</strong>,{" "}
          <strong>Rework</strong>.. This is required for processing.
        </p>
        <TextInput
          label="Input Sheet(s)"
          placeholder="Input sheet(s) separated with a comma (,)"
          value={formData.sheets}
          name="sheets"
          handleInputChange={update}
        />
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button label="Add" effect={mutate} loading={isPending} />
      </footer>
    </div>
  );
};
