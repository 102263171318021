import { Button } from '~/components';

export const Email = () => {
  return (
    <div className='  bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center'>
      <div className='max-w-[260px] w-full flex flex-col items-center gap-y-5 '>
        <img src='/empty.svg' className='w-[100px]' />
        <div className='flex flex-col items-center gap-y-2'>
          <span className=' text-sm text-[#0A0D14]'>Nothing to show</span>
          <span className=' text-sm text-[#525866]'>
            You have not connected an app for this{' '}
          </span>
        </div>
        <Button
          mono
          label='Connect app'
          icon={
            <svg
              width='21'
              height='20'
              viewBox='0 0 21 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.296 9.99956L7.5835 6.28706L8.644 5.22656L13.417 9.99956L8.644 14.7726L7.5835 13.7121L11.296 9.99956Z'
                fill='#525866'
              />
            </svg>
          }
          rightIcon
        />
      </div>
    </div>
  );
};
