import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Group, Button, TextInput } from "~/components";
import { HtmlViewer } from "./HtmlViewer";
import { Modal } from "./Modal/Modal";
import { useState, useEffect } from "react";
import MEditor from "@monaco-editor/react"; // Import the Monaco Editor
import toast from "react-hot-toast";
import { createReportTemplate } from "~/mutations/templateMutation";
import {
  getReportTemplates,
  getReportTemplate,
} from "~/queries/templateQueries";
import Loader from "~/components/UI/Loader";

interface ReportTemplates {
  id: string;
  message: string;
  meta: string;
  status: string;
  data: any;
}

interface ReportTemplate {
  id: string;
  title: string;
  slug: string;
  body: string;
  bannerUrl: any;
  createdAt: any;
  data: any;
}

interface NewReportTemplate {
  title: string;
  body: string;
}

export const CreateTemplate = () => {
  const navigate = useNavigate();
  const [updatedHtml, setUpdatedHtml] = useState<string>(""); // State for updated HTML
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false); // State to control the modal
  const [subject, setSubject] = useState<string>(""); // State for subject
  const [templateOptions, setTemplateOptions] = useState<ReportTemplate[]>([]); // State for existing report templates
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(""); // State for selected template ID

  // Fetch the list of report templates
  const { data: templatesData, isLoading: isTemplatesLoading } =
    useQuery<ReportTemplates>({
      queryKey: ["reportTemplates"],
      queryFn: getReportTemplates,
    });

  // Update the template options when the data is fetched
  useEffect(() => {
    if (templatesData?.data && Array.isArray(templatesData.data)) {
      setTemplateOptions(templatesData.data); // Set the fetched templates into state
    } else {
      console.error("Fetched templatesData is not an array", templatesData);
      setTemplateOptions([]); // Reset to empty array if not an array
    }
  }, [templatesData]);

  const { mutate, isPending } = useMutation<void, Error, NewReportTemplate>({
    mutationFn: (formData) => {
      return createReportTemplate(formData); // Pass the form data without the id
    },
    onSuccess() {
      toast.success(`${subject} created successfully`);
      //   qc.invalidateQueries(["reportTemplates"]); // Invalidate specific queries
      navigate(`/app/templates`); // Navigate to the templates list after saving
    },
    onError(error) {
      toast.error(`Error: ${error.message}`);
    },
  });

  const handleSave = () => {
    const formData: NewReportTemplate = {
      title: subject,
      body: updatedHtml,
    };
    mutate(formData); // Pass the form data to the mutate function
  };

  const handlePreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  // Handle selection of a template
  const handleTemplateSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const templateId = event.target.value;
    setSelectedTemplateId(templateId); // Update selected template ID

    if (templateId) {
      try {
        const templateData: ReportTemplate = await getReportTemplate(
          templateId
        ); // Fetch the selected template by ID
        if (templateData) {
          setUpdatedHtml(templateData?.data.body || ""); // Populate the editor with the selected template's HTML
          setSubject(templateData?.data.title || ""); // Populate the subject field
        }
      } catch (error: any) {
        toast.error(`Error fetching template: ${error.message}`);
      }
    } else {
      setUpdatedHtml(""); // Clear the editor if no template is selected
      setSubject(""); // Clear the subject field
    }
  };

  if (isTemplatesLoading) return <Loader big />; // Show loader while templates are loading

  return (
    <div className="w-full h-full flex flex-col overflow-scroll p-8">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Back to Templates</span>
        </button>
      </div>

      <Group key="header">
        <div className="flex items-center w-full justify-between mt-6">
          <h1 className="text-[#0A0D14] font-medium text-lg">
            Create Template
          </h1>
          <div className="flex flex-row gap-x-3">
            <Button mono label="Preview" onClick={handlePreview} />
            <Button
              primary
              label="Save"
              onClick={handleSave}
              disabled={isPending}
            />
          </div>
        </div>
      </Group>
      <div className="flex flex-col mt-6 gap-y-5 w-full h-full">
        <Group key="editor">
          <div className="bg-white border h-full py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm ">
            <div className="flex flex-col gap-x-5 h-full pt-5">
              <div className="flex justify-between ">
                <h1>Subject</h1>
                <div className="flex flex-col w-[60%] gap-y-5">
                  <TextInput
                    name="subject"
                    required
                    value={subject}
                    placeholder="Template Subject"
                    autoFocus
                    error=""
                    handleInputChange={(e) => setSubject(e.target.value)} // Update subject on input change
                  />
                </div>
              </div>

              <hr className="border-t border-gray-300 my-4" />
              <div className="flex justify-between ">
                <label htmlFor="templateSelect" className="font-small">
                  Duplicate Existing Report Template
                </label>

                <div className="flex flex-col w-[60%] gap-y-5">
                  <select
                    id="templateSelect"
                    className="border border-gray-300 rounded p-2 "
                    value={selectedTemplateId}
                    onChange={handleTemplateSelect}
                  >
                    <option value="">-- Select a Template --</option>
                    {templateOptions.map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <hr className="border-t border-gray-300 my-4" />

              <MEditor
                value={updatedHtml} // Set the editor's value here
                onChange={(value) => setUpdatedHtml(value || "")}
                language="html"
                {...monacoEditorProps}
              />
            </div>
          </div>
        </Group>
      </div>

      <Modal isOpen={isPreviewOpen} onClose={handleClosePreview}>
        <HtmlViewer htmlString={updatedHtml} /> {/* Render the preview modal */}
      </Modal>
    </div>
  );
};

const monacoEditorProps = {
  height: "95%",
  width: "100%",
  theme: "vs-dark",
  options: {
    minimap: { enabled: false },
    formatOnPaste: true,
    formatOnType: true,
  },
};
