/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CopyButton } from "~/components";
import { DeleteIntegration } from "../Delete";
import { openModal } from "~/providers";
import { useForm } from "~/hooks";
import { SettingContainer } from "~/actions/Settings/components";
// import { setIntegration } from '~/mutations/integrationMutations';

export const DetailView = ({
  active,
  back,
}: {
  active?: Integration;
  back: () => void;
}) => {
  // const qc = useQueryClient();
  const popup = openModal();
  const { formData, update } = useForm({
    initial: {
      integration: active?.slug,
      clientId: "",
      clientSecret: "",
      redirectUri: `${new URL(window.origin).hostname}/close/${active?.slug}`,
    },
  });

  const handleDisconnect = () => {
    popup({
      component: <DeleteIntegration details={active!} refetch={back} />,
    });
  };
  return (
    <div className="flex flex-col">
      <button onMouseDown={back} className="flex items-center gap-x-1">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.871 9.24952H16V10.7495H6.871L10.894 14.7725L9.8335 15.833L4 9.99952L9.8335 4.16602L10.894 5.22652L6.871 9.24952Z"
            fill="#525866"
          />
        </svg>
        <span className="text-sm text-[#525866] font-medium">
          Back to Integrations
        </span>
      </button>
      <div className="mt-6 flex items-center w-full justify-between">
        <div className="flex items-center gap-x-3">
          <span className="w-12 h-12 rounded-full bg-white border border-[#E1E4EA] flex items-center justify-center">
            <img src={active?.icon} />
          </span>
          <div className="flex flex-col gap-y-1">
            <h2 className="font-medium text-[#0A0D14]">{active?.name}</h2>
            <span className="text-sm text-[#525866]">
              {active?.description}
            </span>
          </div>
        </div>
        <Button danger effect={handleDisconnect} label="Disconnect App" />
      </div>

      <div className="bg-white rounded-b-xl px-5 flex-col mt-10">
        <SettingContainer
          title="Redirect URI"
          subtext="Copy this and paste it into your application's authentication setup"
        >
          <div className="w-[40%]">
            <div className="flex items-center gap-x-3">
              <div className="border border-[#E2E4E9] rounded-xl flex items-center mt-1 relative shrink-0 flex-1">
                <span className="border-r px-3 py-[10px] text-[#868C98] text-sm shrink-0">
                  https://
                </span>
                <input
                  name="redirectUri"
                  placeholder="www.example.com"
                  readOnly
                  value={formData.redirectUri}
                  onChange={update}
                  className="px-3 py-[10px] bg-[#E2E4E9]/70 text-sm outline-none w-full rounded-r-xl text-[#525866]"
                />
              </div>
              <CopyButton value={`https://${formData.redirectUri}`} />
            </div>
          </div>
        </SettingContainer>

        {/* <div className='w-full flex justify-end py-5'>
          <Button
            effect={mutate}
            loading={isPending}
            disabled={!formData.clientId || !formData.clientSecret}
            label='Save changes'
          />
        </div> */}
      </div>
    </div>
  );
};
