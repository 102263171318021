/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ExpandableButton,
  Group,
  MultiSelect,
  SelectElement,
  Table,
  TextArea,
  TextInput,
} from "~/components";
import { TableAvatar } from "~/components/UI/TableAvatar";
import { useForm } from "~/hooks";
import { useClients, useUsers } from "~/hooks/useLists";
import { createProject } from "~/mutations/projectMutations";
import { useAuth } from "~/providers";
import { handleError } from "~/utils/helpers";
import { motion } from "framer-motion";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
export const CreateProjectPage = () => {
  const { workspace } = useAuth();
  const navigate = useNavigate();
  const { list: clients = [] } = useClients();
  const { list: users = [], data = [] } = useUsers();
  const serviceTypes =
    workspace.serviceTypes && workspace.serviceTypes.length > 0
      ? workspace?.serviceTypes?.map((st: string) => ({
          value: st,
          label: st,
        }))
      : [];
  const qc = useQueryClient();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectedUserData, setSelectedUserData] = useState<any>([]);
  const { formData, update, setErrors, errors } = useForm({
    initial: {
      email: "",
      name: "",
      description: "",
      serviceType: "",
      startDate: "",
      endDate: "",
      daysSpent: "",
      workDone: undefined,
      clientId: "",
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (status: string) =>
      createProject({
        ...formData,
        startDate: formData.startDate
          ? dayjs(formData.startDate!).format("YYYY-MM-DD")
          : formData.startDate,
        endDate: formData.endDate
          ? dayjs(formData.endDate).format("YYYY-MM-DD")
          : formData.endDate,
        status,
        workDone: parseFloat(formData?.workDone || "0.0"),
        users: [...selectedUsers],
        email: formData.email || undefined,
      }),
    ...{
      onSuccess() {
        toast.success(`${formData.name} created succesfully`);
        qc.invalidateQueries({ queryKey: ["projects"] });
        navigate("/app/projects");
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === "validation") {
          setErrors(handledError.messages);
        }
      },
    },
  });
  const handleSubmit = (status: string) => {
    if (status == "draft") return mutate(status);
    if (dayjs(formData.startDate).diff(dayjs(), "days") < 1) {
      mutate("ongoing");
    } else {
      mutate("scheduled");
    }
  };

  const removeUser = (v: string) => {
    setSelectedUsers((prev: any) => prev.filter((p: string) => p != v));
    setSelectedUserData((prev: any) => prev.filter((p: any) => p.id != v));
  };

  useEffect(() => {
    setSelectedUserData(() => {
      return selectedUsers
        .map((s: any) => data.find((d: any) => d.id === s))
        .filter(Boolean);
    });
  }, [selectedUsers, data]);

  const colums: ColumnDef<User>[] = [
    {
      id: "name",
      cell: ({ row }) => (
        <TableAvatar
          first_name={row.original.profile.firstName}
          last_name={row.original.profile.lastName}
        />
      ),
      header: () => <span>Member Name</span>,
      size: 44.5,
    },
    {
      id: "email",
      cell: ({ row }) => (
        <span className="text-left ">{row.original.email}</span>
      ),
      header: () => <span>Email Address</span>,
      size: 44.5,
    },

    {
      id: "event",
      cell: ({ row }) => (
        <button
          onClick={() => removeUser(row.original.id)}
          className="kebab-button"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 4V2.5H13.75V4H17.5V5.5H16V16.75C16 16.9489 15.921 17.1397 15.7803 17.2803C15.6397 17.421 15.4489 17.5 15.25 17.5H4.75C4.55109 17.5 4.36032 17.421 4.21967 17.2803C4.07902 17.1397 4 16.9489 4 16.75V5.5H2.5V4H6.25ZM5.5 5.5V16H14.5V5.5H5.5ZM7.75 7.75H9.25V13.75H7.75V7.75ZM10.75 7.75H12.25V13.75H10.75V7.75Z"
              fill="#525866"
            />
          </svg>
        </button>
      ),
      header: () => <span> </span>,
    },
  ];
  return (
    <div className="flex flex-col w-full text-[#0A0D14] oveflow-clip h-full p-8">
      <div className="flex items-center w-full justify-between">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#0A0D14] font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>New project</span>
        </button>
        <div className="flex items-center gap-x-2">
          <Button mono label="Cancel" effect={() => navigate(-1)} />
          <ExpandableButton
            disabled={
              !formData.name ||
              !formData.clientId ||
              !formData.description ||
              !formData.startDate ||
              !formData.endDate
            }
            loading={isPending}
            label="Save"
          >
            <div className="min-w-[168px] w-full flex flex-col items-start">
              <button
                className="kebab-button"
                onClick={() => handleSubmit("publish")}
              >
                Save and publish
              </button>

              <button
                className="kebab-button"
                onClick={() => handleSubmit("draft")}
              >
                Save as draft
              </button>
            </div>
          </ExpandableButton>
        </div>
      </div>
      <div className="flex flex-col items-center w-full overflow-scroll">
        <div className="flex flex-col gap-y-8 w-full items-center mt-6 max-w-[728px] mx-auto">
          <Group key="project details">
            <div className="flex flex-col gap-y-5 w-full">
              <h3 className="font-medium">Project Details</h3>
              <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
                <div className="grid grid-cols-2 gap-x-5">
                  <TextInput
                    label="Project name"
                    value={formData.name}
                    error={errors.name}
                    name="name"
                    placeholder="Enter project name"
                    autoFocus
                    handleInputChange={update}
                  />
                  <SelectElement
                    label="Client name"
                    value={formData.clientId}
                    error={errors.clientId}
                    name="clientId"
                    placeholder="Select client"
                    onChange={update}
                    options={clients}
                    searchable
                  />
                </div>
                <TextArea
                  label="Project description"
                  value={formData.description}
                  error={errors.description}
                  // error={'Hey'}
                  name="description"
                  placeholder="Enter description"
                  handleInputChange={update}
                />

                {isSubscribedFor("workspace") && (
                  <SelectElement
                    label="Service Type"
                    value={formData.serviceType}
                    error={errors.serviceType}
                    name="serviceType"
                    placeholder="Enter Service Type"
                    onChange={update}
                    hint="Please make sure to have filled in service type in your workspace preferences"
                    options={serviceTypes}
                    searchable
                  />
                )}
                <div className="grid grid-cols-2 gap-x-5">
                  <TextInput
                    label="Start Date"
                    value={formData.startDate}
                    error={errors.startDate}
                    name="startDate"
                    type="date"
                    handleInputChange={update}
                  />
                  <TextInput
                    label="End Date"
                    value={formData.endDate}
                    error={errors.endDate}
                    name="endDate"
                    type="date"
                    handleInputChange={update}
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-5">
                  {/* <TextInput
                    label='Days Spent'
                    value={formData.daysSpent}
                    error={errors.daysSpent}
                    name='daysSpent'
                    type='number'
                    placeholder='0'
                    handleInputChange={update}
                  /> */}
                  {dayjs(formData.startDate).diff(dayjs(), "days") < 0 && (
                    <div className="flex flex-col ">
                      <label
                        htmlFor="workDone"
                        className="text-sm relative font-medium text-[#0A0D14]"
                      >
                        Work Done
                      </label>
                      <div className="border border-[#E2E4E9] rounded-xl flex items-center ">
                        <input
                          placeholder="0.00"
                          value={formData.workDone}
                          name="workDone"
                          type="text"
                          onChange={(e) => {
                            update(e);
                          }}
                          className="px-3 py-[10px] bg-white text-sm outline-none w-full rounded-l-xl"
                        />
                        <span className="border-l px-3 py-[10px] text-[#868C98] text-sm shrink-0">
                          %
                        </span>
                      </div>
                      {errors?.workDone && (
                        <motion.span
                          initial={{ opacity: 0, height: 0 }}
                          animate={{
                            opacity: errors?.workDone ? 1 : 0,
                            height: errors?.workDone ? 1 : 0,
                          }}
                          className="text-xs text-[#EC5757] capitalize"
                        >
                          {errors?.workDone ? errors?.workDone ?? " 7s" : "7s"}
                        </motion.span>
                      )}
                    </div>
                  )}
                  <TextInput
                    label="Project email"
                    value={formData.email}
                    error={errors.email}
                    name="email"
                    placeholder="Enter project email"
                    autoFocus
                    handleInputChange={update}
                    required={false}
                    type="email"
                  />
                </div>
              </div>
            </div>
          </Group>
          {isSubscribedFor("teams") && (
            <Group key="team members">
              <div className="flex flex-col gap-y-5 w-full">
                <h3 className="font-medium">Team members</h3>
                <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
                  <div className="flex items-center border-b pb-2 gap-x-3 w-full">
                    <div className="flex-grow">
                      <MultiSelect
                        setSelected={setSelectedUsers}
                        selected={selectedUsers}
                        options={users}
                        placeholder="Select Team Members"
                        name="user"
                        showSelected={false}
                      />
                    </div>
                  </div>
                  {selectedUserData.length > 0 ? (
                    <Table
                      key="users"
                      cols={colums}
                      rawData={selectedUserData}
                    />
                  ) : (
                    <div className="w-full flex flex-col items-center">
                      <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 text-center">
                        <img src="/empty.svg" className="w-[100px]" />
                        <div className="flex flex-col items-center gap-y-2">
                          <span className=" text-sm text-[#0A0D14]">
                            No team members to show
                          </span>
                          {isActionAllowed("add-team-member") && (
                            <span className=" text-sm text-[#525866]">
                              You have not added any team members. Please add a
                              member above
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Group>
          )}
        </div>
      </div>
    </div>
  );
};
