/* eslint-disable @typescript-eslint/no-explicit-any */
import { focusManager, useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Group, TextInput } from '~/components';
import Loader from '~/components/UI/Loader';
import { useForm } from '~/hooks';
import { initEmailSignup } from '~/mutations/authMutations';
import { getAuthProviders } from '~/queries/authQueries';
import { AuthScreen } from '~/screens';
import { SignupWithGoogle } from './components/SignupWithGoogle';
import { SignupWithMicrosoft } from './components/SignupWithMicrosoft';
import { SignupWithLinkedin } from './components/SignupWithLinkedin';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { useEffect, useState } from 'react';
export const SignupPage = () => {
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      if (focusManager.isFocused()) {
        setRefreshReCaptcha(Date.now());
      }
    }, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const siteKey = import.meta.env.VITE_SITE_KEY;
  const { formData, update } = useForm({
    initial: { email: '' },
  });
  const { data: providers, isLoading } = useQuery<
    { provider: string; url: string }[]
  >({
    queryKey: ['providers'],
    queryFn: async () => {
      try {
        const data = await getAuthProviders();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      initEmailSignup({ ...formData, recaptchaResponse: captchaToken! }),
    ...{
      onSuccess() {
        navigate('/signup/verify', { state: { email: formData.email } });
      },
      onError(error) {
        setRefreshReCaptcha(Date.now());
        toast.error(error.message);
      },
    },
  });

  const handleMutate = (e: any) => {
    e.preventDefault();
    mutate();
  };
  const getProviderUrl = (provider: 'microsoft' | 'linkedin' | 'google') => {
    if (!providers || providers.length < 1) return '';
    const [url] = providers!.filter((pr) => pr.provider == provider);
    return url.url;
  };

  return (
    <AuthScreen icon='/auth/rocket.svg'>
      <div className='flex flex-col w-full'>
        <section className='flex flex-col'>
          <h1 className='text-[#0A0D14] text-[32px] font-medium'>
            Sign up to Wholistic
          </h1>
          <span className='text-sm text-[#525866]'>
            Do you already have an account?{' '}
            <Link className='text-[#0A0D14] underline' to='/'>
              Log in
            </Link>
          </span>
        </section>
        <Group key='oauth'>
          <section className='mt-8 flex flex-col w-full gap-y-2'>
            {isLoading ? (
              <div className='w-full flex justify-center h-16'>
                <Loader />
              </div>
            ) : (
              <>
                <SignupWithGoogle url={getProviderUrl('google')} />
                <SignupWithMicrosoft url={getProviderUrl('microsoft')} />
                <SignupWithLinkedin url={getProviderUrl('linkedin')} />
              </>
            )}
          </section>
        </Group>
        <span className='mt-4 block text-center text-sm font-medium'>Or</span>
        <form onSubmit={handleMutate} className='mt-4 flex flex-col gap-y-4'>
          <TextInput
            name='email'
            value={formData.email}
            label='Email'
            autoFocus
            placeholder='Enter your email address'
            handleInputChange={update}
          />
          <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
            <GoogleReCaptcha
              // className='google-recaptcha-custom-class'
              onVerify={setCaptchaToken}
              refreshReCaptcha={refreshReCaptcha}
            />
          </GoogleReCaptchaProvider>

          <Button
            type='submit'
            label='Continue with email'
            disabled={!formData.email || !captchaToken}
            loading={isPending}
          />
        </form>
        <span className='text-xs text-[#868C98] mt-5'>
          By continuing with Google, Microsoft, LinkedIn or Email, I agree to
          Wholistic's{' '}
          <a
            className='text-[#0A0D14] underline'
            href='https://wholistic.webflow.io/legal/terms-of-use'
            target='_blank'
          >
            Terms of Use
          </a>{' '}
          and
          <a
            href='https://wholistic.webflow.io/legal/privacy-policy'
            target='_blank'
            className='text-[#0A0D14] underline'
          >
            {' '}
            Privacy Policy
          </a>
        </span>
      </div>
    </AuthScreen>
  );
};
