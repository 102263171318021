/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { Button, CheckboxElement, TextArea, TextInput } from "~/components";
import Loader from "~/components/UI/Loader";
import { useForm } from "~/hooks";
import { updateRole } from "~/mutations/roleMutations";
import { getPermissions, getRolePermissions } from "~/queries/roleQueries";
import { isActionAllowed } from "~/utils/authHelper";

export const EditRole = ({
  data,
  close,
}: {
  data: Role;
  close?: () => void;
}) => {
  const { formData, update } = useForm({
    initial: { name: data.name, description: data.description },
  });
  const qc = useQueryClient();
  const [selected, setSelected] = useState<any[]>([]);
  const [features, setFeatures] = useState<string[]>([]);

  const { data: rolePermissions, isLoading: permissionsLoading } = useQuery({
    enabled: isActionAllowed("list-roles"),
    queryKey: ["role", data.id, "permissions"],
    queryFn: async () => {
      try {
        const list: string[] = [];
        const perms = await getRolePermissions(data.id);
        perms?.data?.map((d: any) => list.push(d.id));
        setSelected(list);
        return perms;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { data: permissions, isLoading } = useQuery({
    queryKey: ["permissions"],
    queryFn: async () => {
      try {
        const data = await getPermissions();
        setFeatures(
          Array.from<any>(new Set(data.data.map((d: any) => d.feature?.name)))
        );
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      updateRole(data.id, {
        ...formData,
        permissions: [...selected],
        status: "active",
      }),
    ...{
      onSuccess() {
        toast.success(`${formData.name} role updated successfully`);
        qc.invalidateQueries({ queryKey: ["roles"] });
        close?.();
      },
      onError(err) {
        toast.error(err.message);
      },
    },
  });
  const handleClose = () => {
    mutate();
  };
  const handleCheck = (obj: any) => {
    // allSelected && setAllSelected(false);
    if (selected?.includes(obj.id)) {
      const new_list = selected?.filter((id: any) => id !== obj.id);
      setSelected(() => new_list);
    } else {
      setSelected((prev: any) => [...prev, obj.id]);
    }
  };

  return isLoading || permissionsLoading ? (
    <div className="w-full py-20">
      <Loader big />
    </div>
  ) : (
    <div className="w-full flex flex-col">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Edit role</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5">
        <TextInput
          label="Role Name"
          name="name"
          value={formData?.name}
          handleInputChange={update}
          readOnly={data.workspaceId === null}
        />
        <TextArea
          label="Role Description"
          name="description"
          placeholder="Enter Description"
          rows={3}
          value={formData?.description}
          handleInputChange={update}
          readOnly={data.workspaceId === null}
        />
        {features.map((feature) => {
          return (
            <>
              <span className="text-sm font-medium text-[#0A0D14]">
                {feature}
              </span>
              <div className="flex flex-col w-full">
                <div className=" grid grid-cols-2 gap-x-6 gap-y-5 w-full">
                  {permissions?.data
                    ?.filter(
                      (permission: any) => permission.feature?.name === feature
                    )
                    .map((p: any) => (
                      <div key={p.id} className="w-full ">
                        <CheckboxElement
                          checked={selected.includes(p.id!)}
                          name={p.name}
                          label={p.name}
                          changeFunction={() => handleCheck(p)}
                          disabled={data.workspaceId === null}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </>
          );
        })}
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        {data.workspaceId !== null && (
          <Button
            label="Update role"
            effect={handleClose}
            disabled={
              !formData.name ||
              !selected.length ||
              (rolePermissions?.data?.length === selected.length &&
                rolePermissions?.data?.every((p: any) =>
                  selected.includes(p.id)
                ))
            }
            loading={isPending}
          />
        )}
      </footer>
    </div>
  );
};
