/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import "./styles.css";
import { AnimatePresence, motion } from "framer-motion";
import {useDebouncedCallback} from 'use-debounce';

interface MultiSelectProps {
  label?: string;
  selected: any[];
  options: {
    value: string | number | any;
    label: string;
  }[];
  hint?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  useLabel?: boolean;
  showSelected?: boolean;
  setSelected: (v: any) => void;
  handleSearch?: (term: string) => void;
}

export const MultiSelect = ({
  label,
  options,
  name,
  useLabel,
  selected,
  setSelected,
  showSelected = true,
  placeholder,
  required = false,
  hint,
  handleSearch,
}: MultiSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const isSelected = (value: any) => {
    return selected.includes(value);
  };
  const remove = (option: any) =>
    setSelected(...[selected.filter((v) => v !== option)]);
  const handleSelect = (option: any) => {
    if (isSelected(option)) {
      remove(option);
    } else {
      setSelected((prev: any) => [...prev, option]);
    }
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options])

  const setSearchTerm = (term: string) => {
    if (handleSearch) handleSearch(term);
    else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  const setSearchTermDebounced = useDebouncedCallback(
    setSearchTerm,
    500,
  );

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
    setSearchTerm("");
    if (searchRef.current) searchRef.current.value = "";
  };
  const handleClose = () => {
    setIsOpen(false);
    setSearchTerm(getLabel(selected));
  };
  const getLabel = (value: any) => {
    if (useLabel) return value;
    const val = value || selected;
    if (!val) return "";
    const [result] = options.filter((opt) => opt.value == val);
    return result?.label || "";
  };

  useEffect(() => {
    // Event listener for clicks outside the component
    const handleClickOutside = (e: any) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(e.target)
      ) {
        handleClose();
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <div className="flex flex-col overflow-x-clip gap-y-1">
      {label && (
        <label className="select-label font-medium" htmlFor={name}>
          {label}
          {required && <span className="input--required"></span>}
        </label>
      )}
      <div className="bg-white relative " ref={dropdownContainerRef}>
        <div
          id={name}
          ref={searchRef}
          className="select-input"
          onClick={handleOpen}
        >
          {placeholder}
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.18 }}
              className="dropdown-options-container"
            >
              <input
                id={name}
                ref={searchRef}
                autoComplete="off"
                autoFocus={isOpen}
                placeholder="Search"
                onChange={(e) => setSearchTermDebounced(e.target.value)}
                className="bg-[#F9F9FC] rounded-t-[4px] outline-none border-b block border-[#E6E8F0] py-2 px-3 w-full text-[#6C798F]"
              />
              {filteredOptions.map((option: any) => (
                <motion.li
                  animate={{
                    color: isSelected(useLabel ? option.label : option.value)
                      ? "#000"
                      : "",
                  }}
                  key={option.value}
                  onClick={() =>
                    handleSelect(useLabel ? option.label : option.value)
                  }
                >
                  <motion.span
                    animate={{
                      backgroundColor: isSelected(
                        useLabel ? option.label : option.value
                      )
                        ? "#2C1DFF"
                        : "#fff",
                    }}
                    className="w-[16px] h-[16px] grid place-items-center border rounded"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.6654 3.5L5.2487 9.91667L2.33203 7"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </motion.span>

                  {option.label}
                </motion.li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
        <span onClick={handleOpen} className="absolute right-2 top-3">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 10.8805L13.7126 7.16797L14.7731 8.22847L10.0001 13.0015L5.22705 8.22847L6.28755 7.16797L10.0001 10.8805Z"
              fill="#525866"
            />
          </svg>
        </span>
      </div>
      {showSelected && (
        <div className="flex items-center gap-3 mt-2 flex-wrap">
          <AnimatePresence mode="popLayout" initial={false}>
            {selected?.map((v) => (
              <motion.div
                key={v}
                initial={{ x: -90 }}
                animate={{ x: 0 }}
                exit={{
                  x: -90,
                  opacity: 0,
                  // transition: { opacity: { duration: 2 } },
                }}
                //   transition={{ type: 'spring', stiffness: 600, damping: 40 }}
              >
                <span className="flex items-center w-fit gap-x-3 p-1 pl-2 border border-[#E2E4E9] text-[#525866] text-xs rounded-[6px]">
                  {getLabel(v)}
                  <button type="button" onClick={() => remove(v)}>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 3.22234L6.72234 0.5L7.5 1.27766L4.77766 4L7.5 6.72234L6.72234 7.5L4 4.77766L1.27766 7.5L0.5 6.72234L3.22234 4L0.5 1.27766L1.27766 0.5L4 3.22234Z"
                        fill="#868C98"
                      />
                    </svg>
                  </button>
                </span>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}

      <span className="text-xs font-medium text-[#525866] mt-2">{hint}</span>
    </div>
  );
};
