import { useEffect } from "react";
import { Button } from "~/components";
import { AuthScreen } from "~/screens";

export const RequestThanks = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = `https://wholistic.work`;
    }, 10_000);
    return () => clearTimeout(timer);
  });
  return (
    <AuthScreen icon="/auth/mail.svg">
      <div className="flex flex-col w-full">
        <section className="flex flex-col">
          <h1 className="text-[#0A0D14] text-[32px] font-medium">
            We've received your request!
          </h1>
          <span className="text-sm text-[#525866]">
            Your project request has been submitted successfully and you should
            get a response in the next 24-48 hours. Thank you for your time,
            this page will close in 10s.
          </span>
        </section>
        <div className="block mt-5">
          <Button
            label="Visit our site"
            {...{ style: { width: "60%" } }}
            onClick={() => {
              window.location.href = `https://wholistic.work`;
            }}
          />
        </div>
      </div>
    </AuthScreen>
  );
};
