type Guides = {
  [key: string]: string[];
};

export const guides: Guides = {
  google: [
    "Go to console.cloud.google.com, open the navigation menu, and select “OAuth consent screen”. Ensure a project is selected; if none exists, you'll be prompted to create one.",
    "Click the “Select a project” dropdown to choose an existing project, or if needed, create a new one by selecting “New project”.",
    "Choose “External” as the user type, then click “Create”.",
    "Enter your app's name and select your email from the “User support email” dropdown.",
    "Enter your preferred email in the “Developer contact information” field, then click “Save and continue.”",
    "On the following page, click “Save and continue”. Then, click “Add users”, enter the user's email address, and click “Save.”",
    "Click “Publish App”, then confirm by selecting “Confirm”.",
    "Next, go to “Credentials”, click “Create credentials”, and select “OAuth client ID” from the dropdown.",
    "Under “Application type”, select “Web application” for Wholistic.",
    "Under Authorized Redirect URIs, select Add URI, enter your workspace URI, and click Create. The generated API keys will appear. Copy the client ID and client secret, and store them securely for future use.",
    "Navigate to Enabled APIs and Services and select it. Then, choose the Google product you wish to integrate or search for it and click Enable.",
  ],
  slack: [
    "Go to api.slack.com and sign in with your Slack account.",
    "Click on 'Your Apps' in the top right corner.",
    "Click on 'Create New App' and choose 'From scratch'.",
    "Give your app a name and select the workspace you want to develop it in, then click 'Create App'.",
    "On the 'Basic Information' page, scroll down to 'App Credentials'. Here you'll find your Client ID and Client Secret.",
    "In the left sidebar, click on 'OAuth & Permissions'.",
    "Scroll down to 'Redirect URLs' and add the URL where you want Slack to send users after they authorize your app.",
    "Scroll further down to 'Scopes' and add the necessary OAuth scopes your app will need. These determine what your app can do in a Slack workspace.",
    "Go back to 'Basic Information' and scroll down to 'Install your app'. Click 'Install to Workspace' to add the app to your Slack workspace.",
    "After installation, you'll be redirected to the OAuth & Permissions page where you can find your 'Bot User OAuth Token'. This is what you'll use to make API calls.",
    "If you need additional features, you can enable them in the left sidebar (e.g., Incoming Webhooks, Interactivity & Shortcuts, etc.).",
    "Remember to securely store your Client ID, Client Secret, and Bot User OAuth Token.",
  ],
  clickup: [
    "Log in to your ClickUp account at app.clickup.com.",
    "Click on your profile picture in the bottom left corner to open the user menu.",
    "Select 'Settings' from the menu options.",
    "In the left sidebar of the Settings page, click on 'Apps'.",
    "Scroll down to the 'API' section.",
    "You'll see your personal API token displayed here. If not, click 'Generate' to create a new token.",
    "Copy and securely store this API token. It will be used to authenticate your API requests.",
    "To create a new OAuth2 application (if needed), click on 'Create an App' in the OAuth2 Applications section.",
    "Fill in the required information for your app, including name and redirect URL.",
    "After creating the app, you'll receive a Client ID and Client Secret. Store these securely.",
    "To get your Team ID (also known as Workspace ID), go to your ClickUp workspace.",
    "The Team ID is the number in the URL after '/t/' when you're in a workspace.",
    "Remember to keep all these credentials (API token, Client ID, Client Secret, and Team ID) secure and never share them publicly.",
    "When making API requests, you'll typically use the API token in the 'Authorization' header of your HTTP requests.",
    "Refer to the ClickUp API documentation at clickup.com/api for detailed information on endpoints and request formats.",
  ],
  "zoho-workdrive": [
    "Go to https://accounts.zoho.com/developerconsole and sign in using your Zoho account credentials.",
    "Click on 'Add Client'.",
    "Choose 'Server-based Applications' as the Client Type.",
    "Enter your app name, homepage URL, and authorized redirect URIs. The redirect URI is where Zoho will send the authorization code after access is granted.",
    "Set the required scopes for Zoho WorkDrive, such as 'WorkDrive.files.ALL' to manage all files or 'WorkDrive.team.ALL' to manage teams.",
    "Click 'Create'.",
    "After submitting the details, Zoho will generate the Client ID and Client Secret. Copy and store them securely.",
    "To start using the API, authenticate by generating an access token using OAuth 2.0 and the client credentials.",
  ],
  openai: [
    "Go to https://platform.openai.com/signup and sign in with your OpenAI account.",
    "After signing in, navigate to your profile in the top-right corner and click 'API Keys'.",
    "Click 'Create new secret key' to generate a new API key (client secret). Copy this key and store it securely for integration.",
    "To find available models, go to the 'Playground' or API documentation (https://platform.openai.com/docs) and select the models you'd like to use.",
    "For text-based tasks, popular models include 'gpt-3.5-turbo' or 'gpt-4', and for embeddings, you can use 'text-embedding-ada-002'.",
    "You can now use the API key and the selected model name to integrate OpenAI into other applications, making requests to their API endpoints.",
  ],
  "openai-embeddings": [
    "Sign in or create an OpenAI account at https://platform.openai.com/signup.",
    "After signing in, go to 'API Keys' in your account and generate a new API key if you don't already have one.",
    "To use embeddings, find the appropriate model name from the documentation, such as 'text-embedding-ada-002'.",
    "Make a POST request to the Embeddings endpoint (https://api.openai.com/v1/embeddings) with your input text and the model name.",
    "Use the generated API key in the Authorization header (Bearer token) to authenticate requests.",
    "Integrate the API key and model into your application to retrieve embeddings for your data.",
  ],
  microsoft: [
    "Go to https://entra.microsoft.com/ and sign in with your Microsoft account",
    "Navigate to 'Identity'",
    "Click on Applications and then on 'New registration' and enter your app name",
    "Select 'Accounts in an organisational directory (Any Microsoft Entra ID tenant - Multi Tenant) and personal Microsoft accounts (e.g. Skype, Xbox)' from the supported account types",
    "In the redirect URI section, select 'web' from the dropdown menu",
    "Input the redirect URI and click on 'register'",
    "Copy your client ID (Application (client) ID) from the Overview page",
    "Navigate to certificates & secrets",
    "Click on 'New client secret', enter the client secret description, select the expiration period, and click on 'Add'",
    "Copy the client secret under the value section",
  ],
  github: [
    "Go to https://github.com and sign in with your account.",
    "Click on your profile icon in the top-right corner and select 'Settings'.",
    "In the left sidebar, scroll down to 'Developer settings' and click on it.",
    "Under 'Developer settings', click on 'OAuth Apps'.",
    "Click the 'New OAuth App' button to create a new application.",
    "Fill in the required fields, such as Application name, Homepage URL, and Authorization callback URL (this is the redirect URL where users will be sent after authenticating).",
    "After submitting the form, GitHub will generate a **client ID** and **client secret** for your application.",
    "Copy and securely store the **client ID** and **client secret** for future use.",
    "You can manage these credentials later by returning to 'OAuth Apps' under 'Developer settings'.",
  ],
};
