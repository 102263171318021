/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useNavigate } from "react-router-dom";
import { AuthScreen } from "../../screens";
import { Button, Group, PasswordInput, TextInput } from "../../components";
import { useForm } from "../../hooks";
import { openModal } from "../../providers";
import { VerifyCode } from "../../actions";
import {
  LoginWithGoogle,
  LoginWithLinkedin,
  LoginWithMicrosoft,
} from "./components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAuthProviders } from "~/queries/authQueries";
import Loader from "~/components/UI/Loader";
import { emailLogin } from "~/mutations/authMutations";
import toast from "react-hot-toast";
import { useAuth } from "~/providers";

export const LoginPage = () => {
  const popup = openModal();
  const { setStoredToken } = useAuth();
  const navigate = useNavigate();
  const { formData, update } = useForm({
    initial: { email: "", password: "" },
  });
  const { data: providers, isLoading } = useQuery<
    { provider: string; url: string }[]
  >({
    queryKey: ["providers"],
    queryFn: async () => {
      try {
        const data = await getAuthProviders();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: async () => emailLogin({ ...formData }),
    ...{
      onSuccess(data) {
        setStoredToken(data);
        navigate("/choose");
      },
      onError(error: any) {
        if (error.status == 412) {
          popup({ component: <VerifyCode token={error.data.data.token} /> });
        } else {
          toast.error(error.message);
        }
      },
    },
  });

  const handleLogin = (e: any) => {
    e.preventDefault();
    mutate();
  };
  const getProviderUrl = (provider: "microsoft" | "linkedin" | "google") => {
    const [url] = providers!.filter((pr) => pr.provider == provider);
    return url.url;
  };
  return (
    <AuthScreen icon="/auth/forward.svg">
      <div className="flex flex-col w-full">
        <section className="flex flex-col">
          <h1 className="text-[#0A0D14] text-[32px] font-medium">
            Log in to Wholistic
          </h1>
          <span className="text-sm text-[#525866]">
            Don't have an account?{" "}
            <Link className="text-[#0A0D14] underline" to="/signup">
              Sign up
            </Link>
          </span>
        </section>
        <Group key="oauth">
          <section className="mt-8 flex flex-col w-full gap-y-2">
            {isLoading ? (
              <div className="w-full flex justify-center h-16">
                <Loader />
              </div>
            ) : providers ? (
              <>
                <LoginWithGoogle url={getProviderUrl("google")} />
                <LoginWithMicrosoft url={getProviderUrl("microsoft")} />
                <LoginWithLinkedin url={getProviderUrl("linkedin")} />
              </>
            ) : null}
          </section>
        </Group>
        {providers ? (
          <span className="mt-4 block text-center text-sm font-medium">Or</span>
        ) : null}
        <form onSubmit={handleLogin} className="mt-4 flex flex-col gap-y-4">
          <TextInput
            name="email"
            value={formData.email}
            label="Email"
            autoFocus
            placeholder="Enter your email address"
            handleInputChange={update}
          />
          <PasswordInput
            name="password"
            value={formData.password}
            label="Password"
            placeholder="• • • • • • • • • • "
            handleInputChange={update}
          />
          <Link to="/forgot" className="text-sm font-medium text-[#35B9E9]">
            Forgot Password?
          </Link>
          <Button
            type="submit"
            label="Log in"
            loading={isPending}
            disabled={!formData.email && !formData.password}
          />
        </form>
      </div>
    </AuthScreen>
  );
};
