/* eslint-disable @typescript-eslint/no-explicit-any */
interface CheckboxElementProps {
  label?: string;
  disabled?: boolean;
  name?: string;
  checked?: boolean;
  changeFunction: (e: any) => void;
}

export const CheckboxElement = ({
  label,
  name,
  checked,
  disabled,
  changeFunction,
}: CheckboxElementProps) => {
  return (
    <div className='flex items-center gap-x-[10px]'>
      <input
        type='checkbox'
        id={name}
        className='rounded-[5px] w-4 h-4 accent-[#35B9E9]'
        name={name}
        checked={checked}
        onChange={changeFunction}
        disabled={disabled}
      />
      {label && (
        <label
          htmlFor={name}
          className='text-[#0A0D14] capitalize text-sm -tracking-[3%]'
        >
          {label}
        </label>
      )}
    </div>
  );
};
