/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Unsaved } from "~/actions";
import {
  Button,
  ExpandableButton,
  Group,
  MultiSelect,
  SelectElement,
  Table,
  TextArea,
  TextInput,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { TableAvatar } from "~/components/UI/TableAvatar";
import { useForm } from "~/hooks";
import { useClients, useUsers } from "~/hooks/useLists";
import {
  updateProject,
  updateProjectUsers,
} from "~/mutations/projectMutations";
import { openModal } from "~/providers";
import { getProject, getProjectUsers } from "~/queries/projectQueries";
import { areObjectsEqual, arraysEqual, handleError } from "~/utils/helpers";
import { motion } from "framer-motion";
import { isActionAllowed } from "~/utils/authHelper";
import { InviteMember } from "./Invite";

export const EditProjectPage = () => {
  const navigate = useNavigate();
  const popup = openModal();
  const { id } = useParams();
  const { list: clients = [] } = useClients();
  const { list: users = [], data = [] } = useUsers();
  const [originalSelectedUsers, setOriginalSelectedUsers] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedUserData, setSelectedUserData] = useState<any>([]);
  const [statuses, setStatuses] = useState([
    { value: "pending", label: "Pending" },
    { value: "ongoing", label: "Ongoing" },
    { value: "blocked", label: "Blocked" },
    { value: "completed", label: "Completed" },
  ]);
  const qc = useQueryClient();
  const { formData, update, setData, setErrors, errors } = useForm({
    initial: {
      name: "",
      email: "",
      description: "",
      serviceType: "",
      startDate: "",
      endDate: "",
      daysSpent: "",
      workDone: "",
      clientId: "",
      status: "",
    },
  });

  const { data: project, isLoading } = useQuery<Project>({
    queryKey: ["project", id],
    queryFn: async () => {
      try {
        const data = await getProject(id);
        setData({
          email: data.email,
          name: data.name,
          description: data.description,
          serviceType: data.serviceType,
          startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(data.endDate).format("YYYY-MM-DD"),
          daysSpent: data.daysSpent,
          workDone: data.workDone,
          clientId: data.clientId,
          status: data.status,
        });
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
  });
  const { data: memberss, isLoading: membersLoading } = useQuery({
    queryKey: ["project", id, "members"],
    queryFn: async () => {
      try {
        const data = await getProjectUsers(id);
        setSelectedUserData(data);
        const usersss = data.map((u: any) => u.id);
        setSelectedUsers(usersss);
        setOriginalSelectedUsers(usersss);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (status?: string) =>
      updateProject(
        {
          ...formData,
          workDone: parseFloat(formData.workDone || "0"),
          startDate: formData.startDate
            ? dayjs(formData.startDate!).format("YYYY-MM-DD")
            : formData.startDate,
          endDate: formData.endDate
            ? dayjs(formData.endDate).format("YYYY-MM-DD")
            : formData.endDate,
          status: status ?? formData.status ?? undefined,
          email: formData.email || undefined,
        },
        id
      ),
    ...{
      onSuccess() {
        toast.success(`${formData.name} updated succesfully`);
        qc.invalidateQueries({ queryKey: ["projects"] });
        navigate("/app/projects");
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === "validation") {
          setErrors(handledError.messages);
        }
      },
    },
  });
  const { mutate: updateUsers, isPending: updating } = useMutation({
    mutationFn: () =>
      updateProjectUsers(
        {
          users: Array.from(new Set([...selectedUsers])),
        },
        id
      ),
    ...{
      onSuccess(_, status?: string) {
        mutate(status);
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === "validation") {
          setErrors(handledError.messages);
        }
      },
    },
  });

  useEffect(() => {
    setStatuses((statuses) => {
      if (
        project?.status &&
        !statuses.find((s) => s.value === project.status)
      ) {
        return [
          {
            value: project.status,
            label: project.status.replace(/\b\w/g, (char) =>
              char.toUpperCase()
            ),
          },
          ...statuses,
        ];
      }
      return statuses;
    });
  }, [project?.status]);

  const handleExit = () => {
    if (
      areObjectsEqual(formData, {
        email: project?.email,
        name: project?.name,
        description: project?.description,
        serviceType: project?.serviceType,
        startDate: dayjs(project?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(project?.endDate).format("YYYY-MM-DD"),
        daysSpent: project?.daysSpent,
        workDone: project?.workDone,
        clientId: project?.clientId,
      }) &&
      arraysEqual(selectedUsers, originalSelectedUsers)
    ) {
      navigate(-1);
    } else {
      popup({ component: <Unsaved /> });
    }
  };
  const handleSubmit = (status?: string) => {
    if (status == "draft" || !selectedUsers.length) return mutate(status);
    if (
      dayjs(formData.startDate).diff(dayjs(), "days") < 1 &&
      project?.status == "pending"
    ) {
      updateUsers("ongoing");
    } else {
      updateUsers(undefined);
    }
  };

  const removeUser = (v: string) => {
    setSelectedUsers((prev: any) => prev.filter((p: string) => p != v));
    setSelectedUserData((prev: any) => prev.filter((p: any) => p.id != v));
  };

  useEffect(() => {
    setSelectedUserData(() => {
      return selectedUsers
        .map((s: any) => data.find((d: any) => d.id === s))
        .filter(Boolean);
    });
  }, [selectedUsers, data]);

  const colums: ColumnDef<User>[] = [
    {
      id: "name",
      cell: ({ row }) => (
        <TableAvatar
          first_name={row.original.profile.firstName}
          last_name={row.original.profile.lastName}
        />
      ),
      header: () => <span>Member Name</span>,
      size: 44.5,
    },
    {
      id: "email",
      cell: ({ row }) => (
        <span className="text-left ">{row.original.email}</span>
      ),
      header: () => <span>Email Address</span>,
      size: 44.5,
    },

    {
      id: "event",
      cell: ({ row }) => (
        <button
          onClick={() => removeUser(row.original.id)}
          className="kebab-button"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 4V2.5H13.75V4H17.5V5.5H16V16.75C16 16.9489 15.921 17.1397 15.7803 17.2803C15.6397 17.421 15.4489 17.5 15.25 17.5H4.75C4.55109 17.5 4.36032 17.421 4.21967 17.2803C4.07902 17.1397 4 16.9489 4 16.75V5.5H2.5V4H6.25ZM5.5 5.5V16H14.5V5.5H5.5ZM7.75 7.75H9.25V13.75H7.75V7.75ZM10.75 7.75H12.25V13.75H10.75V7.75Z"
              fill="#525866"
            />
          </svg>
        </button>
      ),
      header: () => <span> </span>,
    },
  ];

  return isLoading || membersLoading ? (
    <Loader big />
  ) : (
    <div className="flex flex-col w-full text-[#0A0D14] oveflow-clip pb-10 px-8 pt-8 h-full">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={handleExit}
          className="flex items-center gap-x-2 text-[#0A0D14] font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Edit project</span>
        </button>
        <div className="flex items-center gap-x-2">
          <Button mono label="Discard" effect={handleExit} />
          <ExpandableButton
            disabled={
              areObjectsEqual(formData, {
                email: project?.email,
                name: project?.name,
                description: project?.description,
                serviceType: project?.serviceType,
                startDate: dayjs(project?.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(project?.endDate).format("YYYY-MM-DD"),
                daysSpent: project?.daysSpent,
                workDone: project?.workDone,
                clientId: project?.clientId,
                status: project?.status,
              }) && arraysEqual(selectedUsers, originalSelectedUsers)
            }
            loading={isPending || updating}
            label="Save"
          >
            <div className="min-w-[168px] w-full flex flex-col items-start">
              <button className="kebab-button" onClick={() => handleSubmit()}>
                Save
              </button>

              {project?.status == "draft" && (
                <button
                  className="kebab-button"
                  onClick={() => handleSubmit("draft")}
                >
                  Save as draft
                </button>
              )}
            </div>
          </ExpandableButton>
        </div>
      </div>
      <div className="flex flex-col items-center w-full overflow-scroll">
        <div className="flex flex-col gap-y-8 w-full items-center mt-6 max-w-[728px] mx-auto">
          <div className="flex flex-col gap-y-5 w-full">
            <h3 className="font-medium">Project Details</h3>
            <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
              <div className="grid grid-cols-2 gap-x-5">
                <TextInput
                  label="Project name"
                  value={formData.name}
                  error={errors.name}
                  name="name"
                  placeholder="Enter project name"
                  autoFocus
                  handleInputChange={update}
                />
                <SelectElement
                  label="Client name"
                  error={errors.clientId}
                  value={formData.clientId}
                  name="clientId"
                  placeholder="Select client"
                  onChange={update}
                  options={clients}
                  searchable
                />
              </div>
              <SelectElement
                label="Project Status"
                error={errors.status}
                value={formData.status}
                name="status"
                placeholder="Select status"
                onChange={update}
                options={statuses}
              />
              <TextArea
                label="Project description"
                value={formData.description}
                name="description"
                placeholder="Enter description..."
                handleInputChange={update}
              />
              <TextInput
                label="Service Type"
                value={formData.serviceType}
                error={errors.serviceType}
                name="serviceType"
                placeholder="e.g Product Management"
                handleInputChange={update}
              />
              <div className="grid grid-cols-2 gap-x-5">
                <TextInput
                  label="Start Date"
                  value={formData.startDate}
                  error={errors.startDate}
                  name="startDate"
                  type="date"
                  handleInputChange={update}
                />
                <TextInput
                  label="End Date"
                  error={errors.endDate}
                  value={formData.endDate}
                  name="endDate"
                  type="date"
                  handleInputChange={update}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-5">
                <div className="flex flex-col ">
                  <label
                    htmlFor="workDone"
                    className="text-sm relative font-medium text-[#0A0D14]"
                  >
                    Work Done
                  </label>
                  <div className="border border-[#E2E4E9] rounded-xl flex items-center ">
                    <input
                      placeholder="0.00"
                      value={formData.workDone}
                      name="workDone"
                      type="text"
                      onChange={update}
                      className="px-3 py-[10px] bg-white text-sm outline-none w-full rounded-l-xl"
                    />
                    <span className="border-l px-3 py-[10px] text-[#868C98] text-sm shrink-0">
                      %
                    </span>
                  </div>
                  {errors?.workDone && (
                    <motion.span
                      initial={{ opacity: 0, height: 0 }}
                      animate={{
                        opacity: errors?.workDone ? 1 : 0,
                        height: errors?.workDone ? 1 : 0,
                      }}
                      className="text-xs text-[#EC5757] capitalize"
                    >
                      {errors?.workDone ? errors?.workDone ?? " 7s" : "7s"}
                    </motion.span>
                  )}
                </div>
                <TextInput
                  label="Project email"
                  value={formData.email}
                  error={errors.email}
                  name="email"
                  placeholder="Enter project email"
                  handleInputChange={update}
                  required={false}
                  type="email"
                />
              </div>
            </div>
          </div>
          <Group key="team members">
            <div className="flex flex-col gap-y-5 w-full">
              <h3 className="font-medium">Team members</h3>
              <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
                <div className="flex flex-row justify-between gap-x-5">
                  <div className="flex-grow">
                    <MultiSelect
                      setSelected={setSelectedUsers}
                      selected={selectedUsers}
                      options={users}
                      placeholder="Select Team Members"
                      name="user"
                      showSelected={false}
                    />
                  </div>
                  <div>
                    <Button
                      label="Invite Member"
                      effect={() =>
                        popup({
                          component: (
                            <InviteMember
                              close={(id) => {
                                setSelectedUsers([...selectedUsers, id]);
                              }}
                            />
                          ),
                        })
                      }
                      loading={isPending}
                    />
                  </div>
                </div>

                {selectedUserData?.length > 0 ? (
                  <Table key="users" cols={colums} rawData={memberss} />
                ) : (
                  <div className="w-full flex flex-col items-center">
                    <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 text-center">
                      <img src="/empty.svg" className="w-[100px]" />
                      <div className="flex flex-col items-center gap-y-2">
                        <span className=" text-sm text-[#0A0D14]">
                          No team members to show
                        </span>
                        {isActionAllowed("add-team-member") && (
                          <span className=" text-sm text-[#525866]">
                            You have not added any team members. Please add a
                            member above
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Group>
        </div>
      </div>
    </div>
  );
};
