/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import React from "react";

interface TableProps {
  cols: readonly ColumnDef<any>[];
  rawData: any[];
  clickFunction?: (rowData: any) => void;
  linkPrefix?: string;
  linkSuffix?: string;
  extra?: string;
  loading?: boolean;
  useData?: boolean;
  truncate?: boolean;
}

export const Table = ({
  cols,
  rawData,
  clickFunction,
  linkPrefix,
  linkSuffix,
  extra,
  useData,
}: TableProps) => {
  const columns: ColumnDef<any, any>[] = [...cols];
  const data = React.useMemo(() => [...rawData], [rawData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="relative w-full">
      <table
        {...{
          style: {
            width: "100%",
          },
        }}
        className="w-full"
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={index}
                  align="left"
                  className="bg-[#F6F8FA] first:rounded-lg last:rounded-lg text-sm text-[#525866] font-normal py-2 px-3 relative min-w-fit max-w-fit "
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width: `${header.getSize()}%`,
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className="mt-3">
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={index}
              className={`py-1 border-b last:border-none text-[#0A0D14] transition-all duration-200 rounded-lg ${
                clickFunction && "hover:bg-[#f5f5f5] hover:cursor-pointer"
              }`}
              onClick={() =>
                clickFunction
                  ? useData
                    ? clickFunction(row.original)
                    : clickFunction(
                        `${linkPrefix ? linkPrefix : ""}${
                          row.original.clientId || row.original.id
                        }${linkSuffix ? linkSuffix : ""}${
                          extra ? row.original[extra] : ""
                        }`
                      )
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={index}
                  className="py-[14px] px-3 text-sm"
                  {...{
                    style: {
                      width: `${cell.column.getSize()}%`,
                    },
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
