import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { AddProjectIntegration } from "~/actions";
import { getProjectIntegrations, removeIntegrationAuthorization } from "~/api";
import { Button, Group, Kebab } from "~/components";
import Loader from "~/components/UI/Loader";
import { openModal } from "~/providers";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { accordionVariants } from "~/constants/animations";
import { isActionAllowed } from "~/utils/authHelper";
import { DeleteResource } from "./DeleteResource";
import { AddQualityResource } from "./AddQualityResources";
import { getMyRole } from "~/api/profile";
import { useAuth } from "~/providers";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const Connections = ({
  id,
  integration,
  close,
}: {
  id: any;
  integration: Integration;
  close?: () => void;
}) => {
  const qc = useQueryClient();
  const { role } = useAuth();

  const popup = openModal();
  const {
    data: integrations,
    isLoading,
    refetch,
  } = useQuery<ListResponse<ProjectIntegration>>({
    enabled: isActionAllowed("list-project-integrations"),
    queryKey: ["project", id, "integrations", integration?.slug],
    queryFn: async () => {
      try {
        const data = await getProjectIntegrations(id, {
          "filter[integration]": integration?.slug,
          limit: 1000,
        });

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () => removeIntegrationAuthorization(integration.slug),
    ...{
      onSuccess() {
        qc.invalidateQueries({ queryKey: ["integrations", "connections"] });
        integration!.authorized = false;
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  return (
    <div className="w-full flex flex-col h-full relative">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Resources</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className=" flex flex-col w-full h-full overflow-scroll remove-scroll">
        {isLoading ? (
          <Loader big />
        ) : integrations?.data && integrations?.data.length > 0 ? (
          <div className="flex flex-col ">
            {integrations.data.map((item) =>
              item.children && item.children.length > 0 ? (
                <FolderItem id={id} key={item.id} item={item} />
              ) : (
                <ListItem id={id} key={item.id} item={item} role={role} />
              )
            )}
          </div>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center gap-y-5">
            <img src="/emptyintegrations.svg" />
            <p className="text-center text-sm text-[#525866]">
              Sync your projects with Wholistic. You'll get in-app notifications
              on activity and get expert insights with Wholistic AI.
            </p>
          </div>
        )}
      </section>
      <footer className="p-5 bg-white border flex flex-col w-full gap-y-5">
        <div className="flex flex-col gap-y-2">
          {isActionAllowed("add-project-integration") && (
            <Button
              label="Link project resource"
              {...{ style: { width: "100%" } }}
              effect={() => {
                if (integration?.url && !integration.authorized) {
                  window.open(integration.url, "_blank");
                } else {
                  popup({
                    component: (
                      <AddProjectIntegration
                        id={id}
                        app={integration}
                        refetch={refetch}
                      />
                    ),
                  });
                }
              }}
            />
          )}
          {integration?.authorized && (
            <Button
              danger
              label="Remove Authorization"
              effect={mutate}
              loading={isPending}
            />
          )}
        </div>
      </footer>
    </div>
  );
};

const FolderItem = ({ id, item }: { id: string; item: ProjectIntegration }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popup = openModal();

  const handleClick = (data: any, action: "delete") => {
    action == "delete" &&
      popup({ component: <DeleteResource id={id} data={data} /> });
  };

  return (
    <>
      <Group key="title">
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className="flex items-center py-[17.5px] border-b border-[#E2E4E9] pl-3 pr-4 justify-between cursor-pointer"
        >
          <div className="flex items-center gap-x-3">
            <motion.svg
              animate={{ rotate: isOpen ? 0 : -90 }}
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 7.25L9 11.75L13.5 7.25"
                stroke="#626F86"
                strokeWidth="1.5"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </motion.svg>

            <div className="items-center gap-x-3 flex">
              <span className="flex items-center justify-center border-[#E1E4EA] border rounded-lg h-8 w-8 bg-white">
                <img src={item?.integration?.icon} />
              </span>
              <div className="flex flex-col ">
                <span className="text-sm text-[#0A0D14]">{item.name}</span>
                <span className="flex items-center gap-x-1">
                  <div className="relative">
                    <span className="bg-[#38C793] rounded-full w-3 h-3 animate-pulse flex items-center justify-center"></span>
                    <span className="bg-[#38C793] rounded-full block w-[6px] h-[6px] absolute inset-0 my-auto mx-auto"></span>
                  </div>
                  <span className="text-xs font-medium text-[#38C793]">
                    Connected
                  </span>
                </span>
              </div>
            </div>
          </div>
          <Kebab>
            <button
              style={{ color: "#DF1C41" }}
              className="kebab-button"
              onClick={() => handleClick(item, "delete")}
            >
              Delete
            </button>
          </Kebab>
        </div>
      </Group>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key={item.id}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={accordionVariants}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="bg-[#F5F7FA] flex pl-[53px] pr-4 max-h-[272px] overflow-scroll remove-scroll inner-shadow">
              <div className="flex flex-col border-l border-[#E2E4E9] h-full">
                {item.children?.map((child) => (
                  <div
                    key={child.id}
                    className="flex items-center py-3 -ml-[3px] gap-x-2"
                  >
                    <svg
                      width="26"
                      height="5"
                      viewBox="0 0 26 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line x1="26" y1="2.5" x2="2" y2="2.5" stroke="#E2E4E9" />
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#E2E4E9" />
                    </svg>
                    <a
                      href={child.url}
                      target="_blank"
                      className="text-sm text-[#525866] flex items-center gap-x-1.5"
                    >
                      {child.integration.slug.includes("drive") ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 4.75V15.25H16V6.25H9.6895L8.1895 4.75H4ZM10.3105 4.75H16.75C16.9489 4.75 17.1397 4.82902 17.2803 4.96967C17.421 5.11032 17.5 5.30109 17.5 5.5V16C17.5 16.1989 17.421 16.3897 17.2803 16.5303C17.1397 16.671 16.9489 16.75 16.75 16.75H3.25C3.05109 16.75 2.86032 16.671 2.71967 16.5303C2.57902 16.3897 2.5 16.1989 2.5 16V4C2.5 3.80109 2.57902 3.61032 2.71967 3.46967C2.86032 3.32902 3.05109 3.25 3.25 3.25H8.8105L10.3105 4.75Z"
                            fill="#868C98"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.75 7V16.7448C16.7507 16.8432 16.732 16.9409 16.6949 17.0322C16.6579 17.1234 16.6032 17.2065 16.534 17.2766C16.4649 17.3468 16.3826 17.4026 16.2919 17.4409C16.2011 17.4792 16.1037 17.4993 16.0052 17.5H3.99475C3.79736 17.5 3.60804 17.4216 3.4684 17.2821C3.32875 17.1426 3.2502 16.9534 3.25 16.756V3.244C3.25 2.84125 3.58675 2.5 4.0015 2.5H12.2477L16.75 7ZM15.25 7.75H11.5V4H4.75V16H15.25V7.75Z"
                            fill="#868C98"
                          />
                        </svg>
                      )}{" "}
                      {child.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};
const ListItem = ({
  id,
  item,
  role,
}: {
  id: string;
  item: ProjectIntegration;
  role: any;
}) => {
  const popup = openModal();

  const handleClick = (data: any, action: "delete") => {
    action == "delete" &&
      popup({ component: <DeleteResource id={id} data={data} /> });
  };

  const { data: roleData } = useQuery({
    queryKey: ["role"],
    queryFn: getMyRole,
  });

  const handleQualityResourceClick = (data: any, action: "add") => {
    action == "add" &&
      popup({
        component: (
          <AddQualityResource id={id} data={data} userRole={roleData} />
        ),
      });
  };

  // addQualityResource

  return (
    <div className="flex items-center py-[17.5px] border-b border-[#E2E4E9] pl-[42px] pr-4 justify-between">
      <a href={item.url} target="_blank">
        <div className="items-center gap-x-3 flex">
          <span className="flex items-center justify-center border-[#E1E4EA] border rounded-lg h-8 w-8 bg-white">
            <img src={item?.integration?.icon} />
          </span>
          <div className="flex flex-col ">
            <span className="text-sm text-[#0A0D14]">{item.name}</span>
            <span className="flex items-center gap-x-1">
              <div className="relative">
                <span className="bg-[#38C793] rounded-full w-3 h-3 animate-pulse flex items-center justify-center"></span>
                <span className="bg-[#38C793] rounded-full block w-[6px] h-[6px] absolute inset-0 my-auto mx-auto"></span>
              </div>
              <span className="text-xs font-medium text-[#38C793]">
                Connected
              </span>
            </span>
          </div>
        </div>
      </a>
      <>
        {role?.slug !== "project-team-member" && (
          <Kebab>
            <button
              style={{ color: "#DF1C41" }}
              className="kebab-button"
              onClick={() => handleClick(item, "delete")}
            >
              Delete
            </button>
            {item?.integration?.slug === "google-sheets" && (
              <button
                style={{ color: "#0A0D14" }}
                className="kebab-button"
                onClick={() => handleQualityResourceClick(item, "add")}
              >
                Quality Resource
              </button>
            )}
          </Kebab>
        )}
      </>
    </div>
  );
};
