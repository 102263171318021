export const Tracker = ({
  status,
  label,
}: {
  status: string | undefined | null;
  label?: string;
}) => {
  let bg, text, icon;
  switch (status?.toLowerCase()) {
    case "catching-up":
      text = "#375DFB";
      bg = "#EBF1FF";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0ZM8.7576 3.2424C8.6352 3.1194 8.442 3.1026 8.2998 3.2022C6.5658 4.419 5.5878 5.1402 5.3634 5.3634C5.0124 5.715 5.0124 6.285 5.3634 6.6366C5.715 6.9876 6.285 6.9876 6.6366 6.6366C6.768 6.5046 7.4874 5.526 8.7954 3.6984C8.8962 3.558 8.88 3.3648 8.7576 3.2424ZM9.3 5.4C8.9688 5.4 8.7 5.6688 8.7 6C8.7 6.3312 8.9688 6.6 9.3 6.6C9.6312 6.6 9.9 6.3312 9.9 6C9.9 5.6688 9.6312 5.4 9.3 5.4ZM2.7 5.4C2.3688 5.4 2.1 5.6688 2.1 6C2.1 6.3312 2.3688 6.6 2.7 6.6C3.0312 6.6 3.3 6.3312 3.3 6C3.3 5.6688 3.0312 5.4 2.7 5.4ZM4.0908 3.2424C3.8568 3.0084 3.4764 3.0084 3.2424 3.2424C3.0084 3.4764 3.0084 3.8562 3.2424 4.0908C3.4764 4.3248 3.8562 4.3248 4.0908 4.0908C4.3248 3.8568 4.3248 3.4764 4.0908 3.2424ZM6 2.1C5.6688 2.1 5.4 2.3688 5.4 2.7C5.4 3.0312 5.6688 3.3 6 3.3C6.3312 3.3 6.6 3.0312 6.6 2.7C6.6 2.3688 6.3312 2.1 6 2.1Z"
            fill="#375DFB"
          />
        </svg>
      );

      break;
    case "overdue":
      bg = "#FDEDF0";
      text = "#E32227";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM3.9138 3.0654C3.58604 3.29915 3.29952 3.58587 3.066 3.9138L8.0868 8.9346C8.41477 8.7009 8.7015 8.41418 8.9352 8.0862L3.9138 3.0654Z"
            fill="#E32227"
          />
        </svg>
      );

      break;
    case "at-risk":
      text = "#DF1C41";
      bg = "#FDEDF0";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM6 5.1516L4.3032 3.4542L3.4542 4.3032L5.1516 6L3.4542 7.6968L4.3032 8.5458L6 6.8484L7.6968 8.5458L8.5458 7.6968L6.8484 6L8.5458 4.3032L7.6968 3.4542L6 5.1516Z"
            fill="#DF1C41"
          />
        </svg>
      );

      break;

    case "on-track":
      bg = "#EFFAF6";
      text = "#38C793";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM6.6 6V3H5.4V7.2H9V6H6.6Z"
            fill="#38C793"
          />
        </svg>
      );
      break;

    case "on-hold":
      bg = "#E0E2E7";
      text = "#868C98";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM6.6 6V3H5.4V7.2H9V6H6.6Z"
            fill="#868C98"
          />
        </svg>
      );
      break;

    case "completed":
      bg = "#E8FAFF";
      text = "#35B9E9";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM6.6 6V3H5.4V7.2H9V6H6.6Z"
            fill="#35B9E9"
          />
        </svg>
      );

      break;

    case "warning":
      bg = "#FEF7EC";
      text = "#F2AE40";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4 7.8V9H6.6V7.8H5.4ZM5.4 3V6.6H6.6V3H5.4Z"
            fill="#F2AE40"
          />
        </svg>
      );
      break;

    default:
      bg = "#FEF7EC";
      text = "#F2AE40";
      icon = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4 7.8V9H6.6V7.8H5.4ZM5.4 3V6.6H6.6V3H5.4Z"
            fill="#F2AE40"
          />
        </svg>
      );
  }
  return (
    <span
      style={{ background: bg, color: text }}
      className={` px-2 py-1 text-xs capitalize rounded-[8px] flex items-center gap-x-1 w-fit font-medium `}
    >
      {icon}
      {label || status}
    </span>
  );
};
