/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { Header, Pagination, Table } from '~/components';
import Loader from '~/components/UI/Loader';
import { TableAvatar } from '~/components/UI/TableAvatar';
import { usePagination } from '~/hooks';
import { getAudits } from '~/queries/auditQueries';
import { getDateandTime } from '~/utils/dateUtils';

export const AuditLogsPage = () => {
  const { handlePageChange } = usePagination();
  const searchParams = useSearchParams()[0];
  const createdAt = searchParams.get('createdAt') || '';
  const page = searchParams.get('page') || 1;
  const limit = searchParams.get('limit') || 10;
  const event = searchParams.get('event') || '';
  const { data: audits, isLoading } = useQuery({
    queryKey: ['audits', { createdAt, page, limit, event }],
    queryFn: async () => {
      try {
        const data = getAudits({
          page,
          limit,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const colums: ColumnDef<Audit>[] = [
    {
      id: 'name',
      cell: ({ row }) => (
        <TableAvatar
          first_name={row.original.user?.profile?.firstName}
          last_name={row.original.user?.profile?.lastName}
        />
      ),
      header: () => <span>Member Name</span>,
      size: 23,
    },
    {
      accessorFn: (row) => row.event,
      id: 'event',
      cell: ({ row }) => (
        <span className='capitalize'>
          {row.original.event.split('.').join(' ')}
        </span>
      ),
      header: () => <span>Event Type</span>,
      size: 13.5,
    },
    {
      accessorFn: (row) => row.description,
      id: 'description',
      cell: (info) => info.getValue(),
      header: () => <span>Description</span>,
      size: 38.2,
    },
    {
      accessorFn: (row) => row.createdAt,
      id: 'date',
      cell: ({ row }) => <span>{getDateandTime(row.original.createdAt)}</span>,
      header: () => <span>Date</span>,
      size: 22.6,
    },
  ];

  return (
    <div className='flex flex-col w-full h-full p-8'>
      <Header
        title='Activity log'
        subtext='See all actions performed in your workspace for up to 30days.'
      />
      <div className='flex flex-col mt-6'>
        <div className='mt-6 bg-white w-full rounded-xl flex flex-col p-4'>
          {isLoading ? (
            <Loader big />
          ) : audits?.data?.length > 0 ? (
            <Table cols={colums} rawData={audits?.data} />
          ) : (
            <div className='mt-6 min-h-[400px] w-full h-full rounded-xl flex flex-col items-center justify-center  text-center'>
              <div className='max-w-[260px] w-full flex flex-col items-center gap-y-5 '>
                <img src='/empty.svg' className='w-[100px]' />
                <div className='flex flex-col items-center gap-y-2'>
                  <span className=' text-sm text-[#0A0D14]'>
                    No activities found
                  </span>
                  <span className=' text-sm text-[#525866]'>
                    We can't find any activity that meets the criteria
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {audits?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={audits?.meta?.totalNumberOfPages}
            total={audits?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
