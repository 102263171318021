/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { openModal, useAuth } from '~/providers';
import { Settings } from '~/actions';
import { useQuery } from '@tanstack/react-query';
import { getWorkspace } from '~/queries/workspaceQueries';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import { isActionAllowed, isSubscribedFor } from '~/utils/authHelper';
export const GettingStarted = () => {
  // eslint-disable-next-line prefer-const
  const { workspace: ws, role } = useAuth();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const popup = openModal();
  const handleOpenSettings = (setting: string) => {
    setSearchParams(() => {
      searchParams.set('setting', setting);
      return searchParams;
    });

    popup({ component: <Settings /> });
  };
  const { data: workspace, isLoading } = useQuery<Workspace>({
    queryKey: ['workspaces', ws.id],
    queryFn: async () => {
      try {
        if (!ws.id) return null;
        const data = await getWorkspace(ws.id);
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const countTrueChecklist = (checklists: any) => {
    if (!checklists) return 0;
    return (
      Object.values(checklists).reduce(
        (count: number, value) => count + (value === true ? 1 : 0),
        0
      ) /
      (2 +
        (isSubscribedFor('tools-integration') ? 1 : 0) +
        (isSubscribedFor('project-reports') ? 1 : 0))
    );
  };
  useEffect(() => {
    isOpen && setIsOpen(false);
  }, [pathname]);
  return countTrueChecklist(workspace?.checklists) == 1 ||
    role.slug !== 'workspace-manager' ? null : (
    <div
      id='nav'
      style={{
        right:
          ws.assistantEnabled && isSubscribedFor('assistant-integration') && isActionAllowed("chat-with-assistant")
            ? 90
            : 30,
      }}
      className='fixed bottom-5 z-[22] w-[440px] flex-col items-end'
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            layout
            className='mb-5 w-full origin-bottom-right bg-white rounded-2xl border border-[#E2E4E9]'
            initial={{ scaleY: 0, opacity: 0 }}
            transition={{
              scaleY: {
                type: 'tween',
                ease: [0.4, 0.0, 0.2, 1],
                duration: 0.3,
              },
              opacity: { duration: 0.1 },
            }}
            animate={{ scaleY: 1, opacity: 1 }}
            exit={{
              scaleY: 0,
              opacity: 0,
              transition: {
                type: 'tween',
                ease: [0.4, 0.0, 0.2, 1],
                duration: 0.3,
              },
              transformOrigin: 'bottom-right',
            }}
          >
            <header className='px-5 py-4 border-b rounded-t-2xl flex items-center w-full justify-between'>
              <span className='font-medium text-[#0A0D14]'>
                Onboarding checklist
              </span>
              <button onMouseDown={() => setIsOpen(false)}>
                <svg
                  width='10'
                  height='6'
                  viewBox='0 0 10 6'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4.99956 3.87852L8.71206 0.166016L9.77256 1.22652L4.99956 5.99952L0.226562 1.22652L1.28706 0.166016L4.99956 3.87852Z'
                    fill='#525866'
                  />
                </svg>
              </button>
            </header>
            <div className='flex flex-col p-5 items- w-full'>
              <div className='flex flex-col text-center'>
                <h3 className='text-2xl text-[#0A0D14] font-medium'>
                  Getting started
                </h3>
                <p className='text-sm text-[#525866]'>
                  Get the most out of Wholistic by completing the following
                  tasks
                </p>
              </div>
              <div className='mt-5 flex flex-col gap-y-3'>
                {isSubscribedFor('clients') && (
                  <ActionLink
                    done={workspace?.checklists?.client}
                    title='Create your first client'
                    link='/app/clients/create'
                  />
                )}
                {isSubscribedFor('tools-integration') && (
                  <ActionLink
                    done={workspace?.checklists?.integration}
                    title='Connect an app'
                    action={() => handleOpenSettings('integrations')}
                  />
                )}
                {isSubscribedFor('projects') && (
                  <ActionLink
                    done={workspace?.checklists?.project}
                    title='Create your first project'
                    link='/app/projects/create'
                  />
                )}
                {isSubscribedFor('project-reports') && (
                  <ActionLink
                    done={workspace?.checklists?.report}
                    title='Generate your first report'
                    link='/app/projects/'
                  />
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <button
        key='menu'
        onClick={() => setIsOpen((prev) => !prev)}
        className=' items-center p-4 text-white bg-[#20232D] rounded-2xl backdrop-blur-md text-sm z-[22] ml-auto block'
      >
        {isLoading ? (
          <span>
            <Loader />
          </span>
        ) : (
          `Getting started (${
            countTrueChecklist(workspace?.checklists) * 100
          }% complete) `
        )}
      </button>
    </div>
  );
};

const ActionLink = ({
  done,
  link,
  title,
  action,
}: {
  done?: boolean;
  link?: string;
  title: string;
  action?: (v: any) => void;
}) => (
  <div
    style={{
      border: done ? undefined : '1px solid #E2E4E9',
      background: done ? '#F6F8FA' : undefined,
      color: done ? '#525866' : '#0E121B',
      textDecoration: done ? 'strikethrough' : undefined,
    }}
    className='rounded-lg transition-all duration-200 ease-in-out px-[10px] py-3 w-full flex justify-between items-center text-sm font-medium'
  >
    <div className='flex items-center gap-x-2'>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM8.1027 12.6L14.4657 6.2361L13.1931 4.9635L8.1027 10.0548L5.5566 7.5087L4.284 8.7813L8.1027 12.6Z'
          fill={done ? '#38C793' : '#CDD0D5'}
        />
      </svg>
      <span>{title}</span>
    </div>
    {!done &&
      (action ? (
        <button
          onMouseDown={action}
          className='w-6 h-6 border-[#E2E4E9] border rounded-md flex items-center
        justify-center'
        >
          <svg
            width='6'
            height='8'
            viewBox='0 0 6 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.59687 3.99967L0.8125 1.2153L1.60787 0.419922L5.18762 3.99967L1.60787 7.57942L0.8125 6.78405L3.59687 3.99967Z'
              fill='#525866'
            />
          </svg>
        </button>
      ) : (
        <Link
          to={link!}
          className='w-6 h-6 border-[#E2E4E9] border rounded-md flex items-center justify-center'
        >
          <svg
            width='6'
            height='8'
            viewBox='0 0 6 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.59687 3.99967L0.8125 1.2153L1.60787 0.419922L5.18762 3.99967L1.60787 7.57942L0.8125 6.78405L3.59687 3.99967Z'
              fill='#525866'
            />
          </svg>
        </Link>
      ))}
  </div>
);
