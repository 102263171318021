/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl =
  import.meta.env.VITE_BASE_URL || "https://platform.api.wholistic.work";

export const getTransactions = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/transactions`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getTransactionStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/transactions/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getTransaction = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}//billings/transactions/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getDailyTransactionStats = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/transactions/daily-stats`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getMonthlyTransactionStats = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/transactions/monthly-stats`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getWeeklyTransactionStats = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/transactions/weekly-stats`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const adminGetTransactionStats = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/billings/transactions/stats`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
