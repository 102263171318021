/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@tanstack/react-query";
import { FormEvent } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, PasswordHandler, PasswordInput } from "~/components";
import { useForm } from "~/hooks";
import { forgotPasswordComplete } from "~/mutations/authMutations";
import { AuthScreen } from "~/screens";
import { checkIfValid } from "~/utils/passwordHelpers";

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const token = searchParams.get("token") || "";
  const { formData, update } = useForm({
    initial: { password: "", confirmPassword: "" },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => forgotPasswordComplete({ ...formData }, token),
    ...{
      onSuccess() {
        navigate("/");
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  const handleMutate = (e: FormEvent) => {
    e.preventDefault();
    mutate();
  };
  return (
    <AuthScreen icon="/auth/reset.svg">
      <div className="flex flex-col w-full">
        <section className="flex flex-col">
          <h1 className="text-[#0A0D14] text-[32px] font-medium">
            Reset password
          </h1>
          <span className="text-sm text-[#525866]">
            Create a new password for your account{" "}
          </span>
        </section>

        <form onSubmit={handleMutate} className="mt-8 flex flex-col gap-y-4">
          <div className="flex flex-col">
            <PasswordInput
              name="password"
              value={formData.password}
              label="Password"
              placeholder="• • • • • • • • • • "
              handleInputChange={update}
            />
            <PasswordHandler
              password={formData.password!}
              confirmPassword={formData.confirmPassword}
            />
          </div>
          <PasswordInput
            value={formData.confirmPassword}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="• • • • • • • • • • "
            handleInputChange={update}
          />
          <Link
            to="/"
            className="text-sm underline underline-offset-2 font-medium text-[#35B9E9]"
          >
            I remember my password
          </Link>
          <Button
            loading={isPending}
            type="submit"
            label="Continue"
            disabled={
              !formData.password ||
              !formData.confirmPassword ||
              !checkIfValid(formData.password!, formData.confirmPassword!)
            }
          />
        </form>
      </div>
    </AuthScreen>
  );
};
