/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ActivateClient, DeactivateClient, DeleteClient } from "~/actions";
import {
  Button,
  ExpandableButton,
  Group,
  Kebab,
  Pill,
  ProgressBar,
  Table,
  // TextPanes,
  Tracker,
} from "~/components";
import Loader from "~/components/UI/Loader";
// import { usePanes } from '~/hooks';
import { openModal } from "~/providers";
import { getClient } from "~/queries/clientQueries";
import { isActionAllowed } from "~/utils/authHelper";
import { getFormattedDate } from "~/utils/dateUtils";

export const ClientPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const popup = openModal();
  // const searchParams = useSearchParams()[0];
  // const active = searchParams.get('active') || 'all';
  // const { show, handlePaneSwitch } = usePanes(active);

  const { data: client, isLoading } = useQuery<Client>({
    queryKey: ["client", id],
    queryFn: async () => {
      try {
        const data = await getClient(id);

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  // const panes = [
  //   {
  //     id: 'all',
  //     label: 'All',
  //     show: true,
  //     value: 0,
  //   },
  //   { id: 'completed', label: 'Completed', show: true, value: 0 },
  //   { id: 'ongoing', label: 'Ongoing', show: true, value: 0 },
  //   { id: 'completed', label: 'Blocked', show: true, value: 0 },
  // ];

  const details = [
    { title: "Company ID", value: client?.id.substring(0, 6) },
    { title: "Company name", value: client?.name },
    { title: "Official email address", value: client?.email },
    { title: "Date created", value: getFormattedDate(client?.createdAt) },
    { title: "Status", value: client?.status },
    { title: "Primary contact name", value: client?.contactName },
    { title: "Primary contact email address", value: client?.contactEmail },
    { title: "Primary contact phone number", value: client?.contactPhone },
    { title: "Secondary contact name", value: client?.contactName2 },
    { title: "Secondary contact email address", value: client?.contactEmail2 },
    { title: "Secondary contact phone number", value: client?.contactEmail2 },
  ];
  const colums: ColumnDef<Project>[] = [
    {
      accessorFn: (row) => row.name,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Project Name</span>,
      size: 25,
    },
    {
      id: "project",
      cell: ({ row }) => (
        <ProgressBar progress={parseFloat(row.original.workDone)} />
      ),
      header: () => <span>Work Done</span>,
      size: 30,
    },
    {
      id: "time spent",
      cell: ({ row }) => (
        <ProgressBar progress={row.original.daysSpentPercentage} />
      ),
      header: () => <span>Time spent</span>,
      size: 10,
    },
    // {
    //   id: 'date',
    //   cell: ({ row }) => (
    //     <span className=' block text-center'>{`${dayjs(
    //       row.original.endDate
    //     ).diff(dayjs(), 'day')} days`}</span>
    //   ),
    //   header: () => <span className=' block text-center'>Days left</span>,
    //   size: 5,
    // },
    {
      accessorFn: (row) => row.status,
      id: "health",
      cell: ({ row }) => <Tracker status={row.original.health ?? "on-track"} />,
      header: () => <span className="w-[340px]">Project Health</span>,
      size: 30,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 11,
    },
    {
      id: "event",
      cell: ({ row }) => (
        <Kebab>
          <div className="flex flex-col w-[100px]">
            <button
              onClick={() => navigate(`/app/projects/${row.original.id}/edit`)}
              className="kebab-button"
            >
              Edit Project
            </button>
          </div>
        </Kebab>
      ),
      header: () => <span> </span>,
      // size: 16,
    },
  ];
  const handleProjectClick = (data: Project) => {
    navigate(`/app/projects/${data.id}`);
  };
  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full h-full flex flex-col overflow-scroll p-8">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Back to Clients</span>
        </button>
      </div>
      <Group key="header">
        <div className="flex items-center w-full justify-between mt-6">
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <h1 className="text-[#0A0D14] font-medium text-lg">
                {client?.name}
              </h1>
              <Pill status={client?.status} />
            </div>
            <span className="text-sm text-[#525866]">
              Client ID: {client?.id.substring(0, 6)}
            </span>
          </div>
          {isActionAllowed("update-client") && (
            <ExpandableButton label="Actions" mono>
              <div className="min-w-[144px] w-full flex flex-col items-start">
                <button
                  onClick={() => navigate("edit")}
                  className="kebab-button"
                >
                  Edit
                </button>
                {client?.status == "active" && (
                  <button
                    onClick={() =>
                      popup({
                        component: (
                          <DeactivateClient id={id} name={client?.name} />
                        ),
                      })
                    }
                    className="kebab-button"
                  >
                    Deactivate
                  </button>
                )}
                {client?.status !== "active" && (
                  <button
                    onClick={() =>
                      popup({
                        component: (
                          <ActivateClient id={id} name={client?.name} />
                        ),
                      })
                    }
                    className="kebab-button"
                  >
                    Activate
                  </button>
                )}
                <button
                  onClick={() =>
                    popup({
                      component: <DeleteClient id={id} name={client?.name} />,
                    })
                  }
                  className="kebab-button"
                >
                  Remove
                </button>
              </div>
            </ExpandableButton>
          )}
        </div>
      </Group>
      <div className="flex mt-6 items-start gap-x-5 w-full h-full">
        <Group key="left">
          <div className="w-[35%] h-full">
            {/* <Group key='summary chart'>
              <div className='bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm '>
                <header className='w-full flex items-center justify-between border-b pb-4'>
                  <span className='font-medium'>Project Summary</span>
                </header>
                <div className='text-center w-full flex flex-col items-center gap-y-5 py-4'>
                  <img src='/empty.svg' className='w-[60px]' />
                  <div className='flex flex-col items-center gap-y-2'>
                    <span className=' text-sm text-[#0A0D14]'>
                      No projects to show
                    </span>
                    <span className=' text-sm text-[#525866]'>
                      You have not added any projects. Add a new project to get
                      started
                    </span>
                  </div>
                </div>
              </div>
            </Group> */}
            <Group key="details">
              <div className="bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm ">
                <header className="w-full flex items-center justify-between border-b pb-4">
                  <span className="font-medium">Client Information</span>
                  {isActionAllowed("update-client") && (
                    <Button label="Edit" mono effect={() => navigate("edit")} />
                  )}{" "}
                </header>
                <div className="py-4 flex flex-col gap-y-4">
                  {details.map((d) => (
                    <div key={d.title} className="flex flex-col ">
                      <div className="text-xs text-[#525866]">{d.title}</div>
                      <div className="text-sm text-[#0A0D14]">{d.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Group>
          </div>
        </Group>
        <Group key="right">
          <div className="w-full h-[90%]">
            <h3 className="font-medium">Projects</h3>
            {/* <div className='flex flex-col mt-4 w-full'>
              <TextPanes
                panes={panes}
                active={show}
                handleChange={handlePaneSwitch}
              />
            </div> */}
            {client?.projects && client.projects.length > 0 ? (
              <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4 gap-y-5 h-full">
                <Table
                  clickFunction={handleProjectClick}
                  useData
                  key="clients"
                  cols={colums}
                  rawData={client?.projects}
                />
              </div>
            ) : (
              <div className="mt-4 bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
                <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                  <img src="/empty.svg" className="w-[100px]" />
                  <div className="flex flex-col items-center gap-y-2">
                    <span className=" text-sm text-[#0A0D14]">
                      No projects to show for this client
                    </span>
                    {/* {isActionAllowed("create-project") && (
                      <span className=" text-sm text-[#525866]">
                        You have not added any projects. Add a new project to
                        get started
                      </span>
                    )} */}
                  </div>
                  {/* <Button
                  label='Add new project'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z'
                        fill='black'
                      />
                    </svg>
                  }
                  leftIcon
                /> */}
                </div>
              </div>
            )}
          </div>
        </Group>
      </div>
    </div>
  );
};
