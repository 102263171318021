import { motion, AnimatePresence } from "framer-motion";
import { fadeIn } from "~/constants/animations";
import styles from "./styles.module.css";
import { useState } from "react";
import { Button, TextArea } from "~/components";
import { useForm } from "~/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rejectProjectRequest } from "~/api";
import toast from "react-hot-toast";
export const RejectRequest = ({
  close,
  id,
}: {
  id: string;
  close: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const qc = useQueryClient();
  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => close(), 200);
  };
  const { formData, update } = useForm({ initial: { reason: "" } });
  const { mutate, isPending } = useMutation({
    mutationFn: () => rejectProjectRequest(id, formData.reason),
    ...{
      onSuccess() {
        qc.invalidateQueries({ queryKey: ["projects"] });
        qc.invalidateQueries({ queryKey: ["requests"] });
        toast.success("Request rejected successfully.");
        close();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  return (
    <motion.div
      initial="enter"
      animate="animate"
      exit="exit"
      variants={fadeIn}
      className={styles.background}
    >
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            initial={{ y: 400 }}
            animate={{ y: 0 }}
            exit={{ y: 400 }}
            className="absolute left-0 right-0 bottom-0 w-full bg-white rounded-t-2xl border"
            layout
          >
            <div className="flex flex-col">
              <header className="flex items-start w-full justify-between border-b py-4 px-5">
                <div className="flex flex-col gap-y-1  max-w-[440px]">
                  <h4 className="text-[#0A0D14] font-medium">Confirm action</h4>
                </div>
                <button onClick={handleClose}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
                      fill="#525866"
                    />
                  </svg>
                </button>
              </header>
              <div className="flex flex-col p-5 gap-y-5">
                <p className="text-sm text-[#0A0D14]">
                  Kindly enter the reason for rejecting this request. This will
                  be sent as a response to the client.
                </p>
                <TextArea
                  value={formData.reason}
                  name="reason"
                  placeholder="Enter reason"
                  label="Reason"
                  handleInputChange={update}
                />
              </div>
              <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3 mt-auto">
                <Button mono label="Cancel" effect={close} />
                <Button
                  label="Confirm"
                  loading={isPending}
                  disabled={!formData.reason}
                  effect={mutate}
                />
              </footer>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
