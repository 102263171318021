import { useRouteError, Link, Outlet } from "react-router-dom";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  const errorMessage = (() => {
    switch (error.status) {
      case 404:
        return {
          status: 404,
          title: "Whoops! Seems like you drifted off course...",
          paragraphs: [
            "Looks like you've wandered off the map. The page you're looking for is nowhere to be found!",
            "No worries—head back to our homepage or shoot us an email if you need help finding your way.",
          ],
          regards: "Catch you on the right page!",
          signature: "The Wholistic Team",
        };
      case 403:
        return {
          status: 403,
          title: "Access denied!",
          paragraphs: [
            "Looks like you've stumbled into a restricted area. Unfortunately, we can't grant you access right now.",
            "If you think this is a mistake, or need help, feel free to reach out to our support team.",
          ],
          regards: "Stay in touch!",
          signature: "The Wholistic Team",
        };
      case 500:
        return {
          status: 500,
          title: "Oh shoot! That didn't go as planned...",
          paragraphs: [
            "Looks like something went wrong, but don't worry—our team is on it and we'll be back in action soon!",
            "In the meantime, if you need a hand, just drop us an email. Thanks for sticking with us!",
          ],
          regards: "Yours in whole,",
          signature: "The Wholistic Team",
        };
      default:
        return {
          status: 500,
          title: "Oh shoot! That didn't go as planned...",
          paragraphs: [
            "Looks like something went wrong, but don't worry—our team is on it and we'll be back in action soon!",
            "In the meantime, if you need a hand, just drop us an email. Thanks for sticking with us!",
          ],
          regards: "Yours in whole,",
          signature: "The Wholistic Team",
        };
    }
  })();

  return (
    <div className="w-full h-full px-[50px] py-10 flex flex-col absolute inset-0">
      <Link to="/app">
        <img src="/logo.svg" className="w-[115.02px]" />
        <div className="h-screen pt-0">
          <div className="w-full h-screen flex flex-row items-center justify-center max-md:pb-[96px] pt-[0px]">
            <div className="mt-10 flex flex-col items-start gap-y-0 text-left pl-[140px] pb-[180px] flex-grow font-inter">
              <span className="text-[#525866] font-bold max-w-[500px] pt-[10px]">
                {error.status === 404
                  ? `ERROR CODE: ${errorMessage.status}`
                  : `ERROR CODE: ${error?.error?.status}` || "ERROR CODE: 500"}
              </span>
              <h2 className="text-[#0A0D14] max-w-[700px] text-[56px] font-bold leading-[64px] tracking-[-0.01em] pt-[18px]">
                {errorMessage.title}
              </h2>
              <span className="text-[#525866] max-w-[500px] pt-[10px]">
                {error.status === 404 && errorMessage.paragraphs
                  ? errorMessage.paragraphs[0]
                  : error?.error?.message ||
                    "Looks like something went wrong, but don't worry—our team is on it and we'll be back in action soon!"}
              </span>
              <span className="text-[#525866] max-w-[500px] pt-[30px]">
                {error.status === 404 && errorMessage.paragraphs
                  ? errorMessage.paragraphs[1]
                  : error?.error?.message || ""}
              </span>
              <span className="text-[#525866] max-w-[305px] pt-[30px]">
                {error.status === 404
                  ? errorMessage.regards
                  : error?.error?.message || "Catch you on the right page!"}
              </span>
              <span className="text-[#525866] max-w-[305px]">
                {error.status === 404
                  ? errorMessage.signature
                  : error?.error?.message || "The Wholistic Team"}
              </span>
              <div className="pt-[30px]">
                <Button
                  type="button"
                  label="Take me home"
                  onClick={() => navigate("/app")}
                  disabled={false}
                />
              </div>
            </div>
            {error.status === 404 ? (
              <img
                src="/404.svg"
                alt="Page not found"
                className="w-[960px] h-[960px] pt-[200px] pr-[300px]"
              />
            ) : error.status === 403 ? (
              <img
                src="/403.svg"
                alt="Access denied"
                className="w-[1060px] h-[1060px] pt-[200px] pr-[300px]"
              />
            ) : (
              <img
                src="/500.svg"
                alt="Server error"
                className="w-[1060px] h-[1060px] pt-5"
              />
            )}
          </div>
        </div>
      </Link>
      <div className="w-full h-full max-w-[1440px] mx-auto">
        <Outlet />
      </div>
    </div>
  );
};
