/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ListView } from './components/ListView';
import { DetailView } from './components/DetailView';

export const Integrations = () => {
  const [page, setPage] = useState('list');
  const [active, setActive] = useState<Integration>();

  const handleChange = (v: Integration) => {
    setActive(v);
    setPage('details');
  };
  return (
    <div className='w-full flex flex-col h-full'>
      {page == 'list' && <ListView change={handleChange} />}
      {page == 'details' && (
        <DetailView active={active} back={() => setPage('list')} />
      )}
    </div>
  );
};
