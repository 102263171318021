import { motion } from 'framer-motion';

export interface PanesProps {
  panes: {
    id: string;
    label: string;
    value?: string | number;
    show?: boolean;
  }[];
  active: string;
  handleChange: (id: string) => void;
}

export const TextPanes = ({ panes, active, handleChange }: PanesProps) => {
  //todo make sure that the setactive prop is passed down as a prop

  return (
    <motion.div key={panes.toString()}>
      <ul className='flex w-full gap-x-5 items-center '>
        {panes
          .filter((pane) => pane.show)
          .map((pane) => (
            <li key={pane.id}>
              <motion.button
                onClick={() => handleChange(pane.id)}
                key={pane.label}
              >
                <div className='flex items-center '>
                  <span
                    style={{ color: active == pane.id ? '#1F87AD' : '#868C98' }}
                    className='transition duration-200 ease-in-out text-sm font-medium'
                  >
                    {pane.label} {pane.value && `(${pane.value})`}
                  </span>
                </div>
              </motion.button>
            </li>
          ))}
      </ul>
    </motion.div>
  );
};
