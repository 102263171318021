/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Outlet, useNavigate } from "react-router-dom";
import { AIChat, Navbar } from "../../components";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "~/queries/profileQueries";
import { openModal, useAuth } from "~/providers";
import toast from "react-hot-toast";
import Loader from "~/components/UI/Loader";
import { getWorkspace } from "~/queries/workspaceQueries";
import { getMyRole, pushNotificationToken } from "~/api";
import { AssistantPrompt } from "~/actions";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
import { getToken } from "firebase/messaging";
import { useEffect, useState } from "react";
import { messaging } from "~/config/firebase";
import { useDeviceId } from "~/hooks";

export const AppLayout = () => {
  const navigate = useNavigate();
  const deviceId = useDeviceId();
  const { setUser, setWorkspace, workspace, setRole } = useAuth();
  const popup = openModal();
  const [isNavVisible] = useState(true);
  const { isLoading: gettingProfile } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      try {
        const data = await getProfile();
        setUser(data);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    ...{
      refetchOnWindowFocus: false,
    },
  });
  const { isLoading: gettingWorkspace, data: myWorkspace } = useQuery({
    queryKey: ["workspace"],
    queryFn: async () => {
      const key = "who_sub";
      try {
        const data = await getWorkspace(new URL(window.origin).hostname);
        localStorage.setItem(key, JSON.stringify(data.subscription));

        if (
          data.assistantEnabled == null &&
          workspace?.subscription?.plan?.name?.includes("pro")
        ) {
          setTimeout(() => popup({ component: <AssistantPrompt /> }), 4000);
        }
        return data;
      } catch (err: any) {
        toast.error(err.message);
        if (!localStorage.getItem(key)) {
          navigate("/");
        }
      }
    },
    ...{
      refetchOnWindowFocus: false,
    },
  });
  const { isLoading: gettingRole, data: myRole } = useQuery({
    queryKey: ["my role"],
    queryFn: async () => {
      const key = "who_r";
      try {
        const data = await getMyRole();
        localStorage.setItem(key, JSON.stringify(data));
        return data;
      } catch (err: any) {
        toast.error(err.message);
        if (!localStorage.getItem(key)) {
          navigate("/");
        }
      }
    },
    ...{
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (myRole) {
      setRole(myRole);
    }
  }, [myRole]);

  useEffect(() => {
    if (myWorkspace) {
      setWorkspace(myWorkspace);
    }
  }, [myWorkspace]);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          getToken(messaging, {
            vapidKey: import.meta.env.VITE_VAPID_KEY,
          }).then((res) => {
            pushNotificationToken(res, deviceId);
          });
        } else {
          console.log("Permission not granted");
        }
      } catch (error) {
        console.error("Error getting permission", error);
      }
    };

    requestPermission();
  }, []);

  return gettingProfile || gettingWorkspace || gettingRole ? (
    <Loader big />
  ) : (
    <div className="w-full h-full flex relative">
      <Navbar show={isNavVisible} />
      <div className="h-full w-full relative bg-[#F7F7F7] overflow-scroll">
        {/* <div className="absolute right-0 top-0 flex justify-end px-8 py-4">
          <button
            onClick={() => setIsNavVisible((prev) => !prev)}
            className="text-end lg:hidden flex flex-col gap-1 w-5"
          >
            <p
              className={`border-b border-[1.5px] w-full rounded-full border-black transition-all duration-200 ${
                isNavVisible ? "rotate-45 translate-y-1 -translate-x-0.5" : ""
              }`}
            ></p>
            <p
              className={`border-b border-[1.5px] w-full rounded-full border-black transition-all duration-200 ${
                isNavVisible ? "-translate-x-2 opacity-0" : "opacity-100"
              }`}
            ></p>
            <p
              className={`border-b border-[1.5px] w-full rounded-full border-black transition-all duration-200 ${
                isNavVisible ? "-rotate-45 -translate-y-2 -translate-x-0.5" : ""
              }`}
            ></p>
          </button>
        </div> */}
        <Outlet />
        {workspace.assistantEnabled &&
          isSubscribedFor("assistant-integration") &&
          isActionAllowed("chat-with-assistant") && <AIChat />}
      </div>
    </div>
  );
};
