import { Group } from '../Group';

export const ProgressBar = ({ progress }: { progress: number }) => {
  const toBeUsed = isNaN(progress) ? 0 : progress;
  return (
    <div className='flex w-full items-center gap-x-2'>
      <Group key='bar'>
        <div className='h-[6px] relative bg-[#E2E4E9] w-full rounded-full'>
          <div
            style={{
              width: `${Math.min(toBeUsed, 100)}%`,
              backgroundColor:
                toBeUsed < 40
                  ? '#DF1C41'
                  : toBeUsed < 75
                  ? '#F17B2C'
                  : toBeUsed > 100
                  ? '#DF1C41'
                  : '#38C793',
            }}
            className='absolute inset-0 rounded-full h-[6px]'
          ></div>
        </div>
      </Group>
      <span className='text-xs text-[#525866] shrink-0'>
        {Number(toBeUsed).toFixed(2)}%
      </span>
    </div>
  );
};
