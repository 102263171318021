/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { addProjectReport } from "~/api";
import { Button, SelectElement } from "~/components";
import { getProjects } from "~/queries/projectQueries";

export const DuplicateReport = ({
  report,
  close,
}: {
  report: ProjectReport;
  close?: () => void;
}) => {
  const [selected, setSelected] = useState(report.projectId);
  const navigate = useNavigate();
  const { data: projects } = useQuery<ListResponse<Project>>({
    queryKey: ["projects", "dashboard"],
    queryFn: async () => {
      try {
        const data = await getProjects({ page: 1, limit: 100 });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      addProjectReport(selected, report),
    ...{
      onSuccess(data: any) {
        navigate(`app/projects/${selected}/reports/${data.data.id}`);
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  return (
    <div className="w-full flex flex-col min-w-[500px] max-h-[50vh]">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1 max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Duplicate report</h4>
          <span className="text-sm text-[#525866]">
            Use an existing report for a new project
          </span>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col flex-1 w-full gap-y-2 h-full">
        <SelectElement
          label="Select the project you want to duplicate into"
          value={selected}
          options={(projects?.data || []).map((p) => ({
            label: p.name,
            value: p.id,
          }))}
          placeholder="Select project"
          name="projectId"
          onChange={(e) => setSelected(e.target.value)}
        />
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        <Button
          effect={mutate}
          label="Proceed"
          disabled={!selected}
          loading={isPending}
        />
      </footer>
    </div>
  );
};
