import { useNavigate } from 'react-router-dom';
import {
  Button,
  Group,
  PasswordHandler,
  PasswordInput,
  TextInput,
} from '~/components';
import { useForm } from '~/hooks';
import { AuthScreen } from '~/screens';
import { getPasswordStrength } from '~/utils/passwordHelpers';

export const AcceptInvitePage = () => {
  const navigate = useNavigate();
  const { formData, update } = useForm({
    initial: { name: '', password: '' },
  });
  return (
    <AuthScreen icon='/auth/together.svg'>
      <div className='flex flex-col w-full'>
        <section className='flex flex-col'>
          <h1 className='text-[#0A0D14] text-[32px] font-medium'>
            Complete your profile
          </h1>
          <span className='text-sm text-[#525866]'>
            We just need a few more things to finish setting up your profile
          </span>
        </section>

        <section className='mt-4 flex flex-col gap-y-4'>
          <TextInput
            name='name'
            value={formData.name}
            label='Full name'
            autoFocus
            placeholder='Enter your full name'
            handleInputChange={update}
          />
          <div className='flex flex-col'>
            <PasswordInput
              name='password'
              value={formData.password}
              label='Password'
              placeholder='• • • • • • • • • • '
              handleInputChange={update}
            />
            <PasswordHandler password={formData.password!} />
          </div>
          <Button
            label='Continue'
            disabled={
              !formData.name ||
              !formData.password ||
              getPasswordStrength(formData.password!).strength < 4
            }
            effect={() => navigate('/')}
          />
        </section>
        <span className='mt-4 block text-center text-sm font-medium'>Or</span>
        <Group key='oauth'>
          <section className='mt-8 flex flex-col w-full gap-y-2'>
            <button className='flex items-center justify-center gap-x-2 py-[10px] px-4 rounded-xl text-sm text-white font-medium bg-[#F14336]'>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.153 8.63672V11.5413H14.2718C14.0909 12.4754 13.5482 13.2663 12.7342 13.7981L15.218 15.6868C16.6651 14.3777 17.5 12.455 17.5 10.1709C17.5 9.63908 17.4513 9.12767 17.3608 8.6368L10.153 8.63672Z'
                  fill='white'
                />
                <path
                  d='M3.32106 6.63867C2.79929 7.64773 2.50015 8.78639 2.50015 10C2.50015 11.2136 2.79929 12.3523 3.32106 13.3613C3.32106 13.3681 5.8675 11.425 5.8675 11.425C5.71444 10.975 5.62397 10.4977 5.62397 9.99993C5.62397 9.50214 5.71444 9.02489 5.8675 8.57489L3.32106 6.63867Z'
                  fill='white'
                />
                <path
                  d='M10.153 5.48638C11.2801 5.48638 12.2819 5.86819 13.082 6.60457L15.2736 4.45685C13.9447 3.24323 12.2194 2.5 10.153 2.5C7.16135 2.5 4.5802 4.1841 3.32092 6.63866L5.86728 8.57504C6.47254 6.80228 8.1632 5.48638 10.153 5.48638Z'
                  fill='white'
                />
                <path
                  d='M5.86399 11.4277L5.30381 11.848L3.32092 13.3616C4.5802 15.8093 7.1612 17.5003 10.1528 17.5003C12.2191 17.5003 13.9515 16.8321 15.2178 15.6866L12.734 13.798C12.0522 14.248 11.1825 14.5207 10.1528 14.5207C8.16304 14.5207 6.47245 13.2048 5.86712 11.4321L5.86399 11.4277Z'
                  fill='white'
                />
              </svg>
              <span>Continue with Google</span>
            </button>
            <button className='flex items-center justify-center gap-x-2 py-[10px] px-4 rounded-xl text-sm text-white font-medium bg-[#2F2F2F]'>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M2.5 2.5H9.64285V9.64285H2.5V2.5Z' fill='white' />
                <path d='M10.3571 2.5H17.5V9.64285H10.3571V2.5Z' fill='white' />
                <path
                  d='M2.5 10.3574H9.64285V17.5003H2.5V10.3574Z'
                  fill='white'
                />
                <path
                  d='M10.3571 10.3574H17.4999V17.5003H10.3571V10.3574Z'
                  fill='white'
                />
              </svg>
              <span>Continue with Microsoft</span>
            </button>
            <button className='flex items-center justify-center gap-x-2 py-[10px] px-4 rounded-xl text-sm text-white font-medium bg-[#0077B5]'>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M15.8333 2.5H4.16667C3.24583 2.5 2.5 3.24583 2.5 4.16667V15.8333C2.5 16.7542 3.24583 17.5 4.16667 17.5H15.8333C16.7542 17.5 17.5 16.7542 17.5 15.8333V4.16667C17.5 3.24583 16.7542 2.5 15.8333 2.5ZM7.03542 15.4167H4.79792V8.19375H7.03542V15.4167ZM5.90625 7.24792C5.175 7.24792 4.58333 6.65208 4.58333 5.91458C4.58333 5.17708 5.175 4.58125 5.90625 4.58125C6.6375 4.58125 7.22917 5.17708 7.22917 5.91458C7.22917 6.65208 6.6375 7.24792 5.90625 7.24792ZM15.4167 15.4167H13.1917V11.625C13.1917 10.5854 12.7958 10.0042 11.975 10.0042C11.0792 10.0042 10.6125 10.6083 10.6125 11.625V15.4167H8.46667V8.19375H10.6125V9.16667C10.6125 9.16667 11.2583 7.97292 12.7896 7.97292C14.3208 7.97292 15.4187 8.90833 15.4187 10.8438V15.4167H15.4167Z'
                  fill='white'
                />
              </svg>
              <span>Continue with LinkedIn</span>
            </button>
          </section>
        </Group>
      </div>
    </AuthScreen>
  );
};
