import { useSearchParams } from "react-router-dom";
import { Group } from "~/components";
import { motion } from "framer-motion";
import { General } from "./setting/general/General";
import { Billings } from "./setting/billings/Billings";
import { Integrations } from "./setting/integrations/Integrations";
import { Members } from "./setting/members/Members";
import { Plans } from "./setting/plans/Plans";
import { Permissions } from "./setting/permissions/Permissions";
import { Profile } from "./setting/profile/Profile";
import { Security } from "./setting/security/Security";
import { Templates } from "./setting/templates/Templates";
import { Notifications } from "./setting/notifications";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
import { useAuth } from "~/providers";
export const Settings = ({ close }: { close?: () => void }) => {
  const { workspace, role } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const setting = searchParams.get("setting") || "profile";
  const accountLinks = [
    {
      path: "profile",
      label: "Profile",
    },
    {
      path: "notifications",
      label: "Notifications",
      show: role?.slug?.toLowerCase() !== "platform-owner",
    },
  ];
  const workspaceLinks = [
    {
      path: "general",
      label: workspace?.slug === "admin" ? "Workspace" : "General",
      show:
        (isActionAllowed("update-workspace") && isSubscribedFor("workspace")) ||
        workspace?.slug === "admin",
    },
    {
      path: "security",
      label: "General Settings",
      show: workspace?.slug == "admin",
    },

    {
      path: "roles",
      label: "Roles and Permissions",
      show:
        (isActionAllowed("create-role") && isSubscribedFor("teams")) ||
        workspace?.slug === "admin",
    },
    {
      path: "members",
      label: "Members",
      show:
        (isActionAllowed("add-team-member") && isSubscribedFor("teams")) ||
        workspace?.slug === "admin",
    },
    {
      path: "templates",
      label: "Templates",
      // show: role?.slug?.toLowerCase() === "platform-owner",
      show: false,
    },
    {
      path: "integrations",
      label: "Integrations",
      show:
        isActionAllowed("list-integrations") &&
        workspace?.slug !== "admin" &&
        isSubscribedFor("tools-integration"),
    },
    {
      path: "plans",
      label: "Plans",
      show:
        isActionAllowed("subscribe-to-subscription-plan") &&
        workspace?.slug !== "admin",
    },
    {
      path: "billings",
      label: "Billings",
      show:
        isActionAllowed("subscribe-to-subscription-plan") &&
        workspace?.slug !== "admin",
    },
  ];
  const navigate = (setting: string) => {
    setSearchParams(() => {
      searchParams.set("setting", setting);
      return searchParams;
    });
  };
  const handleClose = () => {
    close?.();
    setSearchParams(new URLSearchParams());
  };
  return (
    <div className="w-screen h-screen flex ">
      <Group key="nav">
        <div className="w-[216px] shrink-0 border-r px-3 py-8 h-full flex flex-col">
          <Group key="account links">
            <button
              onMouseDown={handleClose}
              className="flex gap-x-2 items-center"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.871 9.24952H16V10.7495H6.871L10.894 14.7725L9.8335 15.833L4 9.99952L9.8335 4.16602L10.894 5.22652L6.871 9.24952Z"
                  fill="#525866"
                />
              </svg>
              <span className="text-sm font-medium text-[#525866]">
                Back to Workspace
              </span>
            </button>
            <div className="flex flex-col gap-y-[6px] w-full mt-5">
              <span className="uppercase text-xs font-medium text-[#868C98]">
                account
              </span>
              <div className="flex flex-col relative gap-y-1 w-full">
                {accountLinks
                  .filter((link) => link.show !== false)
                  .map((link) => (
                    <div
                      key={link.label}
                      className="relative flex items-center w-full "
                    >
                      {setting == link.path && (
                        <motion.div
                          style={{ top: "25%" }}
                          layoutId="settingstool"
                          className="absolute left-[-12px]"
                        >
                          <svg
                            width="4"
                            height="20"
                            viewBox="0 0 4 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 0C2.20914 0 4 1.79086 4 4V16C4 18.2091 2.20914 20 0 20V0Z"
                              fill="#5FD7F4"
                            />
                          </svg>
                        </motion.div>
                      )}
                      <button
                        style={{
                          background:
                            setting == link.path ? "#F1FBFE" : undefined,
                          color: setting == link.path ? "#0A0D14" : "#525866",
                          fill: setting == link.path ? "#39c9ee" : "#525866",
                        }}
                        className="flex w-full items-center gap-x-2 px-2 font-medium py-3 rounded-lg text-sm hover:bg-[#F1FBFE] hover:fill-[#39c9ee] transition duration-200 ease-in-out"
                        onClick={() => navigate(link.path)}
                      >
                        {link.label}
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </Group>
          {role.slug !== "project-team-member" && (
            <Group key="workspace links">
              <div className="flex flex-col gap-y-[6px] w-full mt-5">
                <span className="uppercase text-xs font-medium text-[#868C98]">
                  workspace
                </span>
                <div className="flex flex-col relative gap-y-1 w-full">
                  {workspaceLinks
                    .filter((link) => link.show)
                    .map((link) => (
                      <div key={link.label} className="relative ">
                        {setting == link.path && (
                          <motion.div
                            style={{ top: "25%" }}
                            layoutId="settingstool"
                            className="absolute left-[-12px]"
                          >
                            <svg
                              width="4"
                              height="20"
                              viewBox="0 0 4 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 0C2.20914 0 4 1.79086 4 4V16C4 18.2091 2.20914 20 0 20V0Z"
                                fill="#5FD7F4"
                              />
                            </svg>
                          </motion.div>
                        )}
                        <button
                          style={{
                            background:
                              setting == link.path ? "#F1FBFE" : undefined,
                            color: setting == link.path ? "#0A0D14" : "#525866",
                            fill: setting == link.path ? "#39c9ee" : "#525866",
                          }}
                          className="flex w-full items-center gap-x-2 px-2 font-medium py-3 rounded-lg text-sm hover:bg-[#F1FBFE] hover:fill-[#39c9ee] transition duration-200 ease-in-out"
                          onClick={() => navigate(link.path)}
                        >
                          {link.label}
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </Group>
          )}
        </div>
      </Group>
      <div className="w-full bg-[#F7F7F7] p-8 overflow-scroll">
        {setting == "general" && <General />}
        {setting == "security" && <Security />}
        {setting == "billings" && <Billings move={navigate} />}
        {setting == "integrations" && <Integrations />}
        {setting == "notifications" && <Notifications />}
        {setting == "members" && <Members />}
        {setting == "plans" && <Plans />}
        {setting == "roles" && <Permissions />}
        {setting == "profile" && <Profile />}
        {setting == "templates" && <Templates />}
      </div>
    </div>
  );
};
