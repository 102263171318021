import React, { useRef, useEffect } from 'react';
interface OtpInputProps {
  length: number;
  updateValue: (v: string) => void;
  finish?: () => void;
  loading?: boolean;
}

export const OtpInput = ({
  length,
  updateValue,
  finish,
  loading,
}: OtpInputProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    const handleKeyDown = (index: number, e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        const input = e.target as HTMLInputElement;
        if (input.value === '' && index > 0) {
          const prevIndex = index - 1;
          inputRefs.current[prevIndex]?.focus();
        }
      }
    };

    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const pasteData = e.clipboardData?.getData('text') || '';
      const otpArray = pasteData.split('').slice(0, length);
      otpArray.forEach((value, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = value;
          handleInputChange(index, { target: inputRefs.current[index] });
        }
      });
    };

    inputRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.addEventListener('keydown', (e) => handleKeyDown(index, e));
      }
    });

    document.addEventListener('paste', handlePaste);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputRefs.current.forEach((ref, index) => {
        if (ref) {
          ref.removeEventListener('keydown', (e) => handleKeyDown(index, e));
        }
      });

      document.removeEventListener('paste', handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRefs, length]);

  const handleInputChange = (
    index: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: React.ChangeEvent<HTMLInputElement> | { target: any }
  ) => {
    const input = e.target;
    if (isNaN(Number(e.target.value))) {
      // Ignore non-numeric input when type is "number"
      return;
    }
    if (input.value.length >= input.maxLength) {
      const nextIndex = index + 1;
      const otpValues = inputRefs.current.map((ref) => ref.value).join('');
      updateValue(otpValues);
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex]?.focus();
      } else {
        finish?.();
      }
    }
  };

  return (
    <div className='flex flex-col gap-y-2'>
      <div className='flex items-center gap-x-2'>
        <label className='text-[#263238] text-sm font-medium shrink-0'>
          Authentication code
        </label>
        {loading && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            style={{
              // background: 'transparent',
              display: 'block',
              shapeRendering: 'auto',
            }}
            width={'25px'}
            height={'25px'}
            viewBox='0 0 100 100'
            preserveAspectRatio='xMidYMid'
          >
            <circle
              cx='50'
              cy='50'
              fill='none'
              stroke={'#0F4B93'}
              strokeWidth='12.5'
              r='35'
              strokeDasharray='164.93361431346415 56.97787143782138'
            >
              <animateTransform
                attributeName='transform'
                type='rotate'
                repeatCount='indefinite'
                dur='1s'
                values='0 50 50;360 50 50'
                keyTimes='0;1'
              ></animateTransform>
            </circle>
            {/* [ldio] generated by https://loading.io/*/}
          </svg>
        )}
      </div>
      <div className='flex items-center gap-x-2 '>
        <>
          {Array.from({ length: length }, (_, index) => (
            <>
              <input
                className='h-[64px] w-[60px] text-center border text-2xl font-medium border-[#BDBDBD] shadow-sm focus:border-primary transition-all duration-200 rounded-xl text-black'
                key={index}
                autoFocus={index == 0}
                ref={(el) => (inputRefs.current[index] = el!)}
                type='tel'
                maxLength={1}
                onChange={(e) => handleInputChange(index, e)}
              />{' '}
            </>
          ))}
        </>
      </div>
    </div>
  );
};
