/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { authorizeProjectIntegrations } from "~/api";
import Loader from "~/components/UI/Loader";
import { AuthScreen } from "~/screens";

export const CloseIntegrationPage = () => {
  const qc = useQueryClient();
  const { integration } = useParams();
  const searchParams = useSearchParams()[0];
  const code = searchParams.get("code");

  const { isLoading } = useQuery({
    queryKey: ["project", "integrations", "success"],
    queryFn: async () => {
      try {
        const data = await authorizeProjectIntegrations(integration!, { code });
        qc.invalidateQueries({ queryKey: ["integrations", "connections"] });
        setTimeout(() => {
          window.close();
        }, 2000);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  return (
    <AuthScreen icon="/auth/mail.svg">
      {isLoading ? (
        <Loader big />
      ) : (
        <div className="flex flex-col w-full">
          <section className="flex flex-col">
            <h1 className="text-[#0A0D14] text-[32px] font-medium">
              Integration Successful
            </h1>
            <span className="text-sm text-[#525866]">
              Your app has been successfully authorized, this page will close in
              a few seconds. If it doesn't you can close it yourself
            </span>
          </section>
        </div>
      )}
    </AuthScreen>
  );
};
