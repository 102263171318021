/* eslint-disable @typescript-eslint/no-explicit-any */
export const SearchDropdown = ({
  label,
  name,
  placeholder,
  options,
  value,
  update,
  effect,
}: {
  label: string;
  placeholder?: string;
  name: string;
  value?: any;
  options: {
    value: string | number | any;
    label: string;
  }[];
  update: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  effect?: (e: any) => void;
}) => {
  const handleChange = (e: any) => {
    update(e);
    effect?.(e);
  };
  return (
    <div className="flex items-center py-[6px] px-2 sm:w-auto w-full border border-[#E2E4E9] rounded-lg text-sm text-[#525866]">
      <span>{label}:</span>
      <select
        onChange={handleChange}
        className="text-sm outline-none flex-grow"
        name={name}
        id={name}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option) => (
          <option
            value={option.value}
            key={option.value}
            selected={option.value.toString() == value?.toString()}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
