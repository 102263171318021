import { ReactNode } from 'react';

export const SettingContainer = ({
  title,
  subtext,
  children,
}: {
  title: string;
  subtext?: string;
  children: ReactNode;
}) => {
  return (
    <div className='border-b py-5 flex w-full items-center justify-between gap-x-5'>
      <div className='flex flex-col gap-y-[1px] text-sm max-w-[34%]'>
        <h4 className='font-medium text-[#0A0D14]'>{title}</h4>
        {subtext && <span className='text-[#525866]'>{subtext}</span>}
      </div>
      {children}
    </div>
  );
};
