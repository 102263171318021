import { AnimatePresence, motion } from 'framer-motion';

import './styles.css';

interface ToggleProps {
  isOn: boolean;
  id?: string;
  effect: () => void;
}

export const Toggle = ({ isOn, effect }: ToggleProps) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <AnimatePresence initial={false}>
      <motion.button
        className='toggle-container'
        type='button'
        onClick={effect}
        animate={{ backgroundColor: isOn ? undefined : '#BDBDBD' }}
      >
        <motion.div
          className='toggle-switch'
          animate={{
            x: isOn ? 10 : -1,
          }}
          transition={spring}
        />
      </motion.button>
    </AnimatePresence>
  );
};
