/* eslint-disable @typescript-eslint/no-explicit-any */

import toast from "react-hot-toast";

/* eslint-disable prefer-const */
export const getPaddedDate = (d: { startDate: string; endDate: string }) => {
  const newStartDate = d.startDate
    ? d.startDate
        .split("-")
        .map((d) => d.padStart(2, "0"))
        .join("-")
    : "";
  const newEndDate = d.endDate
    ? d.endDate
        .split("-")
        .map((d) => d.padStart(2, "0"))
        .join("-")
    : "";

  return {
    from: newStartDate,
    to: newEndDate,
  };
};

export const hasEmptyValues = (obj: any) => {
  for (let key in obj) {
    if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      return true;
    }
  }
  return false;
};

export const areObjectsEqual = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    obj1 == null ||
    typeof obj1 !== "object" ||
    obj2 == null ||
    typeof obj2 !== "object"
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const arraysEqual = (arr1: any[], arr2: any[]) => {
  if (arr1 === arr2) {
    return true;
  }

  if (arr1 == null || arr2 == null) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
      if (!arraysEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {
      if (!areObjectsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const handleError = (error: any) => {
  if (error.validationErrors) {
    // Handle validation errors
    toast.error("Validation Error");
    // Optionally set validation errors in state
    return { type: "validation", messages: error.validationErrors };
  } else {
    // Handle general errors
    toast.error(error.message);
    return { type: "general", message: error.message };
  }
};

export const getTotalCount = (array: any[]) => {
  return array?.reduce((total, obj) => {
    return total + parseInt(obj?.count, 10);
  }, 0);
};

export const getStat = (key: string, stats: any) => {
  if (!stats) return "";
  const [stat] = stats.filter((st: any) => st.value == key);
  return stat?.count || "0";
};

export const totoPascalCase = (word: string | undefined | null): string => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export type FormData = {
  [key: string]: any;
};
