import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Button, PhoneTextInput, TextInput } from '~/components';
import { useForm } from '~/hooks';
import { createClient } from '~/mutations/clientMutations';
import { handleError, hasEmptyValues } from '~/utils/helpers';

export const CreateClientPage = () => {
  const navigate = useNavigate();
  const qc = useQueryClient();
  const {
    formData: primary,
    update,
    setErrors,
    errors,
  } = useForm({
    initial: {
      name: '',
      email: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
    },
  });
  const { formData: secondary, update: updateSecondary } = useForm({
    initial: {
      contactName2: '',
      contactEmail2: '',
      contactPhone2: '',
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      createClient({
        ...primary,
        contactPhone: `+${primary.contactPhone}`,
        ...(secondary.contactPhone2 && {
          contactPhone2: `+${secondary.contactPhone2}`,
        }),
        ...(secondary.contactEmail2 && {
          contactEmail2: secondary.contactEmail2,
        }),
        ...(secondary.contactName2 && {
          contactName2: secondary.contactName2,
        }),
      }),
    ...{
      onSuccess() {
        toast.success(`${primary.name} created succesfully`);
        qc.invalidateQueries({ queryKey: ['clients'] });
        navigate('/app/clients');
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === 'validation') {
          setErrors(handledError.messages);
        }
      },
    },
  });

  return (
    <div className='flex flex-col w-full text-[#0A0D14] oveflow-clip px-8 pt-10 pb-11 h-full'>
      <div className='flex items-center w-full justify-between remove-scroll'>
        <button
          onClick={() => navigate(-1)}
          className='flex items-center gap-x-2 text-[#0A0D14] font-medium'
        >
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z'
              fill='#525866'
            />
          </svg>
          <span>New client</span>
        </button>
        <div className='flex items-center gap-x-2'>
          <Button mono label='Cancel' effect={() => navigate(-1)} />
          <Button
            label='Add client'
            effect={mutate}
            disabled={hasEmptyValues(primary)}
            loading={isPending}
          />
        </div>
      </div>
      <div className='flex flex-col items-center w-full overflow-scroll remove-scroll'>
        <div className='flex flex-col gap-y-8 w-full items-center mt-6 max-w-[512px] mx-auto'>
          <div className='flex flex-col gap-y-5 w-full'>
            <h3 className='font-medium'>Company Details</h3>
            <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5'>
              <TextInput
                name='name'
                label='Company Name'
                required
                value={primary.name}
                placeholder='Enter company name'
                autoFocus
                error={errors.name}
                handleInputChange={update}
              />
              <TextInput
                value={primary.email}
                name='email'
                type='email'
                error={errors.email}
                label='Official Email'
                required
                placeholder='support@acme.com'
                handleInputChange={update}
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-5 w-full'>
            <h3 className='font-medium'>Primary contact details</h3>
            <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5'>
              <TextInput
                value={primary.contactName}
                required
                name='contactName'
                error={errors.contactName}
                label='Full Name'
                placeholder='Enter full name'
                handleInputChange={update}
              />
              <TextInput
                value={primary.contactEmail}
                required
                name='contactEmail'
                type='email'
                error={errors.contactEmail}
                label='Email Address'
                placeholder='Enter email address'
                handleInputChange={update}
              />
              <PhoneTextInput
                value={primary.contactName}
                name='contactPhone'
                error={errors.contactPhone}
                onChange={update}
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-5 w-full'>
            <h3 className='font-medium'>
              Secondary contact details (optional)
            </h3>
            <div className='p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5'>
              <TextInput
                value={secondary.contactName2}
                name='contactName2'
                label='Full Name'
                placeholder='Enter full name'
                handleInputChange={updateSecondary}
              />
              <TextInput
                value={secondary.contactEmail2}
                name='contactEmail2'
                label='Email Address'
                placeholder='Enter email address'
                handleInputChange={updateSecondary}
              />
              <PhoneTextInput
                value={secondary.contactPhone2}
                name='contactPhone2'
                onChange={updateSecondary}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
