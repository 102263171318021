/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Header,
  Kebab,
  Pagination,
  Pill,
  SearchBar,
  SearchDropdown,
  Table,
  TextPanes,
} from "~/components";
import { useForm } from "~/hooks";
import { openModal } from "~/providers";
import { ActivateMember } from "./Activate";
import { DeactivateMember } from "./Deactivate";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "~/components/UI/Loader";
import { getUsers } from "~/queries/userQueries";
import toast from "react-hot-toast";
import { addUser } from "~/mutations/userMutations";
import { useRoles } from "~/hooks/useLists";
import { ColumnDef } from "@tanstack/react-table";
import { TableAvatar } from "~/components/UI/TableAvatar";
import { FormEvent, useState } from "react";
import { DeleteMember } from "./Delete";
import { ReinviteMember } from "./Reinvite";
import { UpdateRole } from "./UpdateRole";
import { getUserStats } from "~/api";
import { isActionAllowed } from "~/utils/authHelper";

export const Members = () => {
  const popup = openModal();
  const qc = useQueryClient();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { list = [] } = useRoles();
  const { formData, update, reset } = useForm({
    initial: { email: "", roleId: "" },
  });
  const { formData: filters, update: updateFilter } = useForm({
    initial: { roleId: "" },
  });
  const [active, setActive] = useState("");

  const { mutate, isPending } = useMutation({
    mutationFn: () => addUser({ ...formData }),
    ...{
      onSuccess() {
        toast.success("User Sucessfully invited");
        reset();
        qc.invalidateQueries({ queryKey: ["members"] });
      },
      onError(err) {
        toast.error(err.message);
      },
    },
  });
  const { data: members, isLoading } = useQuery({
    enabled: isActionAllowed("list-team-members"),
    queryKey: ["members", { active, role: filters.roleId, search, page }],
    queryFn: async () => {
      try {
        const data = await getUsers({
          "filter[name]": search,
          "filter[status]": active,
          "filter[roleId]": filters.roleId,
          page,
          limit: 10,
        });
        return data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
  });
  const { data: stats, isLoading: statsLoading } = useQuery({
    enabled: isActionAllowed("list-team-members"),
    queryKey: ["members", "stats"],
    queryFn: async () => {
      try {
        const data = await getUserStats();
        return data.data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
  });

  const getStat = (key: string) => {
    if (!stats) return "";
    const [stat] = stats?.filter((st: any) => st.value == key);
    return stat?.count || 0;
  };

  const getTotalCount = (array: any[]) => {
    return array?.reduce((total, obj) => {
      return total + parseInt(obj?.count, 10);
    }, 0);
  };
  const panes = [
    {
      id: "",
      label: "All",
      show: true,
      value: getTotalCount(stats),
    },
    { id: "active", label: "Active", show: true, value: getStat("active") },
    { id: "pending", label: "Pending", show: true, value: getStat("pending") },
    {
      id: "inactive",
      label: "Inactive",
      show: true,
      value: getStat("inactive"),
    },
  ];

  const handleUpdate = (
    action: "active" | "deactivate" | "delete" | "resend" | "role",
    data: any
  ) => {
    if (action == "active")
      return popup({ component: <ActivateMember data={data} /> });
    if (action == "deactivate")
      return popup({ component: <DeactivateMember data={data} /> });
    if (action == "delete")
      return popup({ component: <DeleteMember data={data} /> });
    if (action == "resend")
      return popup({ component: <ReinviteMember data={data} /> });
    if (action == "role")
      return popup({ component: <UpdateRole data={data} /> });
  };

  const colums: ColumnDef<User>[] = [
    {
      id: "name",
      cell: ({ row }) => (
        <TableAvatar
          first_name={row.original.profile.firstName}
          last_name={row.original.profile.lastName}
        />
      ),
      header: () => <span>Member Name</span>,
      size: 31,
    },
    {
      id: "email",
      cell: ({ row }) => (
        <span className="text-left ">{row.original.email}</span>
      ),
      header: () => <span>Email Address</span>,
      size: 31,
    },
    {
      id: "role",
      cell: ({ row }) => (
        <span className="capitalize">{row.original.role?.name}</span>
      ),
      header: () => <span>Role</span>,
      size: 17,
    },
    {
      id: "stattus",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 14,
    },
    {
      id: "action",
      cell: ({ row }) => (
        <Kebab>
          <div className="min-w-[168px] w-full flex flex-col items-start">
            {row.original.status !== "pending" && (
              <button
                onClick={() => handleUpdate("role", row.original)}
                className="kebab-button"
              >
                Update Role
              </button>
            )}
            {row.original.status == "pending" && (
              <button
                onClick={() => handleUpdate("resend", row.original)}
                className="kebab-button"
              >
                Resend Invite
              </button>
            )}
            {row.original.status == "inactive" && (
              <button
                className="kebab-button"
                onClick={() => handleUpdate("active", row.original)}
              >
                Activate
              </button>
            )}
            {row.original.status == "active" && (
              <button
                onClick={() => handleUpdate("deactivate", row.original)}
                className="kebab-button"
              >
                Deactivate
              </button>
            )}
            <button
              onClick={() => handleUpdate("delete", row.original)}
              className="kebab-button"
            >
              Remove
            </button>
          </div>
        </Kebab>
      ),
      header: () => <span> </span>,
      size: 8,
    },
  ];
  const handleAdd = (e: FormEvent) => {
    e.preventDefault();
    mutate();
  };
  return statsLoading ? (
    <Loader big />
  ) : (
    <div className="w-full flex flex-col h-full">
      <Header
        title="Members"
        subtext="Manage your team members and permissions."
      />
      <div className="rounded-xl border border-[#E2E4E9] bg-white p-4 flex flex-col w-full mt-6">
        <h4 className="font-medium text-sm text-[#0A0D14]">Invite Member</h4>
        <form onSubmit={handleAdd} className="flex items-center gap-x-2  mt-4">
          <div className="bg-transparent border  w-full p-2 rounded-xl flex items-center justify-between">
            <input
              placeholder="Email"
              className="text-sm outline-none w-full border-r  border-[#E2E4E9]"
              value={formData.email}
              name="email"
              type="email"
              required
              onChange={update}
            />
            <select
              className="outline-none text-sm shrink-0 ml-3"
              name="roleId"
              required
              onChange={update}
            >
              <option value="">Select role</option>
              {list?.map((option) => (
                <option
                  value={option.value}
                  key={option.value}
                  selected={option.value.toString() == formData.roleId}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="shrink-0 h-fit">
            <Button
              label="Send invite"
              type="submit"
              disabled={!formData.email}
              loading={isPending}
            />
          </div>
        </form>
      </div>
      <div className="flex flex-col mt-6 w-full">
        <TextPanes
          panes={panes}
          active={active}
          handleChange={(id) => {
            setActive(id);
            setPage(1);
          }}
        />
        <div className="mt-6 bg-white w-full h-full rounded-xl flex flex-col p-4 gap-y-5">
          <div className="flex items-center gap-x-2">
            {" "}
            <SearchBar
              placeholder="Search..."
              initialValue={search}
              onClear={() => setSearch("")}
              onSearch={(v: string) => setSearch(v)}
            />
            <SearchDropdown
              label="Role"
              name="roleId"
              update={updateFilter}
              options={list}
              placeholder="All"
              value={filters.roleId}
            />
          </div>
          {isLoading ? (
            <div className="py-10">
              <Loader big />
            </div>
          ) : members?.data.length > 0 ? (
            <Table key="roles" cols={colums} rawData={members.data} />
          ) : (
            <div className="mt-6 min-h-[400px] w-full h-full rounded-xl flex flex-col items-center justify-center  text-center ">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No members to show
                  </span>
                  {isActionAllowed("add-team-member") && (
                    <span className=" text-sm text-[#525866]">
                      You have not added any members. Invite a new member to get
                      started
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {members?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={10}
            pages={members?.meta?.totalNumberOfPages}
            total={members?.meta?.totalNumberOfRecords}
            handlePageChange={(e) => {
              setPage(e.selected + 1);
            }}
          />
        )}
      </div>
    </div>
  );
};
