/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Button, OtpInput } from '~/components';
import { twoFaLogin } from '~/mutations/authMutations';
import { useAuth } from '~/providers';

export const VerifyCode = ({
  close,
  token,
}: {
  close?: () => void;
  token: string;
}) => {
  const [code, setCode] = useState('');
  const { setStoredToken } = useAuth();

  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async () => twoFaLogin({ code, token }),
    ...{
      onSuccess(data) {
        setStoredToken(data);
        navigate('/choose');
        close?.();
      },
      onError(error: any) {
        toast.error(error.message);
      },
    },
  });

  return (
    <div className='w-full flex flex-col'>
      <header className='flex items-start w-full justify-between border-b py-4 px-5'>
        <div className='flex flex-col gap-y-1'>
          <h4 className='text-[#0A0D14] font-medium'>
            Two-factor authentication
          </h4>
          <span className='text-sm text-[#525866]'>Verify your login</span>
        </div>
        <button onClick={close}>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z'
              fill='#525866'
            />
          </svg>
        </button>
      </header>
      <section className='p-5 flex flex-col w-full gap-y-5'>
        <p className='text-[#525866] text-sm max-w-[400px]'>
          You have two-factor authentication enabled on your account for
          bolstered security.
          <br /> <br />
          Open the authenticator app on your device to access the 6-digit code.
        </p>
        <OtpInput updateValue={setCode} length={6} finish={mutate} />
      </section>
      <footer className='px-5 py-4 border-t flex justify-end items-center gap-x-3'>
        <Button mono label='Cancel' effect={close} />
        <Button
          label='Authenticate'
          effect={mutate}
          loading={isPending}
          disabled={!code}
        />
      </footer>
    </div>
  );
};
