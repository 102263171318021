interface TableAvatarProps {
  img_src?: string;
  first_name: string;
  last_name?: string | undefined;
}
export const TableAvatar = ({
  img_src,
  first_name,
  last_name,
}: TableAvatarProps) => {
  return (
    <div
      className=' gap-x-3 flex items-center rounded-full text-base'
      title={`${first_name} ${last_name}`}
    >
      <div className='w-8 h-8 rounded-full flex items-center justify-center bg-[#E7E4F9] shrink-0'>
        {img_src ? (
          <img src={img_src} className='object-cover  w-6 h-6 rounded-full' />
        ) : (
          <span className='text-[#524988] text-sm'>
            {first_name[0]}
            {last_name?.[0]}
          </span>
        )}
      </div>

      <h1 className='text-[#0A0D14] text-sm'>{`${first_name} ${
        last_name || ''
      }`}</h1>
    </div>
  );
};
