/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSearchParams } from "react-router-dom";
import {
  Button,
  GettingStarted,
  Pagination,
  PanesProps,
  SearchBar,
  TextPanes,
} from "~/components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Loader from "~/components/UI/Loader";
import { getNotifications, readNotifications } from "~/api";
import { usePagination, usePanes } from "~/hooks";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const NotificationsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("show") || "";
  const search = searchParams.get("search") || "";
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;

  const qc = useQueryClient();
  const { handlePageChange } = usePagination();

  const { show, handlePaneSwitch } = usePanes(status);

  const { data: notifications, isLoading } = useQuery<
    ListResponse<UserNotification>
  >({
    queryKey: ["dashboard notifications", { page, limit, search }],
    queryFn: async () => {
      try {
        const data = await getNotifications({
          page,
          limit,
          "filter[title]": search,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (notifications: string[]) => readNotifications(notifications),
    ...{
      onSuccess() {
        qc.invalidateQueries({ queryKey: ["dashboard notifications"] });
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  // const { data: notificationStats, isLoading: statsLoading } = useQuery({
  //   queryKey: ["dashboard notifications", "stats"],
  //   queryFn: async () => {
  //     try {
  //       const data = await getNotificationStats();
  //       return data.data;
  //     } catch (err: any) {
  //       toast.error(err.message);
  //     }
  //   },
  // });

  // const getStat = (key: string) => {
  //   if (!notificationStats) return "";
  //   const [stat] = notificationStats?.filter((st: any) => st.value == key) || [];
  //   return stat?.count || "0";
  // };

  const panes: PanesProps["panes"] = [
    // {
    //   id: "",
    //   label: "All",
    //   show: true,
    //   value: Number(getStat("read")) + Number(getStat("unread")),
    // },
    // { id: "read", label: "Read", show: true, value: getStat("read") },
    // { id: "unread", label: "Unread", show: true, value: getStat("unread") },
  ];

  const searchFunction = (v: string) => {
    setSearchParams(() => ({
      search: v!,
      show: show!,
    }));
  };
  const clearFunction = () => {
    setSearchParams(new URLSearchParams());
  };

  return (
    <div className="flex flex-col w-full h-full p-8  ">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-lg text-[#0A0D14] font-medium">Inbox</h1>
          <p className="text-sm text-[#525866]">
            Here are the notifications on your account.
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-x-5 h-full gap-y-10">
        <div className="flex flex-col mt-5 w-full h-full min-h-[400px]">
          <TextPanes
            panes={panes}
            active={show}
            handleChange={handlePaneSwitch}
          />
          <div className="bg-white border rounded-xl border-[#E2E4E9] shadow-sm w-full">
            <div className=" w-full h-full min-h-[400px]">
              <div className="flex items-center justify-between gap-x-2 p-3">
                {" "}
                <SearchBar
                  placeholder={`Search by title`}
                  initialValue={search}
                  onClear={clearFunction}
                  onSearch={searchFunction}
                />
                <Button
                  mono
                  label="Mark all as read"
                  disabled={isPending}
                  effect={() => {
                    const unreads =
                      notifications?.data
                        ?.filter((n) => !n.read)
                        .map((n) => {
                          n.read = true;
                          return n.id;
                        }) || [];
                    if (unreads.length) {
                      mutate(unreads);
                    }
                  }}
                />
              </div>
              {isLoading ? (
                <Loader big />
              ) : notifications && notifications?.data?.length > 0 ? (
                <div className="flex flex-col w-full h-full pb-10">
                  {notifications?.data.map((n, i) => (
                    <div
                      key={n.id}
                      className={`flex items-center px-[14px] py-5 gap-x-[14px] cursor-pointer ${
                        n?.read ? "" : "bg-[#F5FDFE]"
                      }`}
                      onClick={() => {
                        notifications.data[i].read = true;
                        mutate([n.id]);
                      }}
                    >
                      <span
                        className={`size-[6px] rounded-full flex-shrink-0 ${
                          n?.read ? "bg-transparent" : "bg-[#53C2DA]"
                        }`}
                      ></span>
                      <div className="w-10 h-10 rounded-lg bg-[#E255F2] flex items-center justify-center shrink-0 text-white font-medium uppercase  ">
                        <>
                          {n?.details?.workspaceName?.[0]}
                          {n?.details?.workspaceName?.split(" ")?.[1]?.[0]}
                        </>
                      </div>
                      <div className="w-full flex items-center justify-between">
                        <div className="flex flex-col gap-y-1">
                          <span className="text-sm text-[#0A0D14] font-semibold">
                            {n.title}
                          </span>
                          <span
                            className="text-sm text-[#0A0D14]"
                            dangerouslySetInnerHTML={{ __html: n.body }}
                          />
                        </div>
                        <div className="flex items-center gap-3">
                          <p className="text-[#525866] text-xs">
                            {dayjs().to(dayjs(n.createdAt))}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}{" "}
                </div>
              ) : (
                <div className="max-w-[370px] w-full h-full mx-auto flex flex-col justify-center items-center gap-y-5 ">
                  <img src="/empty.svg" className="w-[100px]" />
                  <div className="flex flex-col items-center gap-y-2">
                    <span className=" text-sm text-[#0A0D14]">
                      You're all caught up
                    </span>
                    <span className="max-w-[256px] text-sm text-[#525866]">
                      You don't have any new notifications
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {notifications && notifications?.meta?.totalNumberOfRecords > 10 && (
            <Pagination
              page={page}
              limit={limit}
              pages={notifications?.meta?.totalNumberOfPages}
              total={notifications?.meta?.totalNumberOfRecords}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </div>
      <GettingStarted />
    </div>
  );
};
