import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Button } from "~/components";
import { useForm } from "~/hooks";
import { useRoles } from "~/hooks/useLists";
import { addUser } from "~/mutations/userMutations";

export const InviteMember = ({ close }: { close?: (id?: string) => void }) => {
  const qc = useQueryClient();
  const { data: roles = [] } = useRoles();

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      addUser({
        ...formData,
        roleId: roles.find((r: Role) => r.slug === "project-team-member")?.id,
      }),
    ...{
      onSuccess(data: any) {
        toast.success("User Sucessfully invited");
        reset();
        qc.invalidateQueries({ queryKey: ["users", "list"] });
        qc.invalidateQueries({ queryKey: ["members"] });
        close?.(data.data.id);
      },
      onError(err) {
        toast.error(err.message);
      },
    },
  });

  const { formData, update, reset } = useForm({
    initial: { email: "" },
  });

  return (
    <div className="w-full flex flex-col">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <h4 className="text-[#0A0D14] font-medium">Invite Member</h4>{" "}
        <button onClick={() => close?.()}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5 max-w-[440px]">
        <div className="flex items-center gap-x-2">
          <div className="bg-transparent border  w-full p-2 rounded-xl flex items-center justify-between">
            <input
              placeholder="Email"
              className="text-sm outline-none w-full"
              value={formData.email}
              name="email"
              type="email"
              required
              onChange={update}
            />
          </div>
        </div>
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        <Button
          label="Send invite"
          type="submit"
          disabled={!formData.email}
          loading={isPending}
          effect={mutate}
        />
      </footer>
    </div>
  );
};
