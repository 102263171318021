/* eslint-disable prefer-const */
import { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ExpandableButton,
  Header,
  Pill,
  Table,
  SearchBar,
  TextPanes,
  Pagination,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { TableAvatar } from "~/components/UI/TableAvatar";
import toast from "react-hot-toast";
import { usePanes, usePagination } from "~/hooks";
import {
  adminGetWorkspaceStats,
  adminGetWorkspaces,
} from "~/queries/workspaceQueries";
import { getTotalCount, getStat, totoPascalCase } from "~/utils/helpers";
import { exportWorkspaces } from "~/mutations/workspaceMutations";
import { PDFDocument } from "pdf-lib";
import { getFormattedDate } from "~/utils/dateUtils";

export const OrganizationsPage = () => {
  const navigate = useNavigate();
  const [_search, setSearch] = useState("");
  const { handlePageChange } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("show") || "";
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "10";
  const { show, handlePaneSwitch } = usePanes(status);

  const handleSearch = (value: string) => {
    setSearch(value);
    // Reset page to 1 when searching
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("search", value);
      return prev;
    });
  };

  const handleClearSearch = () => {
    setSearch("");
    // Reset page to 1 and remove search param when clearing
    setSearchParams((prev) => {
      prev.delete("search");
      prev.set("page", "1");
      return prev;
    });
  };

  const { data: organisations, isLoading } = useQuery({
    queryKey: [
      "organisations",
      { page, limit, status, search: searchParams.get("search") || "" },
    ],
    queryFn: async () => {
      try {
        const data = await adminGetWorkspaces({
          page,
          limit,
          "filter[status]": status,
          "filter[name]": searchParams.get("search") || "",
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { data: workspaceStats, isLoading: statsLoading } = useQuery({
    queryKey: ["workspaces", "stats"],
    queryFn: async () => {
      try {
        const data = await adminGetWorkspaceStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const exportMutation = useMutation({
    mutationFn: (format: "csv" | "pdf" | "xlsx") =>
      exportWorkspaces({ format }),
    onSuccess: async (data: any, format: "csv" | "pdf" | "xlsx") => {
      try {
        if (!data) {
          throw new Error("No data received from the server");
        }

        let blob: Blob | null = null;
        const filename = `organisations.${format}`;

        if (format === "pdf") {
          if (!(data instanceof ArrayBuffer)) {
            throw new Error("Received data is not an ArrayBuffer");
          }
          const pdfDoc = await PDFDocument.load(data);
          const pdfBytes = await pdfDoc.save();
          blob = new Blob([pdfBytes], { type: "application/pdf" });
        } else if (format === "csv") {
          blob = new Blob([data], { type: "text/csv" });
        } else if (format === "xlsx") {
          if (!(data instanceof ArrayBuffer)) {
            throw new Error("Received data is not an ArrayBuffer for XLSX");
          }
          blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        }

        if (!blob) {
          throw new Error("Failed to create a blob for the file export.");
        }

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);

        toast.success("Export completed successfully");
      } catch (error) {
        toast.error(`Export processing failed: ${(error as Error).message}`);
      }
    },
    onError: (error: Error) => {
      toast.error(`Export failed: ${error.message}`);
    },
  });

  const handleExport = async (format: "csv" | "pdf" | "xlsx") => {
    try {
      await exportMutation.mutateAsync(format);
    } catch (error) {
      toast.error(`Export error: ${(error as Error).message}`);
    }
  };

  const panes = [
    {
      id: "",
      label: "All",
      show: true,
      value: getTotalCount(workspaceStats),
    },
    {
      id: "active",
      label: "Active",
      show: true,
      value: getStat("active", workspaceStats),
    },
    {
      id: "pending",
      label: "Pending",
      show: true,
      value: getStat("pending", workspaceStats),
    },
    {
      id: "inactive",
      label: "Inactive",
      show: true,
      value: getStat("inactive", workspaceStats),
    },
  ];

  const colums: ColumnDef<(typeof organisations.data)[0]>[] = [
    {
      id: "workspace-name",
      cell: ({ row }) => {
        const name = row.original.name || "Unknown";
        const [first_name, last_name] = name.split(" ");
        return (
          <TableAvatar
            first_name={totoPascalCase(first_name)}
            last_name={totoPascalCase(last_name)}
          />
        );
      },
      header: () => <span>Name</span>,
      size: 8,
    },
    {
      id: "created-by",
      cell: ({ row }) => {
        const { firstName, lastName } =
          row.original?.primaryUser?.profile || {};
        return (
          <TableAvatar
            first_name={totoPascalCase(firstName || row.original.name)}
            last_name={totoPascalCase(lastName || row.original.name)}
          />
        );
      },
      header: () => <span>Created By</span>,
      size: 8,
    },
    {
      id: "email",
      cell: ({ row }) => <span>{row.original.primaryUser?.email}</span>,
      header: () => <span>Email Address</span>,
      size: 28,
    },
    {
      id: "interval",
      cell: ({ row }) => (
        <div className="flex flex-col items-start tracking-normal gap-y-1">
          {row.original.subscription?.workspace?.slug !== "admin" && (
            <span
              style={{
                color:
                  row.original.subscription?.plan?.name?.toLowerCase() ===
                  "free"
                    ? "#B47818"
                    : "#2D9F75",
                borderColor:
                  row.original.subscription?.plan?.name?.toLowerCase() ===
                  "free"
                    ? "#B47818"
                    : "#2D9F75",
              }}
              className="py-[2px] px-2 border rounded-full font-medium text-[11px] uppercase flex items-center gap-x-1"
            >
              <img
                src={
                  row.original.subscription?.plan?.name?.toLowerCase() ===
                  "free"
                    ? "/leaf.svg"
                    : "/bolt.svg"
                }
                alt="Plan Icon"
              />
              {row.original.subscription?.plan?.name
                ? `${row.original.subscription?.plan?.name.replace(
                    /\b\w/g,
                    (char: string) => char.toUpperCase()
                  )} Plan`
                : "Free Plan"}
            </span>
          )}
        </div>
      ),
      header: () => <span>Billing</span>,
      size: 15,
    },
    {
      id: "date",
      cell: ({ row }) => (
        <div className="flex items-center gap-x-2">
          {getFormattedDate(row.original.createdAt)}
        </div>
      ),
      header: () => <span>Date Created</span>,
      size: 15,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      // size: 9.9,
    },
  ];

  return statsLoading ? (
    <Loader big />
  ) : (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex items-center justify-between">
        <Header
          title="Organisations"
          subtext="Manage and organise your organisations "
        />
        <ExpandableButton label="Export data">
          <div className="min-w-[160px] w-full flex flex-col items-start">
            <button
              className="kebab-button"
              type="button"
              onClick={() => handleExport("csv")}
              disabled={exportMutation.isPending}
            >
              {exportMutation.isPending && exportMutation.variables === "csv"
                ? "Exporting..."
                : "Export as .csv"}
            </button>
            <button
              className="kebab-button"
              type="button"
              onClick={() => handleExport("pdf")}
              disabled={exportMutation.isPending}
            >
              {exportMutation.isPending && exportMutation.variables === "pdf"
                ? "Exporting..."
                : "Export as .pdf"}
            </button>
            <button
              className="kebab-button"
              type="button"
              onClick={() => handleExport("xlsx")}
              disabled={exportMutation.isPending}
            >
              {exportMutation.isPending && exportMutation.variables === "xlsx"
                ? "Exporting..."
                : "Export as .xlsx"}
            </button>
          </div>
        </ExpandableButton>
      </div>
      <div className="flex flex-col mt-6 w-full h-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />

        <div className="mt-6 bg-white w-full h-full rounded-xl flex flex-col p-4 gap-y-5">
          <div className="flex items-center gap-x-2">
            <SearchBar
              placeholder="Search..."
              initialValue={searchParams.get("search") || ""}
              onClear={handleClearSearch}
              onSearch={handleSearch}
            />
          </div>

          {isLoading ? (
            <Loader big />
          ) : organisations?.data?.length > 0 ? (
            <Table
              clickFunction={navigate}
              key="clients"
              cols={colums}
              rawData={organisations?.data}
            />
          ) : (
            <div className="mt-6 bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No organisations to show
                  </span>
                  <span className=" text-sm text-[#525866]">
                    When organisations sign up to your platform, they will show
                    up here
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {organisations?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={organisations?.meta?.totalNumberOfPages}
            total={organisations?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
