/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from './styles.module.css';
import ReactPaginate from 'react-paginate';

export const Pagination = ({
  pages,
  page,
  handlePageChange,
}: {
  total: number;
  pages: number;
  page?: any;
  limit?: any;
  handlePageChange: (e: { selected: number }) => void;
}) => {
  return (
    <div className={styles.container}>
      <div className='text-sm text-[#525866] shrink-0'>
        Page {page} of {pages}
      </div>
      <div className='flex items-center gap-x-5 ml-auto w-full'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<NextArrow />}
          nextLinkClassName='arrow-link'
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          forcePage={parseInt(page) - 1}
          pageCount={pages}
          previousLabel={<PrevArrow />}
          previousLinkClassName='arrow-link'
          disabledLinkClassName='disabled'
          containerClassName='pagination-container'
          pageClassName='pagination-items'
          pageLinkClassName='pagination-links'
          activeClassName='items-active'
          renderOnZeroPageCount={undefined}
        />
      </div>
      <div className='w-[142px] shrink-0'></div>
    </div>
  );
};

const NextArrow = () => (
  <span className='group peer'>
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7958 9.99932L7.08331 6.28682L8.14381 5.22632L12.9168 9.99932L8.14381 14.7723L7.08331 13.7118L10.7958 9.99932Z'
        fill='inherit'
      />
    </svg>
  </span>
);

const PrevArrow = () => (
  <span className='group peer'>
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.20431 9.99932L12.9168 13.7118L11.8563 14.7723L7.08331 9.99932L11.8563 5.22632L12.9168 6.28682L9.20431 9.99932Z'
        fill='inherit'
      />
    </svg>
  </span>
);
