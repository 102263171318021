/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { Button, CheckboxElement, TextArea, TextInput } from "~/components";
import Loader from "~/components/UI/Loader";
import { useForm } from "~/hooks";
import { addRole } from "~/mutations/roleMutations";
import { getPermissions } from "~/queries/roleQueries";

export const AddRole = ({ close }: { close?: () => void }) => {
  const { formData, update } = useForm({
    initial: { name: "", description: "" },
  });
  const qc = useQueryClient();
  const [selected, setSelected] = useState<any>([]);
  const [features, setFeatures] = useState<string[]>([]);

  const { data: permissions, isLoading } = useQuery({
    queryKey: ["permissions"],
    queryFn: async () => {
      try {
        const data = await getPermissions();
        setFeatures(
          Array.from<any>(new Set(data.data.map((d: any) => d.feature?.name)))
        );
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      addRole({ ...formData, permissions: [...selected], status: "active" }),
    ...{
      onSuccess() {
        toast.success(`${formData.name} role created successfully`);
        qc.invalidateQueries({ queryKey: ["roles"] });
        close?.();
      },
      onError(error) {
        toast.error(error.message);
        return false;
      },
    },
  });
  const handleClose = () => {
    mutate();
  };
  const handleCheck = (obj: any) => {
    if (selected?.includes(obj.id)) {
      const new_list = selected?.filter((id: any) => id !== obj.id);
      setSelected(() => new_list);
    } else {
      setSelected((prev: any) => [...prev, obj.id]);
    }
  };
  const handleSelectAll = (feature: string) => {
    const filtered = permissions?.data
      ?.filter((p: any) => p.feature?.name === feature)
      .map((p: any) => p.id);
    setSelected((prev: any) => {
      if (filtered.every((p: any) => prev.includes(p))) {
        return Array.from(
          new Set(prev.filter((p: any) => !filtered.includes(p)))
        );
      }
      return Array.from(new Set([...filtered, ...prev]));
    });
  };

  return (
    <div className="w-full flex flex-col h-full">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Add role</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      {isLoading ? (
        <Loader big />
      ) : (
        <section className="p-5 flex flex-col w-full gap-y-5 ">
          <TextInput
            label="Role Name"
            name="name"
            value={formData?.name}
            handleInputChange={update}
          />
          <TextArea
            label="Role Description"
            name="description"
            placeholder="Enter Description"
            rows={3}
            value={formData?.description}
            handleInputChange={update}
          />
          {features.map((feature) => {
            const filtered = permissions?.data?.filter(
              (permission: any) => permission.feature?.name === feature
            );
            const allSelected = filtered?.every((p: any) =>
              selected.includes(p.id)
            );
            return (
              <>
                <span className="text-sm font-medium text-[#0A0D14]">
                  {feature}
                </span>
                <div className="flex flex-col items-center w-full pt-3">
                  <div className="flex flex-col w-full">
                    <CheckboxElement
                      checked={allSelected}
                      name={"select_all"}
                      label={"Select all"}
                      changeFunction={() => handleSelectAll(feature)}
                    />
                    <div className=" grid grid-cols-2 gap-x-6 gap-y-5 w-full mt-5">
                      {filtered.map((p: any) => (
                        <div key={p.id} className="w-full ">
                          <CheckboxElement
                            checked={selected.includes(p.id!)}
                            name={p.name}
                            label={p.name}
                            changeFunction={() => handleCheck(p)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </section>
      )}
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3 mt-auto">
        <Button mono label="Cancel" effect={close} />
        <Button
          label="Create role"
          effect={handleClose}
          disabled={!formData.name}
          loading={isPending}
        />
      </footer>
    </div>
  );
};
