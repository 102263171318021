export const Documents = () => {
  return (
    <div className='  bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center'>
      <div className='max-w-[260px] w-full flex flex-col items-center gap-y-5 '>
        <img src='/empty.svg' className='w-[100px]' />
        <div className='flex flex-col items-center gap-y-2'>
          <span className=' text-sm text-[#0A0D14]'>No iframe to show</span>
        </div>
      </div>
    </div>
  );
};
