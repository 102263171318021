/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-refresh/only-export-components */
// import { useQuery } from '@tanstack/react-query';
import { createContext, ReactNode, useContext, useState } from 'react';
// import { getWorkspace } from '~/queries/workspaceQueries';

const AuthContext = createContext<any>({});

interface AuthProvider {
  children: ReactNode;
}

export const useAuth = () => {
  return useContext<{
    user: Profile;
    workspace: Workspace;
    role: Role;
    setUser: (v: any) => void;
    setWorkspace: (v: any) => void;
    setRole: (v: any) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (v: boolean) => void;
    setStoredUser: (v: any) => void;
    setStoredToken: (v: any) => void;
    updateProfile: (v: any) => void;
    clearAuth: () => void;
  }>(AuthContext);
};

export const AuthProvider = ({ children }: AuthProvider) => {
  const [user, setUser] = useState<any>({});
  const [role, setRole] = useState<any>({});
  const [workspace, setWorkspace] = useState<any>({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setStoredUser = (data: any) => {
    localStorage.setItem('who_user', JSON.stringify(data));
  };
  const setStoredToken = (data: any) => {
    localStorage.setItem('who_token_data', JSON.stringify(data));
  };
  const updateProfile = (data: any) => {
    setUser((prev: any) => ({ ...prev, ...data }));
    setStoredUser({ ...user, ...data });
  };
  const clearAuth = () => {
    setUser(null), setIsAuthenticated(false);
    localStorage.removeItem('who_user');
    localStorage.removeItem('who_token_data');
  };
  const authHooks = {
    user,
    workspace,
    role,
    setWorkspace,
    setUser,
    setRole,
    isAuthenticated,
    setIsAuthenticated,
    setStoredUser,
    setStoredToken,
    updateProfile,
    clearAuth,
  };
  return (
    <AuthContext.Provider value={authHooks}>{children}</AuthContext.Provider>
  );
};
