import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Button } from '~/components';
import { resendInvite } from '~/mutations/userMutations';
export const ReinviteMember = ({
  close,
  data,
}: {
  close?: () => void;
  data: User;
}) => {
  const qc = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: () => resendInvite(data.id),
    ...{
      onSuccess() {
        toast.success(`Invite resent to ${data.email}`);
        qc.invalidateQueries({ queryKey: ['members'] });
        close?.();
      },
      onError(err) {
        toast.error(err.message);
      },
    },
  });
  return (
    <div className='w-full flex flex-col'>
      <header className='flex items-start w-full justify-between border-b py-4 px-5'>
        <h4 className='text-[#0A0D14] font-medium'>Confirm Action</h4>{' '}
        <button onClick={close}>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z'
              fill='#525866'
            />
          </svg>
        </button>
      </header>
      <section className='p-5 flex flex-col w-full gap-y-5 max-w-[440px]'>
        <p className='text-sm text-[#525866]'>
          Are you sure you want to reinvite{' '}
          <span className='font-medium'>
            {data.profile.firstName
              ? `${data.profile.firstName} ${data.profile.lastName}`
              : data.email}
            ?
          </span>
        </p>
      </section>
      <footer className='px-5 py-4 border-t flex justify-end items-center gap-x-3'>
        <Button mono label='Cancel' effect={close} />
        <Button label='Yes, reinvite' effect={mutate} loading={isPending} />
      </footer>
    </div>
  );
};
