import { Button } from "~/components";
import { getFullMoney } from "~/utils/formatAmount";
import { UpgradePlan } from "./Upgrade";
import { openModal } from "~/providers";

export const PlanCard = ({
  data,
  time,
  location,
  current,
  setReference,
}: {
  index?: number;
  data: Plan;
  time: string;
  current: Subscription;
  location: UserLocation;
  setReference: (v: any) => void;
}) => {
  const popup = openModal();
  const id = data?.id;
  const getProcessor = () => {
    const [processor] = data.processors.filter(
      (pro) =>
        pro.currency ===
        (current?.currency || (location?.currency == "NGN" ? "NGN" : "USD"))
    );
    return processor || data.processors[0];
  };

  const openUpgradePlanModal = () => {
    popup({
      component: (
        <UpgradePlan
          id={id}
          currency={getProcessor().currency == "NGN" ? "NGN" : "USD"}
          name={current?.plan?.name}
          close={(data) => setReference(data)}
        />
      ),
    });
  };

  const processor = getProcessor();

  return (
    <div className="rounded-xl border border-[#E2E4E9] bg-white flex flex-col w-[251px] h-full max-h-full overflow-y-clip">
      <div className="bg-[#F5F7FA] border-b border-[#E2E4E9] px-6 py-4 rounded-t-xl flex flex-col">
        <div className="mt-4 flex flex-col">
          <h3 className="text-[#0E121B] font-medium text-xl">{data.name}</h3>
          <h2 className="mt-3 text-[#35B9E9] font-semibold text-2xl">
            {getFullMoney(
              parseFloat(processor?.amount),
              processor?.currency,
              location?.languages?.split(",")[0]
                ? location.languages?.split(",")[0] == "en-NG"
                  ? location?.languages?.split(",")[0]
                  : "en-US"
                : "en-US"
              // location?.currency ?? 'USD',
              // location?.languages?.split(',')[0] ?? 'en-US'
            )}
          </h2>
          <span className="mt-1 text-xs text-[#525866]">per {time}</span>
          {current?.plan?.id == data.id ? (
            <Button
              mono
              disabled
              label="Current Plan"
              {...{ style: { marginTop: 22 } }}
            />
          ) : (
            <Button
              effect={openUpgradePlanModal}
              label={
                current.currency === processor.currency &&
                processor.amount < current.amount
                  ? "Downgrade Plan"
                  : "Upgrade Plan"
              }
              {...{ style: { marginTop: 22 } }}
            />
          )}
        </div>
      </div>
      <div className="max-h-full h-full flex flex-col p-6 gap-y-5 text-xs text-[#0E121B] ">
        <span>All features options:</span>
        <div className="flex flex-col gap-y-5 h-full max-h-full pb-10">
          {" "}
          {data.features.map((feat) => (
            <div key={feat.id} className="flex items-center gap-x-[6px]">
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 11.625C3.39331 11.625 0.875 9.10669 0.875 6C0.875 2.89331 3.39331 0.375 6.5 0.375C9.60669 0.375 12.125 2.89331 12.125 6C12.125 9.10669 9.60669 11.625 6.5 11.625ZM5.93919 8.25L9.91606 4.27256L9.12069 3.47719L5.93919 6.65925L4.34787 5.06794L3.5525 5.86331L5.93919 8.25Z"
                  fill="#35B9E9"
                />
              </svg>
              <span>{feat.name}</span>
            </div>
          ))}
        </div>

        {/* <div className='flex items-center gap-x-[6px]'>
          <svg
            width='15'
            height='16'
            viewBox='0 0 15 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.5 13.625C4.39331 13.625 1.875 11.1067 1.875 8C1.875 4.89331 4.39331 2.375 7.5 2.375C10.6067 2.375 13.125 4.89331 13.125 8C13.125 11.1067 10.6067 13.625 7.5 13.625ZM7.5 7.20463L5.90925 5.61331L5.11331 6.40925L6.70463 8L5.11331 9.59075L5.90925 10.3867L7.5 8.79537L9.09075 10.3867L9.88669 9.59075L8.29537 8L9.88669 6.40925L9.09075 5.61331L7.5 7.20463Z'
              fill='#F8C9D2'
            />
          </svg>

          <span>This is the feature name</span>
        </div> */}
      </div>
    </div>
  );
};
