import { MouseEventHandler } from "react";

export const SignupWithMicrosoft = ({
  url,
  onClick
}: {
  url: string;
  onClick?: MouseEventHandler;
}) => {
  return (
    <a
      href={url}
      onClick={onClick}
      className="flex items-center justify-center gap-x-2 py-[10px] px-4 rounded-xl text-sm text-white font-medium bg-[#2F2F2F]"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.5 2.5H9.64285V9.64285H2.5V2.5Z" fill="white" />
        <path d="M10.3571 2.5H17.5V9.64285H10.3571V2.5Z" fill="white" />
        <path d="M2.5 10.3574H9.64285V17.5003H2.5V10.3574Z" fill="white" />
        <path
          d="M10.3571 10.3574H17.4999V17.5003H10.3571V10.3574Z"
          fill="white"
        />
      </svg>
      <span>Sign up with Microsoft</span>
    </a>
  );
};
