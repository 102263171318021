/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl =
  import.meta.env.VITE_BASE_URL || "https://platform.api.wholistic.work";

export const getProjects = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getProjectRequests = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/requests`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getProject = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getProjectUsers = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}/users`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const generateProjectQualityResults = async (
  projectId: any,
  resultId: any
) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${projectId}/quality-results/${resultId}/calculate`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const deleteProjectQualityResult = async (
  projectId: any,
  resultId: any
) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/projects/${projectId}/quality-results/${resultId}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getProjectQualityResults = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}/quality-results`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
