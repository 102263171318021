import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import ModalProvider from '~/providers/ModalProvider';
import SideModalProvider from '~/providers/SideModalProvider';

export const AppContainer = () => {
  const { pathname } = useLocation();

  return (
    <div className='w-full h-full relative'>
      <ScrollRestoration key={pathname} />
      <SideModalProvider>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </SideModalProvider>
    </div>
  );
};
