import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Group, Button, TextInput } from "~/components";
import Loader from "~/components/UI/Loader";
import { getEmailTemplate, getReportTemplate } from "~/queries/templateQueries";
import { HtmlViewer } from "./HtmlViewer";
import { Modal } from "./Modal/Modal";
import { useState, useEffect } from "react";
import MEditor from "@monaco-editor/react"; // Import the Monaco Editor
import toast from "react-hot-toast";
import {
  updateEmailTemplate,
  updateReportTemplate,
} from "~/mutations/templateMutation";

export const Template = () => {
  const navigate = useNavigate();
  const qc = useQueryClient();
  const { id } = useParams();
  const [updatedHtml, setUpdatedHtml] = useState<string>(""); // State for updated HTML
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false); // State to control the modal
  const [subject, setSubject] = useState<string>(""); // State for subject
  const [templateType, setTemplateType] = useState<string>(""); // State to track template type

  const { data: template, isLoading } = useQuery({
    queryKey: ["template", id],
    queryFn: async () => {
      try {
        const emailData = await getEmailTemplate(id);
        if (emailData.data) {
          return { ...emailData.data, type: "email" };
        }
      } catch (error) {
        try {
          const reportData = await getReportTemplate(id);
          if (reportData.data) {
            return { ...reportData.data, type: "report" };
          }
        } catch (reportError) {
          throw new Error("Template not found");
        }
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // UseEffect to update the state of updatedHtml, subject, and templateType once the template data is available
  useEffect(() => {
    if (template) {
      setUpdatedHtml(template.body || ""); // Set body as the initial raw HTML
      setSubject(template.title || ""); // Set subject as the initial title
      setTemplateType(template.type || ""); // Set template type (email or report)
    }
  }, [template]);

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const formData = {
        title: subject,
        body: updatedHtml,
      };

      // Choose the correct mutation function based on the template type
      if (templateType === "email") {
        return updateEmailTemplate(formData, id);
      } else {
        return updateReportTemplate(formData, id);
      }
    },
    onSuccess() {
      toast.success(`${subject} updated successfully`);
      qc.invalidateQueries({ queryKey: ["template", id] }); // Invalidate the query to refetch the updated template
      navigate(`/app/templates/${id}`); // Navigate to clients page after saving
    },
    onError(error) {
      toast.error(`${error.message}`);
    },
  });

  const handleSave = () => {
    mutate(); // Trigger the mutation
  };

  const handlePreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  if (isLoading) return <Loader big />;
  if (!template) return <div>Template not found</div>;

  return (
    <div className="w-full h-full flex flex-col overflow-scroll p-8">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Back to Templates</span>
        </button>
      </div>

      <Group key="header">
        <div className="flex items-center w-full justify-between mt-6">
          <h1 className="text-[#0A0D14] font-medium text-lg">
            {template.title}
          </h1>
          <div className="flex flex-row gap-x-3">
            <Button mono label="Preview" onClick={handlePreview} />
            <Button
              primary
              label="Save"
              onClick={handleSave}
              disabled={isPending}
            />
          </div>
        </div>
      </Group>

      <div className="flex flex-col mt-6 gap-y-5 w-full h-full">
        <Group key="right">
          <div className="bg-white border h-full py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm ">
            <div className="flex justify-between ">
              <h1>Subject</h1>
              <div className="flex flex-col w-[60%] gap-y-5">
                <TextInput
                  name="subject"
                  required
                  value={subject}
                  placeholder="Template Subject"
                  autoFocus
                  error=""
                  handleInputChange={(e) => setSubject(e.target.value)} // Update subject on input change
                />
              </div>
            </div>
            <div className="flex flex-col gap-x-5 h-full pt-5">
              <MEditor
                onMount={(editor) => {
                  editor.trigger(
                    "anyString",
                    "editor.action.formatDocument",
                    "test"
                  );
                }}
                onChange={(value) => setUpdatedHtml(value || "")}
                language="html"
                defaultValue={updatedHtml}
                {...monacoEditorProps}
              />
            </div>
          </div>
        </Group>
      </div>

      <Modal isOpen={isPreviewOpen} onClose={handleClosePreview}>
        <div className="w-full">
          <HtmlViewer htmlString={updatedHtml} />
        </div>
      </Modal>
    </div>
  );
};

const monacoEditorProps = {
  height: "95%",
  width: "100%",
  theme: "vs-dark",
  options: {
    minimap: { enabled: false },
    formatOnPaste: true,
    formatOnType: true,
  },
};
