/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;

export const authorizeProvider = async (
  provider: string,
  data: { code: string; token?: string | null }
) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/providers/${provider}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const initEmailSignup = async (data: {
  email?: string;
  recaptchaResponse?: string;
}) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/signup`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const completeEmailSignup = async (
  data: {
    firstName?: string;
    lastName?: string;
    password?: string;
    confirmPassword?: string;
  },
  token: string
) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/setup/${token}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const emailLogin = async (data: {
  email?: string;
  password?: string;
}) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/login`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const twoFaLogin = async (data: { token?: string; code?: string }) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/login/two-fa`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const initForgotPassword = async (data: { email?: string }) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/password/forgot`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const forgotPasswordComplete = async (data: any, token: string) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/auth/password/reset/${token}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
