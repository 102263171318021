interface LoaderProps {
  big?: boolean;
  bgColor?: string;
}

const Loader = ({ big, bgColor }: LoaderProps) => {
  return big ? (
    <div className='w-full h-full grid place-items-center'>
      <svg
        width='72'
        height='72'
        style={{
          background: 'transparent',
          display: 'block',
          shapeRendering: 'auto',
        }}
        viewBox='0 0 72 72'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='36' cy='36' r='32' stroke='#ECEEF5' strokeWidth='8' />
        <path
          d='M68 36C68 53.6731 53.6731 68 36 68'
          stroke='#2C1DFF'
          strokeWidth='8'
          strokeLinecap='round'
        >
          <animateTransform
            attributeName='transform'
            type='rotate'
            repeatCount='indefinite'
            dur='1s'
            values='0 36 36;360 36 36'
            keyTimes='0;1'
          ></animateTransform>
        </path>
      </svg>
    </div>
  ) : (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          // background: 'transparent',
          display: 'block',
          shapeRendering: 'auto',
        }}
        width={'25px'}
        height={'25px'}
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid'
      >
        <circle
          cx='50'
          cy='50'
          fill='none'
          stroke={bgColor ? bgColor : '#35B9E9'}
          strokeWidth='12.5'
          r='35'
          strokeDasharray='164.93361431346415 56.97787143782138'
        >
          <animateTransform
            attributeName='transform'
            type='rotate'
            repeatCount='indefinite'
            dur='1s'
            values='0 50 50;360 50 50'
            keyTimes='0;1'
          ></animateTransform>
        </circle>
        {/* [ldio] generated by https://loading.io/*/}
      </svg>
    </>
  );
};

export default Loader;
