/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { Button, GettingStarted } from '~/components';
import { useAuth } from '~/providers';
import { Inbox, ProjectSummaryCard } from './components';
import { useQuery } from '@tanstack/react-query';
import { getProjects } from '~/queries/projectQueries';
import toast from 'react-hot-toast';
import Loader from '~/components/UI/Loader';

export const TeamDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: projects, isLoading } = useQuery<ListResponse<Project>>({
    queryKey: ['projects', 'dashboard'],
    queryFn: async () => {
      try {
        const data = await getProjects({ page: 1, limit: 3 });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  return isLoading ? (
    <Loader big />
  ) : (
    <div className='flex flex-col w-full h-full p-8  '>
      <div className='flex items-center justify-between'>
        <div className='flex flex-col'>
          <h1 className='text-lg text-[#0A0D14] font-medium'>
            Welcome {user?.firstName} {user.lastName}!
          </h1>
          <p className='text-sm text-[#525866]'>
            Here are the recent stats for your workspace so far.
          </p>
        </div>
      </div>
      <div className='mt-6 flex flex-col gap-x-5 h-full gap-y-10'>
        <div className='flex flex-col'>
          <div className=' flex items-center w-full justify-between'>
            <h3 className='text-lg font-medium'>Recent projects</h3>
            <Button
              label='New project request'
              effect={() => navigate('/requests/create')}
              icon={
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z'
                    fill='white'
                  />
                </svg>
              }
              leftIcon
            />
          </div>
          <div className='p-3 rounded-xl bg-[#F0F0F0] h-[305px] w-full grid grid-cols-3 gap-x-3 mt-5'>
            {projects?.data && projects.data?.length > 0 ? (
              projects?.data.map((d) => <ProjectSummaryCard project={d} />)
            ) : (
              <div className='bg-white border py-4 px-5 rounded-xl border-[#E2E4E9] shadow-sm w-full h-full col-span-3'>
                <div className=' w-full h-full flex flex-col items-center justify-center text-center'>
                  <div className='max-w-[370px] w-full flex flex-col items-center gap-y-5 '>
                    <img src='/empty.svg' className='w-[100px]' />
                    <div className='flex flex-col items-center gap-y-2'>
                      <span className=' text-sm text-[#0A0D14]'>
                        No projects
                      </span>
                      <span className='max-w-[256px] text-sm text-[#525866]'>
                        You do not have any projects created recently
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='flex flex-col gap-y-5 w-full h-full min-h-[400px]'>
          <Inbox />
        </div>
      </div>
      <GettingStarted />
    </div>
  );
};
