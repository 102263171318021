/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
export const RadioButton = ({
  value,
  selected,
  updateData,
  name,
  children,
}: {
  updateData: any;
  selected: boolean;
  name: string;
  value: string;
  children: ReactNode;
}) => {
  return (
    <>
      <label
        className='p-4 border rounded-xl text-center flex justify-center cursor-pointer text-[#0A0D14] duration-200 transition-all ease-in-out hover:bg-[#F8F9FF]'
        style={{
          backgroundColor: selected ? '#F1FBFE' : '#FFFFFF',
          borderColor: selected ? '#5FD7F4' : '#E2E4E9',
          opacity: 0.8,
        }}
        htmlFor={value}
      >
        <span className='text-sm font-medium'>{children}</span>
      </label>
      <input
        id={value}
        className='hidden'
        type='radio'
        name={name}
        value={value}
        checked={selected}
        onChange={updateData}
      />
    </>
  );
};
