import "./instrument.ts"
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { AuthProvider } from "./providers/index.ts";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ToastNotifications } from "./components/index.ts";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CombinedRoutes from "./routes/index.ts";

const router = createBrowserRouter([...CombinedRoutes]);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      retry(failureCount: number) {
        return failureCount == 1;
      },
      // refetchOnWindowFocus: false,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
    <HelmetProvider>
      <ToastNotifications />
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </HelmetProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </PersistQueryClientProvider>
);
