/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { getNotifications, readNotifications } from "~/api";
import { Button } from "~/components";
import Loader from "~/components/UI/Loader";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const Inbox = () => {
  const qc = useQueryClient();

  const { data: notifications, isLoading } = useQuery<
    ListResponse<UserNotification>
  >({
    queryKey: ["dashboard notifications"],
    queryFn: async () => {
      try {
        const data = await getNotifications({ page: 1, limit: 10 });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { mutate } = useMutation({
    mutationFn: (notifications: string[]) => readNotifications(notifications),
    ...{
      onSuccess() {
        qc.invalidateQueries({ queryKey: ["dashboard notifications"] });
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  return (
    <div className="bg-white border py-4 rounded-xl border-[#E2E4E9] shadow-sm w-full h-[90%]  overflow-clip">
      <header className="w-full flex items-center justify-between border-b pb-4 px-5">
        <span className="font-medium">Inbox</span>
        <Link to="/app/inbox">
          <Button label="View all" mono />
        </Link>
      </header>
      <div className=" w-full h-full">
        {isLoading ? (
          <Loader big />
        ) : notifications && notifications?.data?.length > 0 ? (
          <div className="flex flex-col w-full h-full overflow-scroll pb-10">
            {notifications.data.map((n, i) => (
              <div
                key={n.id}
                className={`flex items-center px-[14px] py-5 gap-x-[14px] cursor-pointer ${
                  n?.read ? "" : "bg-[#F5FDFE]"
                }`}
                onClick={() => {
                  notifications.data[i].read = true;
                  mutate([n.id]);
                }}
              >
                <span
                  className={`size-[6px] rounded-full flex-shrink-0 ${
                    n?.read ? "bg-transparent" : "bg-[#53C2DA]"
                  }`}
                ></span>
                <div className="w-10 h-10 rounded-lg bg-[#E255F2] flex items-center justify-center shrink-0 text-white font-medium uppercase  ">
                  <>
                    {n?.details?.workspaceName?.[0]}
                    {n?.details?.workspaceName?.split(" ")?.[1]?.[0]}
                  </>
                </div>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col gap-y-1">
                    <span className="text-sm text-[#0A0D14] font-semibold">
                      {n.title}
                    </span>
                    <span
                      className="text-sm text-[#0A0D14]"
                      dangerouslySetInnerHTML={{ __html: n.body }}
                    />
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="text-[#525866] text-xs">
                      {dayjs().to(dayjs(n.createdAt))}
                    </p>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
        ) : (
          <div className="max-w-[370px] w-full h-full mx-auto flex flex-col justify-center items-center gap-y-5 ">
            <img src="/empty.svg" className="w-[100px]" />
            <div className="flex flex-col items-center gap-y-2">
              <span className=" text-sm text-[#0A0D14]">
                You're all caught up
              </span>
              <span className="max-w-[256px] text-sm text-[#525866]">
                You don't have any new notifications
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
