import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import toast from "react-hot-toast";
import { getNotificationSettings, setEmailSettings } from "~/api";
import { Button, Header, Table, Toggle } from "~/components";
import Loader from "~/components/UI/Loader";

interface NotificationSetting {
  id: string;
  slug: string;
  type: "email" | "push";
  title: string;
  enabled: boolean;
  configurable: boolean;
}

export const Notifications = () => {
  const [enabledList, setEnabledList] = useState<string[]>([]);
  const [initialEnabledList, setInitialEnabledList] = useState<string[]>([]); // Track initial state

  const { data: notificationSettings, isLoading } = useQuery<
    ListResponse<NotificationSetting>
  >({
    queryKey: ["notification settings"],
    queryFn: async () => {
      try {
        const data = await getNotificationSettings();
        const initialEnabledList = data.data
          .filter((d: NotificationSetting) => d.enabled)
          .map((d: NotificationSetting) => d.id);
        setEnabledList(initialEnabledList);
        setInitialEnabledList(initialEnabledList); // Save the initial state
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data: string[]) => setEmailSettings({ enabled: data }),
    onSuccess() {
      toast.success("Settings updated successfully");
      setInitialEnabledList(enabledList); // Update initial state after save
    },
    onError(error: Error) {
      toast.error(error.message);
    },
  });

  const handleToggle = (id: string) => {
    setEnabledList((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Compare current enabledList with the initialEnabledList
  const hasChanges =
    JSON.stringify(enabledList) !== JSON.stringify(initialEnabledList);

  const groupedNotifications = notificationSettings?.data.reduce(
    (acc, notification) => {
      if (!acc[notification.slug]) {
        acc[notification.slug] = {
          title: notification.title,
          email: null,
          push: null,
        };
      }
      acc[notification.slug][notification.type] = notification;
      return acc;
    },
    {} as Record<
      string,
      {
        title: string;
        email: NotificationSetting | null;
        push: NotificationSetting | null;
      }
    >
  );

  const subscriptionColumns: ColumnDef<{
    title: string;
    email: NotificationSetting | null;
    push: NotificationSetting | null;
  }>[] = [
    {
      id: "action",
      cell: ({ row }) => row.original.title,
      header: () => <span>Action</span>,
      size: 80,
    },
    {
      id: "email",
      cell: ({ row }) =>
        row.original.email && (
          <Toggle
            key={row.original.email.id}
            isOn={enabledList.includes(row.original.email.id)}
            effect={() =>
              row.original.email?.configurable &&
              handleToggle(row.original.email!.id)
            }
          />
        ),
      header: () => <span>Email</span>,
      size: 20,
    },
    {
      id: "push",
      cell: ({ row }) =>
        row.original.push && (
          <Toggle
            key={row.original.push.id}
            isOn={enabledList.includes(row.original.push.id)}
            effect={() =>
              row.original.push?.configurable &&
              handleToggle(row.original.push!.id)
            }
          />
        ),
      header: () => <span>Push</span>,
      size: 20,
    },
  ];

  const handleSave = () => {
    if (hasChanges) {
      mutate(enabledList);
    }
  };

  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full flex flex-col">
      <Header
        title="Notifications"
        subtext="Manage where and when you want to be notified."
      />
      <div className="flex flex-col gap-y-3 w-full mt-6">
        <div className="items-center flex py-2 justify-between">
          <h3 className="font-medium text-sm">Notification Settings</h3>
          <Button
            label="Save"
            effect={handleSave}
            loading={isPending}
            disabled={!hasChanges || isPending} // Disable if no changes
          />
        </div>
        <div className="rounded-lg bg-white border flex flex-col w-full text-sm">
          <div className="p-5 border-b flex flex-col w-full gap-y-5">
            {groupedNotifications &&
              Object.values(groupedNotifications).length > 0 && (
                <Table
                  key="subs"
                  cols={subscriptionColumns}
                  rawData={Object.values(groupedNotifications)}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
