import { MouseEventHandler } from "react";

export const SignupWithLinkedin = ({
  url,
  onClick,
}: {
  url: string;
  onClick?: MouseEventHandler;
}) => {
  return (
    <a
      href={url}
      onClick={onClick}
      className="flex items-center justify-center gap-x-2 py-[10px] px-4 rounded-xl text-sm text-white font-medium bg-[#0077B5]"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8333 2.5H4.16667C3.24583 2.5 2.5 3.24583 2.5 4.16667V15.8333C2.5 16.7542 3.24583 17.5 4.16667 17.5H15.8333C16.7542 17.5 17.5 16.7542 17.5 15.8333V4.16667C17.5 3.24583 16.7542 2.5 15.8333 2.5ZM7.03542 15.4167H4.79792V8.19375H7.03542V15.4167ZM5.90625 7.24792C5.175 7.24792 4.58333 6.65208 4.58333 5.91458C4.58333 5.17708 5.175 4.58125 5.90625 4.58125C6.6375 4.58125 7.22917 5.17708 7.22917 5.91458C7.22917 6.65208 6.6375 7.24792 5.90625 7.24792ZM15.4167 15.4167H13.1917V11.625C13.1917 10.5854 12.7958 10.0042 11.975 10.0042C11.0792 10.0042 10.6125 10.6083 10.6125 11.625V15.4167H8.46667V8.19375H10.6125V9.16667C10.6125 9.16667 11.2583 7.97292 12.7896 7.97292C14.3208 7.97292 15.4187 8.90833 15.4187 10.8438V15.4167H15.4167Z"
          fill="white"
        />
      </svg>
      <span>Sign up with LinkedIn</span>
    </a>
  );
};
