import { useAuth } from "~/providers";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { SummaryCard } from "./components";
import { GettingStarted } from "~/components";
import { Chart } from "./../dashboard-pages/charts/Transactions";
import { getStat } from "../../../utils/helpers";
import { adminGetWorkspaceStats } from "~/queries/workspaceQueries";
import { getTransactionStats } from "~/queries/transactionQueries";
import { getSubscriptionStats } from "~/queries/billingsQueries";
export const OwnerDashboard = () => {
  const { user } = useAuth();

  const { data: workspaceStats } = useQuery({
    queryKey: ["workspace", "stats"],
    queryFn: async () => {
      try {
        const data = await adminGetWorkspaceStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { data: transactionStats } = useQuery({
    queryKey: ["transactions", "stats"],
    queryFn: async () => {
      try {
        const data = await getTransactionStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { data: subscriptionStats } = useQuery({
    queryKey: ["subscriptions", "stats"],
    queryFn: async () => {
      try {
        const data = await getSubscriptionStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  return (
    <div className="flex flex-col w-full h-full p-8  ">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-lg text-[#0A0D14] font-medium">
            Welcome {user?.firstName} {user.lastName}!
          </h1>
          <p className="text-sm text-[#525866]">
            Here are the recent stats for your workspace so far.
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-y-10 h-full">
        <div className="w-full max-h-[500px]">
          <Chart />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <div className="bg-white rounded-lg border border-[#E2E4E9] shadow-sm">
            <SummaryCard
              bordered
              change={2}
              period={"30 days"}
              title="Successful Transactions"
              value={
                Array.isArray(transactionStats)
                  ? getStat("success", transactionStats)
                  : 0
              }
            />
            <SummaryCard
              bordered
              change={100}
              period={"30 days"}
              title="Pending Transactions"
              value={
                Array.isArray(transactionStats)
                  ? getStat("pending", transactionStats)
                  : 0
              }
            />
            <SummaryCard
              change={9}
              period={"30 days"}
              title="Failed Transactions"
              value={
                Array.isArray(transactionStats)
                  ? getStat("failed", transactionStats)
                  : 0
              }
            />
          </div>

          <div className="bg-white rounded-lg border border-[#E2E4E9] shadow-sm">
            <SummaryCard
              bordered
              change={2}
              period={"30 days"}
              title="Active Organisations"
              value={
                Array.isArray(workspaceStats)
                  ? getStat("active", workspaceStats)
                  : 0
              }
            />
            <SummaryCard
              bordered
              change={100}
              period={"30 days"}
              title="Pending Organisations"
              value={
                Array.isArray(workspaceStats)
                  ? getStat("pending", workspaceStats)
                  : 0
              }
            />
            <SummaryCard
              change={9}
              period={"30 days"}
              title="Inactive Organisations"
              value={
                Array.isArray(workspaceStats)
                  ? getStat("inactive", workspaceStats)
                  : 0
              }
            />
          </div>

          <div className="bg-white rounded-lg border border-[#E2E4E9] shadow-sm">
            <SummaryCard
              bordered
              change={2}
              period={"30 days"}
              title="Active Subscriptions"
              value={
                Array.isArray(subscriptionStats)
                  ? getStat("active", subscriptionStats)
                  : 0
              }
            />
            <SummaryCard
              bordered
              change={100}
              period={"30 days"}
              title="Inactive Subscriptions"
              value={
                Array.isArray(subscriptionStats)
                  ? getStat("inactive", subscriptionStats)
                  : 0
              }
            />
            <SummaryCard
              change={9}
              period={"30 days"}
              title="Overdue Subscriptions"
              value={
                Array.isArray(subscriptionStats)
                  ? getStat("overdue", subscriptionStats)
                  : 0
              }
            />
          </div>
        </div>
        {/* <div className="flex flex-col gap-y-5 w-full h-full min-h-[400px]"> */}
        {/* <Inbox /> */}
        {/* </div> */}
      </div>
      <GettingStarted />
    </div>
  );
};
