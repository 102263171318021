import React from "react";

interface HtmlViewerProps {
  htmlString: string; // should be a string
  className?: string; // optional string
}

export const HtmlViewer: React.FC<HtmlViewerProps> = ({
  htmlString,
  className,
}) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: htmlString }} // Render HTML safely
    />
  );
};
