import React, { useState } from 'react';
import styles from './styles.module.css';

interface PasswordInputProps {
  label?: string;
  size?: 'small' | 'regular';
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  name: string;
  value: string | undefined;
  autofocus?: boolean;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordInput = ({
  label,
  placeholder,
  readOnly,
  required = false,

  name,
  value,
  autofocus = false,
  handleInputChange,
}: PasswordInputProps) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className={styles.input_container}>
      <label className={styles.input_label} htmlFor={name}>
        {label}
      </label>
      <input
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        required={required}
        id={name}
        readOnly={readOnly}
        autoFocus={autofocus}
        name={name}
        type={show ? 'text' : 'password'}
        className={styles.input_field}
      />
      <button
        type='button'
        className={styles.input_icon}
        onClick={() => setShow(!show)}
      >
        {!show ? <EyeIcon /> : <HideEye />}
      </button>
    </div>
  );
};

const EyeIcon = () => (
  <svg
    width='18'
    height='14'
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.99999 0.25C13.044 0.25 16.4085 3.16 17.1142 7C16.4092 10.84 13.044 13.75 8.99999 13.75C4.95599 13.75 1.59149 10.84 0.885742 7C1.59074 3.16 4.95599 0.25 8.99999 0.25ZM8.99999 12.25C10.5296 12.2497 12.0138 11.7301 13.2096 10.7764C14.4055 9.82264 15.2422 8.49121 15.5827 7C15.2409 5.50998 14.4037 4.18 13.208 3.22752C12.0122 2.27504 10.5287 1.7564 8.99999 1.7564C7.47126 1.7564 5.98776 2.27504 4.79202 3.22752C3.59629 4.18 2.75907 5.50998 2.41724 7C2.75781 8.49121 3.5945 9.82264 4.79035 10.7764C5.9862 11.7301 7.47039 12.2497 8.99999 12.25ZM8.99999 10.375C8.10489 10.375 7.24644 10.0194 6.61351 9.38649C5.98057 8.75355 5.62499 7.89511 5.62499 7C5.62499 6.10489 5.98057 5.24645 6.61351 4.61351C7.24644 3.98058 8.10489 3.625 8.99999 3.625C9.8951 3.625 10.7535 3.98058 11.3865 4.61351C12.0194 5.24645 12.375 6.10489 12.375 7C12.375 7.89511 12.0194 8.75355 11.3865 9.38649C10.7535 10.0194 9.8951 10.375 8.99999 10.375ZM8.99999 8.875C9.49727 8.875 9.97419 8.67746 10.3258 8.32582C10.6774 7.97419 10.875 7.49728 10.875 7C10.875 6.50272 10.6774 6.02581 10.3258 5.67417C9.97419 5.32254 9.49727 5.125 8.99999 5.125C8.50271 5.125 8.0258 5.32254 7.67417 5.67417C7.32254 6.02581 7.12499 6.50272 7.12499 7C7.12499 7.49728 7.32254 7.97419 7.67417 8.32582C8.0258 8.67746 8.50271 8.875 8.99999 8.875Z'
      fill='#868C98'
    />
  </svg>
);

const HideEye = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.95245 4.2436C9.29113 4.19353 9.64051 4.16667 10.0003 4.16667C14.2545 4.16667 17.0461 7.9207 17.9839 9.40569C18.0974 9.58542 18.1542 9.67528 18.1859 9.81389C18.2098 9.91799 18.2098 10.0822 18.1859 10.1863C18.1541 10.3249 18.097 10.4154 17.9827 10.5963C17.7328 10.9918 17.3518 11.5476 16.8471 12.1504M5.6036 5.59586C3.80187 6.81808 2.57871 8.51615 2.01759 9.4044C1.90357 9.58489 1.84656 9.67514 1.81478 9.81373C1.79091 9.91783 1.7909 10.082 1.81476 10.1861C1.84652 10.3247 1.90328 10.4146 2.01678 10.5943C2.95462 12.0793 5.74618 15.8333 10.0003 15.8333C11.7157 15.8333 13.1932 15.223 14.4073 14.3972M2.50035 2.5L17.5003 17.5M8.23258 8.23223C7.78017 8.68464 7.50035 9.30964 7.50035 10C7.50035 11.3807 8.61963 12.5 10.0003 12.5C10.6907 12.5 11.3157 12.2202 11.7681 11.7678'
      stroke='#667085'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
