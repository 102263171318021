/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl =
  import.meta.env.VITE_BASE_URL || "https://platform.api.wholistic.work";

export const getMyRole = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/profile/role`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getNotifications = async (params?: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/profile/notifications`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const readNotifications = async (notifications?: string[]) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/profile/notifications/read`,
    headers,
    data: { notifications },
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getNotificationStats = async (params?: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/profile/notifications/stats`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const uploadProfile = async (data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/profile/uploads`,
    data,
    headers: { ...headers, "Content-Type": "multipart/formdata" },
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const pushNotificationToken = async (token: any, deviceId: any) => {
  const config = {
    method: "PUT",
    url: `${baseUrl}/profile/device/${deviceId}`,
    data: { notificationToken: token },
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
