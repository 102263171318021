/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from 'framer-motion';
export const AnimatedLoader = () => {
  const circles = [
    { initialX: -50, initialScale: 0.5 },
    { initialX: 0, initialScale: 1 },
    { initialX: 50, initialScale: 0.5 },
  ];
  const transition: any = {
    duration: 1.5,
    ease: 'easeInOut',
    repeat: Infinity,
    repeatType: 'loop',
  };
  return (
    <div className='w-full h-full grid place-items-center  '>
      <motion.div className='flex items-center justify-center relative gap-x-3'>
        {circles.map((circle, index) => (
          <motion.div
            key={index}
            className='h-5 w-5 rounded-full bg-[#4da3ff]'
            initial={{ x: circle.initialX, scale: circle.initialScale }}
            animate={{
              x: [
                circle.initialX,
                circle.initialX === -50 ? 50 : -50,
                circle.initialX,
              ],
              scale: [circle.initialScale, 1, circle.initialScale],
            }}
            transition={{
              ...transition,
              delay: index * 0.5, // Adjust delay to stagger the animations
            }}
          />
        ))}
      </motion.div>
    </div>
  );
};
