import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Group } from "~/components";
import Loader from "~/components/UI/Loader";
import { billingPeriod } from "~/utils/dateUtils";

import { getTransaction } from "~/queries/transactionQueries";
export const TransactionPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: transaction, isLoading } = useQuery<Transaction>({
    queryKey: ["transaction", id],
    queryFn: async () => {
      try {
        const data = await getTransaction(id);

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  }); // eslint-disable-next-line prefer-const
  // let isLoading = false;

  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full h-full flex flex-col overflow-scroll p-8">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Back to Transactions</span>
        </button>
      </div>
      <Group key="header">
        <div className="flex items-center w-full justify-between mt-6">
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <h1 className="text-[#0A0D14] font-medium text-lg">
                Transaction ID: {transaction?.id}
              </h1>
            </div>
          </div>
        </div>
      </Group>
      <div className="flex flex-col mt-6 gap-y-5 w-full h-full">
        {" "}
        <Group key="Organisation details">
          <div className="bg-white border py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm ">
            <header className="w-full flex items-center justify-between border-b pb-4">
              <span className="font-medium">Organisation details</span>
            </header>
            <div className="grid grid-cols-3 gap-5 mt-4">
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Organisation Name</div>
                <div className="text-sm text-[#0A0D14]">
                  {transaction?.workspace?.name}
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Official Email</div>
                <div className="text-sm text-[#0A0D14]">
                  {transaction?.user?.email}
                </div>
              </div>
              {/* <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">
                  Official Phone Number
                </div>
                <div className="text-sm text-[#0A0D14]">080129291098</div>
              </div> */}
            </div>
          </div>
        </Group>
        <Group key="Subscription details">
          <div className="bg-white border py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm ">
            <header className="w-full flex items-center justify-between border-b pb-4">
              <span className="font-medium">Subscription details</span>
            </header>
            <div className="grid grid-cols-4 gap-5 mt-4">
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Plan Name</div>
                <div className="text-sm text-[#0A0D14]">
                  {transaction?.subscription?.plan.name}
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Cost</div>
                <div className="text-sm text-[#0A0D14]">
                  {transaction?.amount + (transaction?.currency || "")}
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Billing Cycle</div>
                <div className="text-sm text-[#0A0D14]">
                  {transaction?.subscription?.plan.interval}
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Billing Period</div>
                <div className="text-sm text-[#0A0D14]">
                  {billingPeriod(transaction)}
                </div>
              </div>
            </div>
          </div>
        </Group>
        <Group key="Subscription details">
          <div className="bg-white border py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm ">
            <header className="w-full flex items-center justify-between border-b pb-4">
              <span className="font-medium">Transaction details</span>
            </header>
            <div className="flex gap-20 mt-4">
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Transaction Ref</div>
                <div className="text-sm text-[#0A0D14] word-break:break-word">
                  {transaction?.reference}
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-xs text-[#525866]">Transaction Amount</div>
                <div className="text-sm text-[#0A0D14]">
                  {transaction?.amount + (transaction?.currency || "")}
                </div>
              </div>
            </div>
          </div>
        </Group>
      </div>
    </div>
  );
};
