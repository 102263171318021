import { getPasswordStrength } from "~/utils/passwordHelpers";
import { StrengthCheck } from "../StrengthCheck";

export const PasswordHandler = ({
  password,
  confirmPassword,
}: {
  password: string;
  confirmPassword?: string;
}) => {
  return (
    <>
      <div className="mt-3 flex items-center gap-x-2">
        <StrengthCheck
          key="one"
          pass={getPasswordStrength(password).strength >= 1}
          strength={getPasswordStrength(password).strength}
        />
        <StrengthCheck
          key="two"
          pass={getPasswordStrength(password).strength >= 2}
          strength={getPasswordStrength(password).strength}
        />
        <StrengthCheck
          key="three"
          pass={getPasswordStrength(password).strength >= 3}
          strength={getPasswordStrength(password).strength}
        />
        <StrengthCheck
          key="four"
          pass={getPasswordStrength(password).strength >= 4}
          strength={getPasswordStrength(password).strength}
        />
        <StrengthCheck
          key="four"
          pass={getPasswordStrength(password).strength >= 5}
          strength={getPasswordStrength(password).strength}
        />
      </div>
      <span className="text-xs text-[#525866] mt-2">
        {getPasswordStrength(password).strengthDescription}
      </span>
      {(!!getPasswordStrength(password).failedTests.length) && (
        <span className="text-xs text-[#525866] mt-2">
          Must {getPasswordStrength(password).failedTests.map(f => f.description).join(', ')}
        </span>
      )}
      {!getPasswordStrength(password).failedTests.length &&
        confirmPassword !== undefined &&
        password !== confirmPassword && (
          <span className="text-xs text-[#525866] mt-2">
            Confirm password must match the password
          </span>
        )}
    </>
  );
};
