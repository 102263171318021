/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getClients } from "~/queries/clientQueries";
import { getIntegrations } from "~/queries/integrationQueries";
import { getProjectUsers } from "~/queries/projectQueries";
import { getRoles } from "~/queries/roleQueries";
import { getUsers } from "~/queries/userQueries";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";

export const useRoles = () => {
  const list: Array<{ value: string; label: string }> = [];
  const { data, isLoading, isError } = useQuery({
    enabled: isActionAllowed("list-roles"),
    queryKey: ["roles", "list"],
    queryFn: async () => {
      try {
        const data = await getRoles();
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
  });
  if (isError) {
    return { list };
  } else {
    const getSelectData = (
      data: Array<{ name: string; id: string; status: string }>
    ) => {
      const list: Array<{ value: string; label: string }> = [];
      if (!data?.length) return [];
      data
        ?.filter((d) => d.status == "active")
        ?.map((item) => list.push({ value: item.id, label: item.name }));
      return list;
    };

    return {
      list: isLoading ? [] : getSelectData(data?.data),
      data: data?.data || [],
    };
  }
};
export const useClients = () => {
  const list: Array<{ value: string; label: string }> = [];
  const { data, isLoading, isError } = useQuery({
    enabled: isActionAllowed("list-clients"),
    queryKey: ["clients", "list"],
    queryFn: async () => {
      try {
        const data = await getClients({ page: 1, limit: 100 });
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
  });
  if (isError) {
    return { list };
  } else {
    const getSelectData = (data: Array<{ name: string; id: string }>) => {
      const list: Array<{ value: string; label: string }> = [];
      if (!data?.length) return [];
      data?.map((item) => list.push({ value: item.id, label: item.name }));
      return list;
    };

    return {
      list: isLoading ? [] : getSelectData(data?.data),
    };
  }
};
export const useUsers = () => {
  const list: Array<{ value: string; label: string }> = [];
  const { data, isLoading, isError } = useQuery({
    enabled: isSubscribedFor("teams") && isActionAllowed("list-team-members"),
    queryKey: ["users", "list"],
    queryFn: async () => {
      try {
        const data = await getUsers({ page: 1, limit: 1000 });
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
  });
  if (isError) {
    return { list };
  } else {
    const getSelectData = (data: Array<User>) => {
      const list: Array<{ value: string; label: string }> = [];
      if (!data?.length) return [];
      data.map((item) =>
        list.push({
          value: item.id,
          label:
            `${item.profile.firstName} ${item.profile.lastName}`.trim() ||
            item.email,
        })
      );
      return list;
    };

    return {
      list: isLoading ? [] : getSelectData(data?.data),
      data: data?.data,
    };
  }
};
export const useProjectUsers = (id: any) => {
  const list: Array<{ value: string; label: string }> = [];
  const { data, isLoading, isError } = useQuery({
    enabled: isActionAllowed("list-projects"),
    queryKey: ["project-users", "list"],
    queryFn: async () => {
      try {
        const data = await getProjectUsers(id);
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
  });
  if (isError) {
    return { list };
  } else {
    const getSelectData = (data: Array<User>) => {
      const list: Array<{ value: string; label: string }> = [];
      if (!data?.length) return [];
      data.map((item) =>
        list.push({
          value: item.id,
          label: `${item.profile.firstName} ${item.profile.lastName}`,
        })
      );
      return list;
    };

    return {
      list: isLoading ? [] : getSelectData(data),
      data: data,
    };
  }
};
export const useApps = () => {
  const list: Array<{ value: string; label: string }> = [];
  const { data, isLoading, isError } = useQuery({
    enabled: isActionAllowed("list-integrations"),
    queryKey: ["apps", "list"],
    queryFn: async () => {
      try {
        const data = await getIntegrations();
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
    ...{
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  });
  if (isError) {
    return { list };
  } else {
    const getSelectData = (data: Array<Integration>) => {
      const list: Array<{ value: string; label: string }> = [];
      if (!data?.length) return [];
      data
        ?.filter((d) => d.authorized)
        .map((item) =>
          list.push({
            value: item.id,
            label: item.name,
          })
        );
      return list;
    };

    return {
      list: isLoading ? [] : getSelectData(data?.data),
      data: data?.data,
    };
  }
};
