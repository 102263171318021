import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;

export const updateSettings = async (data: any) => {
  const config = {
    method: "PUT",
    url: `${baseUrl}/platform/settings`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
