import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Button, CopyButton, TextInput } from "~/components";
import { useForm } from "~/hooks";
import { setIntegration } from "~/mutations/integrationMutations";

export const Connect = ({
  close,
  details,
  refetch,
}: {
  close?: () => void;
  details: Integration;
  refetch: () => void;
}) => {
  const qc = useQueryClient();
  const { formData, update } = useForm({
    initial: {
      integration: details.slug,
      clientId: "",
      clientSecret: "",
      redirectUri: ["openai", "openai-embeddings"].includes(details.slug)
        ? "api.openai.com/v1"
        : `${new URL(window.origin).hostname}/close/${details.slug}`,
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      setIntegration({
        ...formData,
        redirectUri: `https://${formData.redirectUri}`,
      }),
    ...{
      onSuccess() {
        toast.success("App connected successfully");
        refetch();
        qc.invalidateQueries({ queryKey: ["integrations", "connections"] });
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  const getLabel = (name: string) => {
    if (details.slug === "openai") {
      if (name === "clientSecret") return "API Key";
      if (name === "clientId") return "Model Name";
      if (name == "redirectUri") return "Base URI";
      return;
    }
    if (details.slug === "openai-embeddings") {
      if (name === "clientSecret") return "API Key";
      if (name === "clientId") return "Model Name";
      if (name == "redirectUri") return "Base URI";
      return;
    }

    if (name == "clientId") return "Client ID";
    if (name == "clientSecret") return "Client Secret";
    if (name == "redirectUri") return "Redirect URI";
    return;
  };
  return (
    <div className="w-full flex flex-col">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">{details.name}</h4>
          <span className="text-sm text-[#525866]">{details.description}</span>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5">
        {!!getLabel("clientId") && (
          <TextInput
            label={getLabel("clientId")}
            value={formData.clientId}
            name="clientId"
            handleInputChange={update}
          />
        )}
        {!!getLabel("clientSecret") && (
          <TextInput
            label="Client Secret"
            value={formData.clientSecret}
            name="clientSecret"
            handleInputChange={update}
          />
        )}
        {!!getLabel("redirectUri") && (
          <div className="flex flex-col">
            <label
              htmlFor="redirectUri"
              className="text-sm relative font-medium text-[#0A0D14]"
            >
              {getLabel("redirectUri")}
            </label>
            <div className="flex items-center gap-x-3">
              <div className="border border-[#E2E4E9] rounded-xl flex items-center mt-1 relative shrink-0 flex-1">
                <span className="border-r px-3 py-[10px] text-[#868C98] text-sm shrink-0">
                  https://
                </span>
                <input
                  name="redirectUri"
                  placeholder="www.example.com"
                  readOnly={
                    !["openai", "openai-embeddings"].includes(details.slug)
                  }
                  value={formData.redirectUri}
                  onChange={update}
                  className={`px-3 py-[10px] ${
                    !["openai", "openai-embeddings"].includes(details.slug)
                      ? "bg-[#E2E4E9]/70"
                      : ""
                  } text-sm outline-none w-full rounded-r-xl text-[#525866]`}
                />
              </div>
              <CopyButton value={`https://${formData.redirectUri}`} />
            </div>
          </div>
        )}
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        <Button
          label="Connect app"
          effect={mutate}
          loading={isPending}
          disabled={!formData.clientId || !formData.clientSecret}
        />
      </footer>
    </div>
  );
};
