/* eslint-disable @typescript-eslint/no-explicit-any */

export const SummaryCard = ({
  title,
  value,
  change,
  period,
  bordered,
}: {
  title: string;
  value: any;
  change: any;
  period: any;
  bordered?: boolean;
}) => {
  return (
    <div
      style={{ borderRight: bordered ? '1px solid ##E2E4E9' : undefined }}
      className='flex flex-col p-4  '
    >
      <span className='text-sm text-[#0E121B] font-medium'>{title}</span>
      <h2 className='text-[40px] font-medium mt-auto text-[#0E121B]'>
        {value}
      </h2>
      <span className='text-sm text-[#99A0AE]'>
        From {change} (last {period})
      </span>
    </div>
  );
};
