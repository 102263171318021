/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;
export const getProjectStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getUserStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/users/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getProjectHealthStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/health/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getProjectReportStats = async (id: string) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}/reports/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
