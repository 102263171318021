import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "~/components/UI/Loader";
import { authorizeProvider } from "~/mutations/authMutations";
import { useAuth } from "~/providers";

export const Complete = () => {
  const { setStoredToken } = useAuth();
  const navigate = useNavigate();
  const code = useSearchParams()[0].get("code") || "";
  const { provider } = useParams();
  const { mutate, isPending } = useMutation({
    mutationFn: async () =>
      authorizeProvider(provider!, {
        code,
        token: localStorage.getItem("who_auth_token"),
      }),
    onSuccess(data) {
      setStoredToken(data);
      navigate("/choose");
      localStorage.removeItem("who_auth_token");
    },
    onError(error) {
      localStorage.removeItem("who_auth_token");
      toast.error(error.message);
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  return isPending ? <Loader big /> : <div>Complete</div>;
};
