/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getShortDayName = () => {
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const today = new Date().getDay();
  return days[today];
};
export const getFormattedDate = (date: string | undefined) => {
  if (!date) return "";
  return dayjs(date).format("DD MMMM YYYY");
};
export const getDateandTime = (date: string | undefined) => {
  if (!date) return "";
  return dayjs(date).format("LLL");
};

export const getCommentTime = (date: string) => {
  const formattedDateTime = dayjs(date).format("dddd, h:mm A");
  return formattedDateTime;
};

export type TimeUnit = "ms" | "secs" | "mins" | "hours" | "days";

export const convertToMilliseconds = (
  value: number,
  unit: TimeUnit
): number => {
  switch (unit) {
    case "days":
      return value * 24 * 3600 * 1000;
    case "hours":
      return value * 3600 * 1000;
    case "mins":
      return value * 60 * 1000;
    case "secs":
      return value * 1000;
    default:
      return value;
  }
};

export const billingPeriod = (workspace: any) => {
  const createdAt = workspace?.subscription?.createdAt
    ? new Date(workspace.subscription.createdAt)
    : new Date(); // Fallback to current date if undefined
  const interval = workspace?.subscription?.plan?.interval;

  const endDate = new Date(createdAt);
  if (interval === "monthly") {
    endDate.setMonth(endDate.getMonth() + 1);
  } else if (interval === "yearly") {
    endDate.setFullYear(endDate.getFullYear() + 1);
  }

  return `${createdAt.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })} - ${endDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })}`;
};

export const convertToDisplayValue = (
  value: number
): { value: number; unit: TimeUnit } => {
  if (value % (24 * 3600 * 1000) === 0)
    return { value: value / (24 * 3600 * 1000), unit: "days" };
  if (value % (3600 * 1000) === 0)
    return { value: value / (3600 * 1000), unit: "hours" };
  if (value % (60 * 1000) === 0)
    return { value: value / (60 * 1000), unit: "mins" };
  if (value % 1000 === 0) return { value: value / 1000, unit: "secs" };
  return { value, unit: "ms" };
};
export class TimeUtils {
  static minToSec(minute: number) {
    return minute * 60;
  }

  static secToMin(second: number) {
    return second / 60;
  }

  static hourToSec(hour: number) {
    return hour * 3600;
  }

  static secToHour(second: number) {
    return second / 3600;
  }

  static minToHour(minute: number) {
    return minute / 60;
  }

  static hourToMin(hour: number) {
    return hour * 60;
  }

  static dayToSec(day: number) {
    return day * 86400;
  }

  static secToDay(second: number) {
    return Math.floor(second / 86400);
  }

  static dayToHour(day: number) {
    return day * 24;
  }

  static hourToDay(hour: number) {
    return hour / 24;
  }

  static dayToMin(day: number) {
    return day * 1440;
  }

  static minToDay(minute: number) {
    return minute / 1440;
  }

  static minHourDisplay(x: number) {
    if (x >= 86400) {
      return "day";
    } else if (x >= 3600) {
      return "hour";
    } else if (x >= 60) {
      return "min";
    } else {
      return "sec";
    }
  }
}
