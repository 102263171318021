/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;
export const createWorkspace = async (data: {
  name?: string;
  slug?: string;
  size?: string;
  utmMedium?: string;
  integrations: string[];
  purpose?: string[];
  serviceTypes: string[];
}) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/workspaces`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const deleteWorkspace = async (id?: string) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/platform/workspaces/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const updateWorkspaceStatus = async (
  id: string,
  data: { status: string }
) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/platform/workspaces/${id}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const exportWorkspaces = async (data: { format: string }) => {
  const config: any = {
    method: "POST",
    url: `${baseUrl}/platform/workspaces/export`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
    responseType: "arraybuffer",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const exportWorkspacesPDF = async (data: { format: string }) => {
  const config: any = {
    method: "POST",
    url: `${baseUrl}/platform/workspaces/export`,
    headers: { ...headers, "Content-Type": "application/pdf" },
    data,
    withCredentials: true,
    withXSRFToken: true,
    responseType: "arraybuffer",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
