/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;

export const subscribeToPlan = async (id: any, data: { currency: string }) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/billings/plans/${id}/subscribe`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const verifySubscription = async (id: any, reference: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/billings/plans/${id}/subscribe/${reference}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const setPlanFeatures = async (id: any, data: any) => {
  const config = {
    method: "PUT",
    url: `${baseUrl}/billings/plans/${id}/features`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const updatePlanDetails = async (id: any, data: any) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/billings/plans/${id}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const updatePlanStatus = async (
  id: string,
  data: { status: string }
) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/billings/plans/${id}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const deletePlan = async (
  id: string
  // data: { status: string }
) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/billings/plans/${id}`,
    headers,
    // data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const createSubscriptionPlan = async (data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/billings/plans`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const exportTransactions = async (data: { format: string }) => {
  const config: any = {
    method: "POST",
    url: `${baseUrl}/billings/transactions/export`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
    responseType: "arraybuffer",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
