/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { RefObject } from 'react';
import styles from './styles.module.css';
import { motion } from 'framer-motion';
interface TextInputProps {
  label?: string;
  required?: boolean;
  error?: any;
  name: string;
  value: string | number | undefined;
  refObject?: RefObject<HTMLInputElement>;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  props?: TextInputProps;
  readOnly?: boolean;
}
export const ColorInput = ({
  label,
  name,
  error,
  value = '',
  readOnly,
  handleInputChange,
  ...props
}: TextInputProps) => {
  return (
    <div className={styles.input_container}>
      {label && (
        <label className={styles.input_label} htmlFor={name} title={label}>
          {label}
        </label>
      )}
      <div className={styles.input_field}>
        <input
          onChange={handleInputChange}
          value={value}
          type={'color'}
          id={name}
          name={name}
          style={{ borderColor: error ? '#EC5757' : undefined }}
          readOnly={readOnly}
          className='rounded-md p-1'
          {...props}
        />
        <span>{value}</span>
      </div>

      {error && (
        <motion.span
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: error ? 1 : 0, height: error ? 1 : 0 }}
          className='text-xs text-[#EC5757] capitalize block'
        >
          {error ? error ?? ' 7s' : '7s'}
        </motion.span>
      )}
    </div>
  );
};
