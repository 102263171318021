import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '~/components';
import { initForgotPassword } from '~/mutations/authMutations';
import { AuthScreen } from '~/screens';

export const ForgotVerifyPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: () => initForgotPassword({ email: state.email }),
    ...{
      onSuccess() {
        toast.success(
          `An email with instructions to reset your password has been sent to ${state.email}`
        );
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  return (
    <AuthScreen icon='/auth/mail.svg'>
      <div className='flex flex-col w-full'>
        <button
          onClick={() => navigate(-1)}
          className='flex items-center gap-x-2 text-[#0A0D14] font-medium'
        >
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z'
              fill='#525866'
            />
          </svg>
          <span>Go back</span>
        </button>
        <section className='flex flex-col'>
          <h1 className='text-[#0A0D14] text-[32px] font-medium'>
            You've got mail!
          </h1>
          <span className='text-sm text-[#525866]'>
            We sent a email to{' '}
            <span className='underline text-[#0A0D14]'>{state.email}</span> with
            a link to verify your email address.
          </span>
        </section>

        <section className='mt-8 flex flex-col gap-y-2'>
          <span className='text-sm text-[#525866]'>Didn't get a mail?</span>
          <Button label='Resend email' effect={mutate} loading={isPending} />
        </section>
      </div>
    </AuthScreen>
  );
};
