/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChangePassword, Settings } from "~/actions";
import { dropdown } from "~/constants/animations";
import { useOutsideClick } from "~/hooks";
import { openModal, useAuth } from "~/providers";
import { getWorkspaces } from "~/queries/workspaceQueries";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
// import { openModal } from '~/providers';

export const ProfileDropdown = ({ isCollpased }: { isCollpased: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sideOpen, setSideOpen] = useState(false);
  const { workspace, user, role } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const popup = openModal();
  const navigate = useNavigate();
  const containerRef = useOutsideClick<HTMLDivElement>(handleClickOutside);
  const switchRef = useOutsideClick<HTMLDivElement>(handleClickOutsideSwicth);
  const qc = useQueryClient();

  // useEffect(() => {
  //   console.log({searchParams})
  //   if (searchParams.get("setting")) {
  //     popup({ component: <Settings /> });
  //   }
  // }, [searchParams])

  const { data: workspaces } = useQuery<Workspace[]>({
    queryKey: ["workspaces"],
    queryFn: async () => {
      try {
        const data = await getWorkspaces();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    ...{
      refetchOnWindowFocus: false,
    },
  });
  function handleClickOutside(e: any) {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }
  function handleClickOutsideSwicth(e: any) {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setSideOpen(false);
    }
  }
  const handleOpenSettings = (setting: string) => {
    setSearchParams(() => {
      searchParams.set("setting", setting);
      return searchParams;
    });

    popup({ component: <Settings /> });
  };
  return (
    <div className="relative w-full">
      <button
        className="flex items-center gap-x-2 hover:bg-[#F6F8FA] rounded-lg p-3 w-full transition-colors duration-200 ease-in-out"
        onClick={() => setIsOpen(true)}
      >
        <span className="w-10 h-10 rounded flex-shrink-0 bg-[#E255F2] overflow-clip flex items-center justify-center font-medium text-white uppercase">
          {workspace?.name?.split(" ")?.[0]?.[0]}
          {workspace?.name?.split(" ")?.[1]?.[0]}
        </span>

        {!isCollpased && (
          <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "auto", opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex items-center gap-x-2 w-full flex-grow justify-between"
          >
            <div className="flex flex-col items-start tracking-normal gap-y-1">
              <span className="font-medium text-sm text-[#0A0D14] capitalize overflow-ellipsis">
                {workspace?.name}
              </span>
              {workspace.slug !== "admin" && (
                <span
                  style={{
                    color: workspace?.subscription
                      ? workspace?.subscription?.plan?.name?.toLowerCase() ==
                        "free"
                        ? "#B47818"
                        : "#2D9F75"
                      : "#B47818",
                    borderColor: workspace?.subscription
                      ? workspace?.subscription?.plan?.name?.toLowerCase() ==
                        "free"
                        ? "#B47818"
                        : "#2D9F75"
                      : "#B47818",
                  }}
                  className=" py-[2px] px-2 border rounded-full font-medium text-[11px] uppercase flex items-center gap-x-1"
                >
                  <img
                    src={
                      workspace.subscription
                        ? workspace.subscription?.plan?.name.toLowerCase() ==
                          "free"
                          ? "/leaf.svg"
                          : "/bolt.svg"
                        : "/leaf.svg"
                    }
                  />
                  {workspace.subscription?.plan?.name ?? "Free Plan"} Plan
                </span>
              )}
            </div>
            <svg
              width="13"
              height="7"
              viewBox="0 0 13 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-auto block"
            >
              <path
                d="M12 1L7.47222 5.59317C6.9375 6.13561 6.0625 6.13561 5.52778 5.59317L1 1"
                stroke="#1D1D1D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </motion.div>
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            onMouseDown={(e) => e.stopPropagation()}
            variants={dropdown}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute z-10"
            ref={containerRef}
          >
            <div className="flex flex-col bg-white shadow-md mt-[6px] px-3 pt-3 pb-4 w-full rounded-xl right-0 border border-[#E2E4E9]">
              <span className="text-xs font-medium text-[#868C98]">
                {user?.user?.email}
              </span>
              <div className="border-b flex flex-col pb-2 mt-1">
                <button
                  onMouseDown={() => handleOpenSettings("profile")}
                  className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                >
                  <img src="/settings/profile.svg" />
                  <span>Profile</span>
                </button>
                <button
                  onMouseDown={() => popup({ component: <ChangePassword /> })}
                  className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                >
                  <img src="/settings/password.svg" />
                  <span>Change password</span>
                </button>
                {isSubscribedFor("workspace") && (
                  <button
                    onMouseDown={() =>
                      handleOpenSettings(
                        role?.slug == "project-team-member"
                          ? "profile"
                          : "general"
                      )
                    }
                    className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                  >
                    <img src="/settings/settings.svg" />
                    <span>Settings</span>
                  </button>
                )}
                <button
                  onMouseDown={() => navigate("/signup/onboarding")}
                  className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                >
                  <img src="/settings/plus.svg" />
                  <span>New Workspace</span>
                </button>
              </div>
              <div className="border-b flex flex-col pb-2 mt-1">
                {workspaces && workspaces?.length > 1 && (
                  <div className="relative w-full">
                    <button
                      onClick={() => setSideOpen(true)}
                      className="flex items-center w-full justify-between py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                    >
                      <div className="flex items-center gap-x-[6px] ">
                        <img src="/settings/switch.svg" />
                        <span>Switch Workspace</span>
                      </div>
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.79575 4.99956L0.083252 1.28706L1.14375 0.226562L5.91675 4.99956L1.14375 9.77256L0.083252 8.71206L3.79575 4.99956Z"
                          fill="#525866"
                        />
                      </svg>
                      <AnimatePresence>
                        {sideOpen && (
                          <motion.div
                            onMouseDown={(e) => e.stopPropagation()}
                            variants={dropdown}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            className="absolute bg-white shadow-md flex flex-col px-3 pt-3 pb-4 w-full rounded-xl -right-[108%] z-10 border border-[#E2E4E9]"
                            ref={switchRef}
                          >
                            <div className="flex flex-col w-full">
                              <span className="text-xs text-[#868C98] w-fit">
                                Switch Workspace
                              </span>
                              <div className="flex flex-col gap-y-1 mt-1">
                                {workspaces?.map((ws) => (
                                  <button
                                    disabled={workspace.id == ws.id}
                                    onClick={() =>
                                      (window.location.href = `https://${ws.domain}:${window.location.port}/app`)
                                    }
                                    className="flex items-center justify-between gap-x-3 p-2 hover:bg-[#F6F8FA] transition duration-200 ease-in-out "
                                  >
                                    <div className="flex items-center gap-x-3">
                                      <span className="w-5 h-5 rounded shrink-0 bg-[#E255F2] text-[10px] overflow-clip flex items-center justify-center font-medium text-white uppercase">
                                        {ws?.name?.split(" ")?.[0]?.[0]}
                                        {ws?.name?.split(" ")?.[1]?.[0]}
                                      </span>
                                      <span className="capitalize truncate text-sm text-[#525866]">
                                        {ws.name}
                                      </span>
                                    </div>
                                    {workspace.id == ws.id && (
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className="shrink-0"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_2223_48059)">
                                          <rect
                                            width="20"
                                            height="20"
                                            rx="10"
                                            fill="white"
                                          />
                                          <path
                                            d="M8.50005 12.3791L15.3941 5.48438L16.4553 6.54488L8.50005 14.5001L3.72705 9.72712L4.78755 8.66663L8.50005 12.3791Z"
                                            fill="#0A0D14"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_2223_48059">
                                            <rect
                                              width="20"
                                              height="20"
                                              rx="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    )}
                                  </button>
                                ))}
                              </div>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </button>
                  </div>
                )}
                {isActionAllowed("update-workspace") &&
                  isSubscribedFor("workspace") && (
                    <button
                      onMouseDown={() => handleOpenSettings("general")}
                      title="workspace-preferences"
                      className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                    >
                      <img src="/settings/workspace.svg" />
                      <span className="tracking-[-0.6%] truncate">
                        Workspace preferences
                      </span>
                    </button>
                  )}
                {isActionAllowed("add-team-member") &&
                  isSubscribedFor("teams") && (
                    <button
                      onMouseDown={() => handleOpenSettings("members")}
                      className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                    >
                      <img src="/settings/team.svg" />
                      <span>Manage Team</span>
                    </button>
                  )}
                {isActionAllowed("subscribe-to-subscription-plan") &&
                  workspace.slug !== "admin" && (
                    <button
                      onMouseDown={() => handleOpenSettings("plans")}
                      className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
                    >
                      <img src="/settings/plan.svg" />
                      <span>Change Plan</span>
                    </button>
                  )}
              </div>
              <a
                href="https://wholistic.webflow.io/#faqs"
                target="_blank"
                className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866] mt-1"
              >
                <img src="/settings/faqs.svg" />

                <span>FAQs</span>
              </a>
              <a
                href="https://wholistic.webflow.io/legal/terms-of-use"
                target="_blank"
                className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
              >
                <img src="/settings/terms.svg" />

                <span>Terms of Use</span>
              </a>
              <a
                href="https://wholistic.webflow.io/legal/privacy-policy"
                target="_blank"
                className="flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#525866]"
              >
                <img src="/settings/terms.svg" />

                <span>Privacy Policy</span>
              </a>
              <button
                onMouseDown={() => {
                  qc.clear();
                  navigate("/");
                }}
                className="mt-2 flex items-center gap-x-[6px] py-2 px-1 rounded hover:bg-[#F6F8FA] transition duration-200 ease-in-out text-sm text-[#DF1C41]"
              >
                <img src="/settings/logout.svg" />

                <span>Logout</span>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
