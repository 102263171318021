/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { sendProjectReport } from "~/api";
import { Button, TextInput } from "~/components";
import { useForm } from "~/hooks";
import dayjs from "dayjs";

export const SendEmail = ({
  projectId,
  reportId,
  close,
  users = [],
}: {
  projectId: string;
  reportId: string;
  close?: () => void;
  users?: User[];
}) => {
  const [emails, setEmails] = useState<any>(users.map((user) => user.email));

  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const { formData, update } = useForm({
    initial: { sentAt: dayjs().format("YYYY-MM-DDTHH:mm") },
  });

  const handleInputKeyDown = (e: any) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      setEmails([...emails, inputValue.trim()]);
      setInputValue("");
    } else if (
      e.key === "Backspace" &&
      inputValue === "" &&
      emails.length > 0
    ) {
      setEmails(emails.slice(0, -1));
    }
  };

  const removeService = (index: any) => {
    setEmails(emails.filter((_: any, i: any) => i !== index));
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      sendProjectReport(projectId, reportId, {
        emails,
        sentAt: dayjs(formData.sentAt).toISOString(),
      }),
    ...{
      onSuccess() {
        if (formData.sentAt && dayjs(formData.sentAt).isAfter(dayjs())) {
          toast.success(`Email scheduled succesfully`);
        } else {
          toast.success(`Email sent succesfully`);
        }
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  return (
    <div className="w-full flex flex-col  max-w-[440px]">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Send Email</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5 ">
        <div
          ref={containerRef}
          onClick={handleContainerClick}
          className="w-full min-h-[100px] max-h-[120px] py-[10px] px-3 border border-[#E2E4E9] rounded-xl overflow-y-auto flex flex-wrap items-start content-start gap-2"
        >
          {emails.map((service: any, index: any) => (
            <div
              key={index}
              className="flex items-center bg-white border border-[#E2E4E9] text-xs rounded-[6px] px-[5px] py-1 text-[#525866]"
            >
              <span className="mr-1">{service}</span>
              <button
                onClick={() => removeService(index)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 6.22234L9.72234 3.5L10.5 4.27766L7.77766 7L10.5 9.72234L9.72234 10.5L7 7.77766L4.27766 10.5L3.5 9.72234L6.22234 7L3.5 4.27766L4.27766 3.5L7 6.22234Z"
                    fill="#868C98"
                  />
                </svg>
              </button>
            </div>
          ))}
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className="flex-grow min-w-[30px] outline-none text-sm placeholder:text-[#CDD0D5] text-[#525866]"
            placeholder={
              emails.length === 0
                ? 'Start typing emails and press "Enter" to save'
                : ""
            }
          />
        </div>
        <TextInput
          label="Send On"
          value={formData.sentAt}
          name="sentAt"
          type="datetime-local"
          handleInputChange={update}
        />
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        <Button
          label="Send Email"
          effect={mutate}
          loading={isPending}
          disabled={emails.length === 0}
        />
      </footer>
    </div>
  );
};
