import { motion } from 'framer-motion';
export const Choice = ({
  choice,
  setChoice,
}: {
  choice: string;
  setChoice: (v: string) => void;
}) => {
  const panes = [{ label: 'Monthly' }, { label: 'Yearly' }];

  return (
    <ul className='flex items-center rounded-lg w-full bg-[#F1F1F4] max-w-[215px] justify-around text-center p-1'>
      {panes.map((pane) => (
        <li key={pane.label} className='py-1 relative w-full'>
          {choice == pane.label && (
            <motion.div
              className='absolute bg-white top-0 bottom-0 left-0 w-full rounded-lg'
              layoutId='choice underline'
            ></motion.div>
          )}
          <button
            type='button'
            onClick={() => setChoice(pane.label)}
            key={pane.label}
            className='relative z-[12px]'
          >
            <span
              style={{
                color: choice == pane.label ? '#0A0D14' : '#868C98',
              }}
              className='transition duration-200 ease-in-out font-medium text-sm'
              // style={{ marginLeft: pane.label == 'Pooler' ? -38 : 0 }}
            >
              {pane.label}
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};
