/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { Header, TextPanes } from "~/components";
import Loader from "~/components/UI/Loader";
import { usePanes } from "~/hooks";
import {
  getIntegrations,
  getIntegrationStats,
} from "~/queries/integrationQueries";
import { IntegrationCard } from "./IntegrationCard";
import { isActionAllowed } from "~/utils/authHelper";
export const ListView = ({ change }: { change: (v: Integration) => void }) => {
  const searchParams = useSearchParams()[0];

  const active = searchParams.get("show") || "";

  const { show, handlePaneSwitch } = usePanes(active, true);
  const {
    data: integrations,
    isLoading,
    refetch,
  } = useQuery<ListResponse<Integration>>({
    queryKey: ["integrations", { active }],
    queryFn: async () => {
      try {
        const data = await getIntegrations({
          integrated: !active ? undefined : active === "connected",
        });
        return data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
  });

  const { data: integrationStats } = useQuery({
    queryKey: ["integrations", "stats"],
    queryFn: async () => {
      try {
        const data = await getIntegrationStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    enabled: isActionAllowed("list-integrations"),
  });

  const getIntegrationStat = (key: string) => {
    if (!integrationStats) return "";
    const [stat] = integrationStats.filter((st: any) => st.value == key);
    return stat && stat.count ? stat?.count : 0;
  };

  const panes = [
    {
      id: "",
      label: "All apps",
      show: true,
      value:
        Number(getIntegrationStat("connected")) +
        Number(getIntegrationStat("disconnected")),
    },
    {
      id: "connected",
      label: "Connected",
      show: true,
      value: getIntegrationStat("connected"),
    },
    {
      id: "disconnected",
      label: "Disconnected",
      show: true,
      value: getIntegrationStat("disconnected"),
    },
  ];
  return (
    <div className="w-full h-full flex flex-col">
      {" "}
      <Header
        title="Integrations"
        subtext="Connect your favourite tools to Wholistic."
      />
      {isLoading ? (
        <div className="w-full h-full">
          <Loader big />
        </div>
      ) : (
        <div className="mt-6 flex flex-col">
          <TextPanes
            panes={panes}
            active={show}
            handleChange={handlePaneSwitch}
          />
          <div className="mt-6 w-full h-full grid grid-cols-3 gap-5">
            {integrations?.data.map((item) => (
              <IntegrationCard
                key={item.id}
                details={item}
                refetch={refetch}
                view={change}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
