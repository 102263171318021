import { motion, AnimatePresence } from 'framer-motion';
import React, { ReactNode, UIEvent, useState } from 'react';
import './styles.css';
import { removeSideModal } from '~/providers';

interface Modal {
  id?: string;
  onClose: () => void;
  component: ReactNode;
  open?: boolean;
}

const bgVariant = {
  enter: {
    opacity: 0,
  },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
  },
};

export const SideModal = ({ component, id }: Modal) => {
  const close = removeSideModal();

  const [isOpen, setIsOpen] = useState(true);
  const [bgOpen, setBgOpen] = useState(true);
  const closeOut = () => {
    setIsOpen(false);
    setTimeout(() => {
      setBgOpen(false);
    }, 150);
    id &&
      setTimeout(() => {
        close(id);
      }, 400);
  };
  const additionalProps = {
    close: closeOut,
  };

  return (
    <AnimatePresence key='side-modal background'>
      {bgOpen && (
        <motion.div
          initial='enter'
          animate='animate'
          exit='exit'
          variants={bgVariant}
          className='side-modal-background'
        >
          <AnimatePresence>
            {isOpen && (
              <motion.div
                onClick={(e: UIEvent) => e.stopPropagation}
                initial={{ x: 1300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 1300, opacity: 0 }}
                transition={{
                  type: 'tween',
                  ease: [0.4, 0.0, 0.2, 1],
                  duration: 0.3,
                }}
              >
                <div
                  className='side-modal-container '
                  onClick={(e: UIEvent) => e.stopPropagation}
                >
                  <div className='side-component-container'>
                    {React.isValidElement(component)
                      ? React.cloneElement(component, additionalProps)
                      : component}
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
