/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { updateWorkspace } from '~/api';
import { Button } from '~/components';
import Loader from '~/components/UI/Loader';
import { useAuth } from '~/providers';

export const AssistantPrompt = ({ close }: { close?: () => void }) => {
  const { workspace, setWorkspace } = useAuth();
  const { mutate, isPending, variables } = useMutation({
    mutationFn: (choice: boolean) =>
      updateWorkspace(workspace.id, {
        ...workspace,
        assistantEnabled: choice,
      }),
    ...{
      onSuccess(data: any) {
        toast.success(
          `Assistant ${
            data.data.assistantEnabled ? 'enabled' : 'disabled'
          } succesfully`
        );
        setWorkspace({ ...data.data });
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  return (
    <div className='w-full flex flex-col'>
      <header className='flex items-start w-full justify-between border-b py-4 px-5'>
        <div className='flex flex-col gap-y-1  max-w-[440px]'>
          <div className='flex items-center gap-x-2'>
            <img src='/coloredstars.svg' />
            <h4 className='text-[#0A0D14] font-medium'>
              Introducing Wholistic AI
            </h4>
          </div>
          <span className='text-sm text-[#525866]'>
            Say hello to your intelligent wholly integrated assistant
          </span>
        </div>
        <button onClick={close}>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z'
              fill='#525866'
            />
          </svg>
        </button>
      </header>

      <section className='p-5 flex flex-col w-full gap-y-5 max-w-[440px]'>
        <p className='text-sm text-[#525866]'>
          Wholistic AI here to help you with any questions or tasks you might
          have. Whether you need information, assistance with a task, or just
          someone to chat with, I'm here for you 24/7. Let's get started and
          make your experience even better!{' '}
        </p>

        <div className='text-sm text-[#000000] bg-[#F6F8FA] border border-[#F1F2F4] rounded-[6px] w-full items-center py-5 px-8 grid grid-cols-3 gap-5 h-[100px]'>
          placeholder for demo video
        </div>
      </section>

      <footer className='px-5 py-4 border-t flex justify-between items-center gap-x-3'>
        <div className='flex items-center gap-x-2'>
          <Button label='Maybe Later' effect={close} mono />
          <Button
            label='No thanks'
            effect={() => mutate(false)}
            loading={isPending && variables == false}
          />
        </div>
        <button
          key='menu'
          onClick={() => mutate(true)}
          className='px-3 py-2 text-sm font-medium text-white rounded-lg backdrop-blur-md flex items-center ai-btn gap-x-1 min-w-[107px]'
        >
          {isPending && variables ? (
            <span className='mx-auto block'>
              <Loader bgColor='#fff' />
            </span>
          ) : (
            <>
              {' '}
              <img src='/stars.svg' />
              <span>Try it out</span>
            </>
          )}
        </button>
      </footer>
    </div>
  );
};
