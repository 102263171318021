/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";
import { Button } from "~/components";
import Loader from "~/components/UI/Loader";
import { getProject } from "~/queries/projectQueries";
import { getFormattedDate } from "~/utils/dateUtils";
import { RejectRequest } from "./Reject";
import { acceptProjectRequest } from "~/api";

export const ViewProjectRequest = ({
  close,
  id,
}: {
  id: string;
  close?: () => void;
}) => {
  const [showReject, setShowReject] = useState(false);
  const qc = useQueryClient();
  const { data: request, isLoading } = useQuery<Project>({
    queryKey: ["requests", id],
    queryFn: async () => {
      try {
        const data = await getProject(id);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => acceptProjectRequest(id),
    ...{
      onSuccess() {
        toast.success("Request accepted successfully and added to projects.");
        qc.invalidateQueries({ queryKey: ["projects"] });
        qc.invalidateQueries({ queryKey: ["requests"] });
        close?.();
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const details = [
    { title: "Client", value: request?.client.name },
    { title: "Official email address", value: request?.client.email },
    { title: "Service Category", value: request?.serviceType },
    {
      title: "Est. duration",
      value: `${request?.estimatedDuration} days`,
    },
    { title: "Project Name", value: request?.name },
    { title: "Description", value: request?.description },
    { title: "Availability", value: getFormattedDate(request?.availableDate) },
    {
      title: "Status",
      value: <span className="capitalize">{request?.status}</span>,
    },
    { title: "Submission Date", value: getFormattedDate(request?.startDate) },

    { title: "Primary contact name", value: request?.client.contactName },
    {
      title: "Primary contact email address",
      value: request?.client.contactEmail,
    },
    {
      title: "Primary contact phone number",
      value: request?.client.contactPhone,
    },
  ];
  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full flex flex-col h-full relative">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">Request details</h4>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5 ">
        <div className="flex flex-col gap-5 ">
          {details.map((d) => (
            <div key={d.title} className="flex flex-col ">
              <div className="text-xs text-[#525866]">{d.title}</div>
              <div className="text-sm text-[#0A0D14]">{d.value}</div>
            </div>
          ))}
        </div>
      </section>
      <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3 mt-auto">
        {request?.status !== "rejected" && (
          <Button
            mono
            label="Reject request"
            effect={() => setShowReject(true)}
          />
        )}
        {(request?.status === "rejected" || request?.status === "pending") && (
          <Button
            loading={isPending}
            label="Accept request"
            effect={() => mutate()}
          />
        )}
      </footer>
      <AnimatePresence>
        {showReject && (
          <RejectRequest
            id={id}
            close={() => {
              setShowReject(false);
              close?.();
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
